<template>
  <div class="mx-6 px-16 pt-0 mb-7">
    <v-card
      dark
      color="transparent"
    >
      <v-card-title class="pb-0">
        <v-row>
          <v-col>
            <v-select
              v-model="filters.referenceDate"
              :items="type == 'VISITS' ? filters.itemListVisit : filters.itemList"
              label="Data de referência"
              prepend-inner-icon="local_shipping"
              dark
              filled
              hide-details
              :style="dynamicFilterWidth()"
              @change="loadItems"
            />
          </v-col>

          <v-col>
            <v-date-range-picker
              v-if="status !== 'PENDING'"
              v-model="filters.dateRange"
              :disabled="filters.referenceDate == 'EM ANDAMENTO'"
              dark
              hide-details
              :style="dynamicFilterWidth()"
              @change="loadItems"
            />
          </v-col>

          <v-col v-if="['COLLECTS', 'VISITS'].includes(type)">
            <routes-autocomplete-filter
              v-model="filters.route"
              label="Rota"
              placeholder=""
              hide-details
              :type="type == 'VISITS' ? 'visita': type=='COLLECTS'?'coleta':'coleta' "
              :style="dynamicFilterWidth()"
              @change="loadItems"
            />
          </v-col>

          <v-col v-if="type == 'SPOT'">
            <company-autocomplete-filter
              v-model="filters.company"
              :style="dynamicFilterWidth()"
              hide-details
              @change="loadItems"
            />
          </v-col>

          <v-col>
            <vehicle-autocomplete-filter
              v-model="filters.vehicle"
              hide-details
              :type="type"
              :style="dynamicFilterWidth()"
              @change="loadItems"
            />
          </v-col>

          <v-col>
            <person-autocomplete-filter
              v-model="filters.driver"
              hide-details
              :label="type == 'VISITS' ? 'Técnico' : type == 'COMMERCIAL' ? 'Vendedor' : 'Motorista'"
              :type="type == 'VISITS' ? 'TECHNICIAN' : type == 'COMMERCIAL' ? 'SELLER' : 'DRIVER'"
              :style="dynamicFilterWidth()"
              @change="loadItems"
            />
          </v-col>

          <v-col v-if="type === 'COLLECTS'">
            <person-autocomplete-filter
              v-model="filters.producer"
              hide-details
              label="Produtor"
              type="PRODUCER"
              :style="dynamicFilterWidth()"
              @change="loadItems"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="filters.dairyCode"
              label="Código Itinerário"
              filled
              dark
              type="number"
              hide-details
              :style="dynamicFilterWidth()"
              @change="loadItems"
            />
          </v-col>

          <v-col v-if="hasSecondaryRawMaterial">
            <v-select
              v-model="rawMaterial"
              :items="rawMaterialItems"
              label="Matéria Prima"
              prepend-inner-icon="info"
              dark
              filled
              hide-details
              clearable
              :style="dynamicFilterWidth()"
              @change="loadItems"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <data-table
        v-model="selected"
        :name="dataTableName"
        :loading="loading"
        :headers="headers"
        :items="itineraries"
        item-key="id"
        class="elevation-1"
        disable-filtering
        multi-sort
        :show-select="type == 'COLLECTS' || type == 'VISITS'"
        @click:row="onItineraryEditing"
      >
        <template #[`item.route.offRoute`]="{ item }">
          <div style="max-width: 90px;">
            <v-chip
              v-for="(route, index) in item.route.offRoute"
              :key="index"
              pill
              x-small
              color="green darken-1"
              class="white--text"
            >
              {{ route }}
            </v-chip>
          </div>
        </template>

        <template #[`item.time.startedAt`]="{ item }">
          <div style="max-width: 90px;">
            {{ dateFormat(item.time.startedAt, 'DD/MM HH:mm') }}
          </div>
        </template>

        <template #[`item.time.endedAt`]="{ item }">
          <div
            v-if="item.time.endedAt"
            style="max-width: 90px;"
          >
            {{ dateFormat(item.time.endedAt, 'DD/MM HH:mm') }}
          </div>
          <div v-else>
            Em Andamento
          </div>
        </template>

        <template #[`item.time.total`]="{ item }">
          <div
            v-if="item.time.endedAt"
            style="max-width: 90px;"
          >
            {{ item.time.total }}
          </div>
        </template>

        <template #[`item.time.unloadedAt`]="{ item }">
          <div style="max-width: 90px;">
            {{ dateFormat(item.time.unloadedAt, 'DD/MM HH:mm') }}
          </div>
        </template>

        <template #[`item.distance.total`]="{ item }">
          <itinerary-odometer-table-item
            v-if="item.distance.startedAt != item.distance.endedAt"
            :started-at="item.distance.startedAt"
            :ended-at="item.distance.endedAt"
            :total="item.distance.total"
            :odometer="item.distance.odometer"
          />
        </template>

        <template #[`item.collect.totalVol`]="{ item }">
          <div style="max-width: 70px;">
            <template v-for="tank in item.collect.tanks">
              <v-chip
                v-if="tank.vol > 0"
                :key="tank.index"
                pill
                x-small
              >
                <v-avatar
                  left
                  color="green"
                  class="white--text"
                >
                  {{ tank.index }}
                </v-avatar>

                {{ tank.vol }}
              </v-chip>
            </template>
          </div>
        </template>

        <template #[`item.collect.totalCarga`]="{ item }">
          <div style="max-width: 90px;">
            {{ item.collect.totalCarga }}
          </div>
        </template>

        <template #[`item.spotType`]="{ value }">
          <v-chip
            v-if="value == 'ENTRADA'"
            small
            color="blue darken-3"
          >
            Entrada
          </v-chip>
          <v-chip
            v-else-if="value == 'SAIDA'"
            small
            color="red darken-3"
          >
            Saída
          </v-chip>
        </template>

        <template #[`item.map`]="{ item }">
          <div style="min-height: 36px;">
            <v-btn
              v-if="item.time.endedAt"
              icon
              @click.stop="showItineraryMap(item)"
            >
              <v-icon>map</v-icon>
            </v-btn>
          </div>
        </template>

        <template #[`item.trailerTransfers`]="{ item }">
          <v-row>
            <v-btn
              v-if="item.collectTransfers && item.collectTransfers.length > 0"
              icon
              @click.stop="showCollectTransfers(item.collectTransfers)"
            >
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-icon v-on="on">
                    mediation
                  </v-icon>
                </template>

                Transferências
              </v-tooltip>
            </v-btn>

            <v-btn
              v-if="item.trailerTransfers && item.trailerTransfers.length > 0"
              icon
              @click.stop="showTrailerTransfers(item.trailerTransfers)"
            >
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-icon v-on="on">
                    insert_link
                  </v-icon>
                </template>

                Reboque
              </v-tooltip>
            </v-btn>
          </v-row>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="type == 'COLLECTS' && item.time.endedAt"
                @click="onGenerateKml(item)"
              >
                <v-list-item-icon>
                  <v-icon>map</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Gerar KML
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="resources.editCollect || status == 'PENDING' "
                @click="onItineraryEditing(item)"
              >
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Editar
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="(resources.deleteCollect || hasDeleteVisit) && status !== 'PENDING' "
                @click="onRemoveItinerary(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Excluir
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <itinerary-map-dialog
      ref="map"
      :type="type"
    />

    <trailer-transfers-dialog
      ref="trailerTransfersDialog"
    />

    <block-collect-dialog
      ref="blockCollect"
    />

    <v-tooltip
      v-if="status === 'PENDING'"
      left
    >
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          class="mr-2"
          color="blue"
          v-on="on"
          @click="loadItems"
        >
          <v-icon>refresh</v-icon>
        </v-btn>
      </template>

      <span>Recarregar</span>
    </v-tooltip>

    <v-speed-dial
      v-else
      fixed
      fab
      dark
      bottom
      right
      open-on-hover
      class="mr-2"
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="resources.blockCollect && type == 'COLLECTS'"
        fab
        dark
        color="black darken-1"
        @click.stop="showBlockCollect()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              lock
            </v-icon>
          </template>

          Bloquear Edição de coletas
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="blue"
        @click="loadItems"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              refresh
            </v-icon>
          </template>

          Recarregar
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="type != 'SPOT' || !onlyPlannedSpotAllowed"
        fab
        dark
        color="deep-purple darken-1"
        @click="onItineraryNew"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>

          Novo itinerário
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="selected.length > 0"
        fab
        dark
        color="orange darken-4"
        @click="printReport"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              receipt_long
            </v-icon>
          </template>
          Imprimir Relatório
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="type == 'COLLECTS'"
        fab
        dark
        color="blue-grey darken-4"
        @click="exportCollects"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              save_alt
            </v-icon>
          </template>
          Exportar
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";

import ItineraryMapDialog from "@/Domains/Itineraries/Components/ItineraryMapDialog.vue";
import VehicleAutocompleteFilter from "@/Support/Components/Filters/VehicleAutocompleteFilter.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ItineraryOdometerTableItem from "@/Domains/Itineraries/Components/ItineraryOdometerTableItem.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import CompanyAutocompleteFilter from '@/Domains/Itineraries/Components/CompanyAutocompleteFilter.vue';
import TrailerTransfersDialog from "@/Domains/Itineraries/Components/TrailerTransfersDialog.vue";
import BlockCollectDialog from "@/Domains/Itineraries/Components/Form/BlockCollectDialog.vue";
import FilterRawMaterialMixin from "@/Support/Mixins/FilterRawMaterialMixin.js";

export default {

  components: {
    ItineraryOdometerTableItem,
    VDateRangePicker,
    PersonAutocompleteFilter,
    VehicleAutocompleteFilter,
    ItineraryMapDialog,
    RoutesAutocompleteFilter,
    CompanyAutocompleteFilter,
    TrailerTransfersDialog,
    BlockCollectDialog,
  },

  mixins: [ReportMixin, FilterRawMaterialMixin],

  props: {
    type: {
      type: String,
      default: 'COLLECTS'
    },
    status: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,

      itineraries: [],

      selected: [],

      // Filtros da tabela
      filters: {
        vehicle: {},

        driver: {},

        dateRange: [],

        route: {},

        company: {},

        producer: {},

        referenceDate: 'INICIO',

        itemList: ['INICIO', 'DESCARGA', 'EM ANDAMENTO'],

        itemListVisit: ['INICIO', 'EM ANDAMENTO'],

        dairyCode: '',
      },

      types: {
        COLLECTS: 'coleta',
        VISITS: 'visita',
        TRANSFERS: 'transferencia',
        SPOT: 'spot',
        COMMERCIAL: 'comercial',
        SHIPMENT: 'expedicao',
      },

      resources: {
        editCollect: false,
        deleteCollect: true,
        blockCollect: false,
      },
    };
  },

  computed: {
    headers() {
      let headers = [{ text: "Código", value: "code", align: "center", width: 15  }];

      if (['COLLECTS', 'VISITS', 'COMMERCIAL'].includes(this.type)) {
        headers.push({ text: "Rota", value: "route.description", align: "center", width: 100 });
        headers.push({ text: "Outras rotas", value: "route.offRoute", align: "center", width: 100, show: false });
      }

      if (this.type == 'SPOT') {
        headers.push(
          { text: "Tipo", value: "spotType", align: "center" },
          { text: "Empresa", value: "company.name", align: "center" }
        );
      }

      if (this.type == 'TRANSFERS') {
        headers.push({ text: "Destino", value: "transferDairy.name", align: "center" });
      }

      if (this.type == 'SHIPMENT') {
        headers.push(
          { text: "Rota/Região", value: "route.description", align: "center" },
          { text: "Motorista", value: "driver.name", align: "center"  }
        );
      }

      if (this.type == 'COMMERCIAL') {
        headers.push({ text: "Vendedor", value: "driver.name", width: 250 });
      } else if (this.type == 'COLLECTS') {
        headers.push({ text: "Motorista", value: "driver.name", align: "center", width: 250  });
      } else if (this.type == 'VISITS') {
        headers.push({ text: "Técnico", value: "driver.name", align: "center", width: 250 });
      }

      headers.push(
        { text: "Placa", value: "vehicle.description", align: "center" },
        { text: "Saída", value: "time.startedAt", align: "center", width: 70  },
        { text: "Chegada", value: "time.endedAt", align: "center", width: 20  },
        { text: "Tempo", value: "time.total", align: "center", width: 20  },
        { text: "Efetivação", value: "time.unloadedAt", align: "center", width: 20  },
        { text: "Quilometragem", value: "distance.total", align: "start", width: 20 }
      );

      if (['COLLECTS'].includes(this.type)) {
        headers.push(
          { text: "Litros", value: "collect.totalVol", align: "center", width: 30  },
          { text: "Total carga", value: "collect.totalCarga", align: "center", width: 30  },
          { text: "Temp.", value: "collect.temp", align: "center", width: 30  },
          { text: "Taxa de ocupação", value: "collect.taxa_ocupacao", align: "center", width: 30  },
          { text: "Latitude", value: "end_lat_ini", align: "center", width: 30, show: false },
          { text: "Longitude", value: "end_lng_ini", align: "center", width: 30, show: false }
        );
      }

      if (this.hasSecondaryRawMaterial) {
        headers.push(this.headerRawMaterial);
      }

      if (['COLLECTS', 'VISITS'].includes(this.type)) {
        headers.push(
          { text: "Produtores", value: "producers", align: "center", width: 10 },
          { altText: 'Mapa', value: "map", align: 'end', width: 1, sortable: false },
          { altText: 'Transferências', value: "trailerTransfers", align: 'end', width: 1, sortable: false },
        );
      }

      headers.push({ altText: 'Opções', value: "actions", align: 'end', width: 1, sortable: false });

      return headers;
    },

    onlyPlannedSpotAllowed() {
      return this.$store.state.settings.gerais.permite_apenas_spot_planejado || false;
    },

    dataTableName() {
      if (this.type == 'COLLECTS') {
        return 'Itinerário Coleta';
      }

      if (this.type == 'VISITS') {
        return 'Itinerário Visita';
      }

      if (this.type == 'SPOT') {
        return 'Itinerário Spot';
      }

      if (this.type == 'TRANSFERS') {
        return 'Itinerário Transferência';
      }

      if (this.type == 'COMMERCIAL') {
        return 'Itinerário Comercial';
      }

      if (this.type == 'SHIPMENT') {
        return 'Romaneio de Expedição';
      }

      return undefined;
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasDeleteVisit() {
      return this.type == 'VISITS' && (this.isAdmin || this.userResources.some(o => o.recurso === "itinerary-visit-delete" && o.tipo === "COMPONENTE"));
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(value) {
        const filterId = `${this.$options._componentTag}-${this.type}`;
        this.$store.commit("setFilter", { [filterId]: value });
      }
    }
  },

  created() {
    this.getFiltersFromStore();
    this.loadResources();
  },

  mounted() {
    this.loadItems();
  },

  methods: {
    getFiltersFromStore() {
      const filterId = `${this.$options._componentTag}-${this.type}`;
      if (filterId in this.$store.state.settings.filters) {
        this.filters = this.$store.state.settings.filters[filterId];
      }
    },
    loadItems() {
      if (this.type == 'COLLECTS') {
        this.loadCollects();
      }
      else {
        this.loadItineraries();
      }
    },

    async loadCollects() {
      try {
        this.loading = true;

        let req = {
          status: this.status === 'PENDING' ? 'pendentes' : 'aprovados',
          id_motorista: this.filters.driver ? this.filters.driver.id : null,
          id_equipamento: this.filters.vehicle ? this.filters.vehicle.id : null,
          id_rota: this.filters.route ? this.filters.route.id : null,
          cod_itinerario: this.filters.dairyCode ? this.filters.dairyCode : null,
          id_produtor: this.filters.producer?.id,
          materiaPrima: this.rawMaterial || null,
        }

        if (this.status !== 'PENDING') {
          const [startDate, endDate] = this.filters.dateRange;
          req = {
            ...req,
            data_ini: moment(startDate).format("DD/MM/YYYY"),
            data_fim: moment(endDate).format("DD/MM/YYYY"),
            filtro_data: this.filters.referenceDate
          }
        }

        const { data } = await this.$axios.post(`/coleta/relatorioItinerario`, this.$qs.stringify(req));

        if (!_.isArray(data)) {
          throw 'Error';
        }

        const [startDate = null, endDate = null] = this.filters.dateRange;

        this.itineraries = data.map(itinerary => {
          const tanks = [
            { index: 1, vol: parseInt(itinerary.tanque1) || 0 },
            { index: 2, vol: parseInt(itinerary.tanque2) || 0 },
            { index: 3, vol: parseInt(itinerary.tanque3) || 0 },
            { index: 4, vol: parseInt(itinerary.tanque4) || 0 },
            { index: 5, vol: parseInt(itinerary.tanque5) || 0 },
            { index: 6, vol: parseInt(itinerary.tanque6) || 0 },
            { index: 7, vol: parseInt(itinerary.tanque7) || 0 },
          ];

          const hasTransshipment = itinerary.id_transbordo || itinerary.id_itinerario_transbordo ? true : false;

          return {
            id: itinerary.id_itinerario,
            code: itinerary.cod_itinerario,
            route: {
              id: itinerary.id_rota,
              description: itinerary.descricao_rota,
              polyline: itinerary.polyline,
              offRoute: JSON.parse(itinerary.fora_de_rota)
            },
            driver: {
              id: itinerary.id_motorista,
              name: itinerary.motorista,
            },
            vehicle: {
              id: itinerary.id_equipamento,
              description: (itinerary.caminhao || '').toUpperCase(),
              plate: itinerary.caminhao,
            },
            carrier: {
              id: itinerary.id_transportadora,
              name: itinerary.nome_transportadora,
            },
            time: {
              startedAt: itinerary.data_hora_inicio,
              endedAt: itinerary.data_hora_fim,
              total: itinerary.data_hora_fim ? moment(itinerary.horas, 'HH:mm:ss').format('HH:ss') : null,
              unloadedAt: itinerary.data_hora_descarga,
              arrivalAt: itinerary.data_chegada_patio,
            },
            distance: {
              startedAt: itinerary.km_inicial ? parseInt(itinerary.km_inicial) : null,
              endedAt: itinerary.km_final ? parseInt(itinerary.km_final) : null,
              total: itinerary.km_total ? parseInt(itinerary.km_total) : null,
              gps: itinerary.total_km_gps ? parseInt(itinerary.total_km_gps) : null,
              planned: itinerary.km_planejado ? parseFloat(itinerary.km_planejado) : null,
              manual: itinerary.km_ajuste_manual ? true : false,
              odometer: [
                {
                  type: 'START',
                  src: itinerary.url_velocimentro_ini,
                  thumb: itinerary.url_velocimentro_ini,
                  caption: 'Início',
                },
                {
                  type: 'END',
                  src: itinerary.url_velocimentro_fim,
                  thumb: itinerary.url_velocimentro_fim,
                  caption: 'Fim',
                },
              ],
            },
            producers: itinerary.totalprodutores,
            footerMessage: itinerary.mensagem_rodape,
            device: itinerary.dispositivo,
            volume: parseFloat(itinerary.volume_total) || 0,
            rawMaterialId: itinerary.id_materia_prima,
            collect: {
              tanks,
              tanque1: parseInt(itinerary.tanque1) || 0,
              tanque2: parseInt(itinerary.tanque2) || 0,
              tanque3: parseInt(itinerary.tanque3) || 0,
              tanque4: parseInt(itinerary.tanque4) || 0,
              tanque5: parseInt(itinerary.tanque5) || 0,
              tanque6: parseInt(itinerary.tanque6) || 0,
              tanque7: parseInt(itinerary.tanque7) || 0,
              totalVol: parseInt(itinerary.quantidade_coleta) || 0,
              totalCarga: parseInt(itinerary.quantidade_coleta) || 0,
              temp: `${parseFloat(itinerary.temperatura).toFixed(1)} ºC`,
              tempNumber: parseFloat(itinerary.temperatura).toFixed(1),
              taxa_ocupacao: itinerary.taxa_ocupacao ? parseFloat(itinerary.taxa_ocupacao).toFixed(2) : '0.00',
            },
            hasTransshipment,
            collectTransfers: itinerary.transferencias.map(transfer => ({
              title: hasTransshipment ? 'TRANSBORDO' : 'TRANSFERÊNCIA',
              date: transfer.data_hora_atualizacao,
              equipmentOrigin: {
                id: transfer.id_equipamento_origem,
                plate: transfer.placa_origem,
              },
              equipment: {
                id: transfer.id_equipamento_destino,
                plate: transfer.placa_destino,
              },
              volume: transfer.transferencias.reduce((acc, cur) => acc + (parseInt(cur.volume)), 0),
              transfers: transfer.transferencias
            })),
            trailerTransfers: itinerary.transferencias_reboques.map(transfer => ({
              date: transfer.data_hora_atualizacao,
              equipmentOrigin: {
                id: transfer.id_equipamento_origem,
                plate: transfer.placa_origem,
              },
              equipment: {
                id: transfer.id_equipamento_destino,
                plate: transfer.placa_destino,
              },
              volume: transfer.transferencias.reduce((acc, cur) => acc + (parseInt(cur.volume)), 0),
              transfers: transfer.transferencias
            })),
            mobile: {
              version: itinerary.versao
            },
            startDate,
            endDate,
            end_lat_ini: (itinerary.end_lat_ini && itinerary.end_lat_ini != 'null') ? itinerary.end_lat_ini : '',
            end_lng_ini: (itinerary.end_lng_ini && itinerary.end_lng_ini != 'null') ? itinerary.end_lng_ini : '',
            nome_materia_prima: itinerary.nome_materia_prima,
          };
        });

      } catch (error) {
        console.log(error);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar o relatório!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async loadItineraries() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filters.dateRange;

        let req = {
          tipo: this.types[this.type],
          id_motorista: this.filters.driver ? this.filters.driver.id : null,
          id_equipamento: this.filters.vehicle ? this.filters.vehicle.id : null,
          id_rota: this.filters.route ? this.filters.route.id : null,
          id_empresa: this.filters.company ? this.filters.company.id : null,
          data_ini: startDate,
          data_fim: endDate,
          filtro_data: this.filters.referenceDate,
          cod_itinerario: this.filters.dairyCode ? this.filters.dairyCode : null,
          materiaPrima: this.rawMaterial || null,
        }

        const { data } = await this.$axios.post(`/itinerario/listaItinerarios`, req);

        if (!_.isArray(data)) {
          throw 'Error';
        }

        this.itineraries = data.map(itinerary => {
          return {
            id: itinerary.id_itinerario,
            code: this.type === 'SHIPMENT' ? itinerary.codigo : itinerary.cod_itinerario,
            route: {
              id: itinerary.id_rota,
              description: itinerary.descricao_rota,
            },
            driver: {
              id: itinerary.id_motorista,
              name: itinerary.motorista,
            },
            vehicle: {
              id: itinerary.id_equipamento,
              description: (itinerary.caminhao || '').toUpperCase(),
              plate: itinerary.caminhao,
            },
            carrier: {
              id: itinerary.id_transportadora,
              name: itinerary.nome_transportadora,
            },
            time: {
              startedAt: itinerary.data_hora_inicio,
              endedAt: itinerary.data_hora_fim,
              total: itinerary.data_hora_fim ? moment(itinerary.horas, 'HH:mm:ss').format('HH:ss') : null,
            },
            distance: {
              startedAt: itinerary.km_inicial ? parseInt(itinerary.km_inicial) : null,
              endedAt: itinerary.km_final ? parseInt(itinerary.km_final) : null,
              total: itinerary.km_total ? parseInt(itinerary.km_total) : null,
              gps: itinerary.total_km_gps ? parseInt(itinerary.total_km_gps) : null,
              odometer: [
                {
                  type: 'START',
                  src: itinerary.url_velocimentro_ini,
                  thumb: itinerary.url_velocimentro_ini,
                  caption: 'Início',
                },
                {
                  type: 'END',
                  src: itinerary.url_velocimentro_fim,
                  thumb: itinerary.url_velocimentro_fim,
                  caption: 'Fim',
                },
              ],
            },
            producers: this.type === 'VISITS' ? itinerary.quantidade_visitas :  itinerary.total_produtores,
            note: itinerary.observacao,
            device: itinerary.dispositivo,
            volume: parseFloat(itinerary.volume_total) || 0,
            transferDairy: {
              id: itinerary.id_laticinio_transferencia,
              name: itinerary.nome_laticinio_transferencia,
            },
            company: {
              id: itinerary.id_empresa,
              name: itinerary.nome_empresa,
            },
            hasSpotPlanning: !!itinerary.id_spot,
            spotType: itinerary.tipo_spot,
            rawMaterialId: itinerary.id_materia_prima,
            mobile: {
              version: itinerary.versao || null,
            },
            nome_materia_prima: itinerary.nome_materia_prima,
          };
        });

      } catch (error) {
        console.log(error);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar o relatório!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async showItineraryMap(itinerary) {
      try {
        this.loading = true;

        await this.$refs.map.show(itinerary);
      } catch (e) {

        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    dateFormat(value, format) {
      return moment(value).format(format);
    },

    onItineraryNew() {
      return this.$emit('onItineraryNew');
    },

    onItineraryEditing(itinerary) {

      if (this.resources.editCollect === true || this.status == 'PENDING') {
        return this.$emit('onItineraryEditing', itinerary);
      }
    },

    async onItineraryPrint(itinerary) {
      const [startDate = null, endDate = null] = this.filters.dateRange;

      return this.$emit('onItineraryPrint', {
        ids: [itinerary.id],
        startDate,
        endDate,
      });
    },

    printReport() {
      if (this.selected.length === 0) {
        return;
      }

      const [startDate = null, endDate = null] = this.filters.dateRange;

      return this.$emit('onItineraryPrint', {
        ids: this.selected.map(row => row.id),
        startDate,
        endDate,
      });
    },

    async onGenerateKml(itinerary) {
      return this.$emit('generateKml', itinerary);
    },

    async onRemoveItinerary(itinerary) {

      if (this.type == 'COLLECTS') {
        const dataBlock = this.$store.state.settings.gerais.data_bloqueio_edicao_coleta;
        if ( moment(itinerary.time.unloadedAt) < moment(dataBlock)) {
          this.$snotify.warning(
            "Não é possivel excluir coletas anteriores a " + moment(dataBlock).format("DD/MM/YY"),
            "Atenção"
          );
          return false;
        }
      }

      if (!(await this.$root.$confirm(
        'Atenção',
        `Deseja realmente excluir o itinerário <b>${itinerary.code} - ${itinerary.route.description ? itinerary.route.description + ' -' : ''} ${itinerary.driver.name}</b>?<br><br>
        ${ this.type == 'COLLECTS' ? `
          <div class="v-alert v-sheet v-sheet--outlined theme--light elevation-0 v-alert--dense v-alert--outlined red--text body-2">
            Itens relacionados ao itinerário como: <b>Coletas, Amostras, Justificativas, etc.</b> também serão excluídos.
          </div>` : ''
        }
        ${ this.type == 'VISITS' ? `
          <div class="v-alert v-sheet v-sheet--outlined theme--light elevation-0 v-alert--dense v-alert--outlined red--text body-2">
            Itens relacionados ao itinerário como: <b>Visitas, Amostras, Recomendações, Checklists, etc.</b> também serão excluídos.
          </div>` : ''
        }
        Esta ação não poderá ser revertida!<br><br>`,
        { color: 'red', token: 'EXCLUIR' }
      ))) {
        return;
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/itinerario/inativa`,
          this.$qs.stringify({
            id_itinerario: itinerary.id,
          }),
        );

        if (_.isEmpty(data)) {
          throw data;
        }

        if (data.codigo !== 1) {
          throw data;
        }

        this.loadItems();
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao excluir a coleta!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    showCollectTransfers(collectTransfers) {
      this.$refs.trailerTransfersDialog.show(collectTransfers);
    },

    showTrailerTransfers(trailerTransfers) {
      this.$refs.trailerTransfersDialog.show(trailerTransfers);
    },

    showBlockCollect() {
      this.$refs.blockCollect.show();
    },

    getReportJson() {
      return this.itineraries.map(o => {
        if (this.type == 'COLLECTS') {

          let data = {
            'Codigo': o.code,
            Rota: o.route.description || '',
            'Motorista/Técnico': o.driver.name || '',
            Placa: o.vehicle.description || '',
            Saída: moment(o.time.startedAt).format('DD/MM/YYYY HH:mm'),
            Chegada: o.time.endedAt ? moment(o.time.endedAt).format('DD/MM/YYYY HH:mm') : 'Em Andamento',
            Horas: o.time.total || '',
            'Efetivação': o.time.unloadedAt ? moment(o.time.unloadedAt).format('DD/MM/YYYY HH:mm') : '',
            'Km Inicial': o.distance.startedAt,
            'Km Final': o.distance.endedAt,
            'Km Total': o.distance.total,
            'Km Planejado': o.distance.planned,
            'Km GPS': o.distance.gps,
            Produtores: o.producers,
            'Temperatura': o.collect.tempNumber > 5 ? `<strong>${o.collect.temp}</strong>` : o.collect.temp,
            'Tanques1': o.collect.tanque1,
            'Tanques2': o.collect.tanque2,
            'Tanques3': o.collect.tanque3,
            'Tanques4': o.collect.tanque4,
            'Tanques5': o.collect.tanque5,
            'Tanques6': o.collect.tanque6,
            'Tanques7': o.collect.tanque7,
            'Volume total': o.collect.totalVol,
            'Taxa de Ocupação': o.collect.taxa_ocupacao ? parseFloat(o.collect.taxa_ocupacao).toFixed(2) : '0.00',
            'Latitude': (o.end_lat_ini && o.end_lat_ini != 'null') ? o.end_lat_ini : '',
            'Longitude': (o.end_lng_ini && o.end_lng_ini != 'null') ? o.end_lng_ini : '',
          };

          if (this.hasSecondaryRawMaterial) {
            data['Matéria Prima'] = o.nome_materia_prima;
          }

          return data;
        } else if (this.type == 'VISITS') {
          let data = {
            'Codigo': o.code,
            Rota: o.route.description || '',
            'Motorista/Técnico': o.driver.name || '',
            Placa: o.vehicle.description || '',
            Saída: moment(o.time.startedAt).format('DD/MM/YYYY HH:mm'),
            Chegada: o.time.endedAt ? moment(o.time.endedAt).format('DD/MM/YYYY HH:mm') : 'Em Andamento',
            Horas: o.time.total || '',
            'Km Inicial': o.distance.startedAt,
            'Km Final': o.distance.endedAt,
            'Km Total': o.distance.total,
            Produtores: o.producers,
            'Observação': o.note
          };

          if (this.hasSecondaryRawMaterial) {
            data['Matéria Prima'] = o.nome_materia_prima;
          }

          return data;
        } else if (this.type == 'SPOT') {
          let data = {
            'Codigo': o.code,
            'Tipo': o.spotType || '',
            'Empresa': o.company.name || '',
            Placa: o.vehicle.description || '',
            Saída: moment(o.time.startedAt).format('DD/MM/YYYY HH:mm'),
            Chegada: o.time.endedAt ? moment(o.time.endedAt).format('DD/MM/YYYY HH:mm') : 'Em Andamento',
            Horas: o.time.total || '',
            'Km Inicial': o.distance.startedAt,
            'Km Final': o.distance.endedAt,
          };

          if (this.hasSecondaryRawMaterial) {
            data['Matéria Prima'] = o.nome_materia_prima;
          }

          return data;
        } else {
          let data = {
            'Codigo': o.code,
            'Destino': o.transferDairy.name || '',
            Placa: o.vehicle.description || '',
            Saída: moment(o.time.startedAt).format('DD/MM/YYYY HH:mm'),
            Chegada: o.time.endedAt ? moment(o.time.endedAt).format('DD/MM/YYYY HH:mm') : 'Em Andamento',
            Horas: o.time.total || '',
            'Km Inicial': o.distance.startedAt,
            'Km Final': o.distance.endedAt,
          };

          if (this.hasSecondaryRawMaterial) {
            data['Matéria Prima'] = o.nome_materia_prima;
          }

          return data;
        }
      });
    },

    exportExcel() {

      const title = {
        'COLLECTS': 'Itinerário de coletas',
        'VISITS': 'Itinerário de visitas',
        'TRANSFERS': 'Itinerário de transferências',
        'SPOT': 'Itinerário de spot',
        'COMMERCIAL': 'Itinerário Comercial',
        'SHIPMENT': 'Romaneio de Expedição',
      };

      const data = this.XLSX.utils.json_to_sheet(this.getReportJson(), { origin: 'A3' });

      data['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }];

      data['A1'] = { v: title[this.type] };

      data['!cols'] = [
        { wch: 30 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 }
      ];

      const workbook = this.XLSX.utils.book_new();
      const filename = 'Itinerário';
      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${title[this.type]}.xlsx`);
    },

    /**
     * Imprime o relatório
     */
    print() {
      const title = {
        'COLLECTS': 'Itinerário de coletas',
        'VISITS': 'Itinerário de visitas',
        'TRANSFERS': 'Itinerário de transferências',
        'SPOT': 'Itinerário de spot',
        'COMMERCIAL': 'Itinerário Comercial',
        'SHIPMENT': 'Romaneio de Expedição',
      };

      return this.printFromJson(this.getReportJson(), title[this.type]);
    },

    async exportCollects() {
      const { data } = await this.$axios.post(`/coleta/exportarColetas`, {
        ids: this.itineraries.map(it => it.id)
      });

      let pp = document.createElement('a');
      pp.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
      pp.setAttribute('download', `Relatorio Itinerario.csv`);
      pp.click();
    },

    /**
     * Carrega as permissões dos recursosa
     */
    loadResources() {
      const recursosUsuario = this.$store.state.settings.recursosUsuario;

      if (_.isEmpty(recursosUsuario)) {
        return true;
      }

      const editCollect = recursosUsuario.find((recurso) => recurso.recurso === "collect-insert-edit" && recurso.tipo == "COMPONENTE");

      this.resources.editCollect = !!editCollect;

      const blockCollect = recursosUsuario.find((recurso) => recurso.recurso === "collect-block-by-date" && recurso.tipo == "COMPONENTE");

      this.resources.blockCollect = !!blockCollect;

      const deleteCollect = this.type == 'COLLECTS' && recursosUsuario.find((recurso) => recurso.recurso === "collect-delete" && recurso.tipo == "COMPONENTE");

      this.resources.deleteCollect = !!deleteCollect;
    }
  },
};
</script>
