<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Volume/Região
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>
    <v-container>
      <v-card
        class="v-tamanho collecton-city"
        color="transparent"
      >
        <v-card-title class="pa-0">
          <v-col>
            <date-range-picker
              v-model="filters.date.input"
              :style="dynamicFilterWidth(true, '200px', '700px')"
              @change="onDateFilter"
            />
          </v-col>
          <v-col v-if="hasSecondaryRawMaterial">
            <v-select
              v-model="rawMaterial"
              :items="rawMaterialItems"
              label="Matéria Prima"
              prepend-inner-icon="info"
              dark
              filled
              hide-details
              clearable
              :style="dynamicFilterWidth(true, '200px', '700px')"
              @change="onFilter"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="filters.search"
              :style="dynamicFilterWidth(true, '200px', '700px')"
              prepend-icon="search"
            />
          </v-col>
        </v-card-title>
        <v-data-table
          class="collecton-city-table"
          dark
          :items="data"
          :search="filters.search"
          :headers="headers"
        >
          <template v-slot:item.volume="{ item }">
            <v-chip
              dark
              small
              color="teal darken-3"
            >
              {{ item.volume }}
            </v-chip>
          </template>
        </v-data-table>
        <div
          class="text-caption text-left ml-4"
          style="margin-top: -58px; height: 58px; line-height: 58px;"
        >
          Volume total
          <v-chip
            small
            class="mx-2"
          >
            {{ volume }}
          </v-chip> de
          <v-chip
            small
            class="mx-2"
          >
            {{ regiao }}
          </v-chip> Região
        </div>
      </v-card>
    </v-container>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="#6776c7"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </v-container>
</template>

<script>
import _ from "lodash";
import XLSX from "xlsx-js-style";
import qs from "qs";
import moment from "moment-timezone";
import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import FilterRawMaterialMixin from "@/Support/Mixins/FilterRawMaterialMixin.js";

export default {
  components: {
    DateRangePicker
  },

  mixins: [FilterRawMaterialMixin],

  data() {
    return {
      filters: {
        date: {
          input: "today",
          range: [],
        },
        search: null,
      },

      headers: [
        { text: 'Região', value: 'regiao'},
        { text: 'Volume (L)', value: 'volume', width: 150, align: 'center'},
      ],

      data: [],
      loading: false,
    };
  },

  computed: {
    regiao() {
      return this.data.length;
    },
    volume() {
      return _.sumBy(this.data, 'volume');
    }
  },

  mounted() {
    this.loadVolumeRegiao();
    this.addRawMaterialToGrid("headers", this.headerRawMaterial);
  },

  methods: {
    async loadVolumeRegiao() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/relatorios/volumeRegiao`,
          qs.stringify({
            data_inicio: !_.isEmpty(this.filters.date.range) ? _.first(this.filters.date.range) : moment().format("YYYY-MM-DD"),
            data_fim: !_.isEmpty(this.filters.date.range) ? _.last(this.filters.date.range) :  moment().format("YYYY-MM-DD"),
            materiaPrima: this.rawMaterial || null,
          })
        );

        this.data = data;
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    onDateFilter(event) {
      this.filters.date.range = event
      this.loadVolumeRegiao();
    },

    onFilter() {
      return this.loadVolumeRegiao();
    },

    exportExcel() {
      let data = XLSX.utils.json_to_sheet(
        this.data.map((item) => {
          let reportItem = {
            Região: item.regiao,
            Volume: item.volume
          };

          if (this.hasSecondaryRawMaterial) {
            reportItem["Matéria Prima"] = item.nome_materia_prima;
          }

          return reportItem;
        }),
        {
          header: this.hasSecondaryRawMaterial ? ['Região', 'Volume', 'Matéria Prima'] : ['Região', 'Volume']
        }
      );

      const workbook = XLSX.utils.book_new();

      const filename = "volume_regiao";

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },

}
</script>
