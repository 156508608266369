<template>
  <div class="d-flex justify-center">
    <v-alert
      v-if="isAlertVisible"
      dense
      light
      prominent
      border="left"
      colored-border
      type="info"
      icon="update"
      elevation="2"
      style="position: fixed; bottom: 0;z-index: 500;"
    >
      <div class="text-left">
        <div class="text-h6">
          Uma nova versão foi encontrada!
        </div>

        <div class="d-flex mt-2">
          <v-spacer />
          <v-btn
            small
            outlined
            color="secondary"
            @click="cancel()"
          >
            Cancelar
          </v-btn>
          <v-btn
            small
            outlined
            color="primary"
            class="ml-4"
            @click="update()"
          >
            Atualizar
          </v-btn>
        </div>
      </div>
    </v-alert>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isAlertVisible: false
    }
  },

  created() {
    this.initWorkbox()
  },

  methods: {
    initWorkbox() {
      console.log('initWorkbox', this.$workbox)

      this.$workbox?.addEventListener('installed', (event) => {
        console.log('wb:installed', { event })
        if (event.isUpdate) {
          console.log('New update found')
          this.isAlertVisible = true
        }
      })

      this.$workbox?.addEventListener('controllerchange', () => {
        console.log('wb:controllerchange')
        console.log('New update found')
        this.isAlertVisible = true
      })

      this.$workbox?.addEventListener('waiting', () => {
        console.log('wb:waiting')
        console.log('New update found')
        this.isAlertVisible = true
      })
    },
    cancel() {
      this.isAlertVisible = false
    },
    update() {
      this.isAlertVisible = false
      // this.$workbox?.messageSW({ type: 'SKIP_WAITING' })
      this.$workbox?.messageSkipWaiting()
    }
  },
}
</script>
