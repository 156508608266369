<template>
  <div class="dashboard">
    <GridStack
      name="BI - Preço Leite"
      :items="charts"
    >
      <template #filter>
        <v-menu
          ref="menu"
          transition="scale-transition"
          offset-y
          content-class="menu-select"
        >
          <template #activator="{ on }">
            <v-text-field
              :value="dateRefFilter"
              label="Mês de Referência"
              prepend-inner-icon="event"
              readonly
              dark
              filled
              hide-details
              v-on="on"
            />
          </template>

          <v-date-picker
            v-model="filter.date"
            :max="maxDateRef"
            type="month"
            no-title
            scrollable
          />
        </v-menu>
      </template>

      <template #default="{ widgets, isLocked, remove }">
        <GridStackItem
          v-for="widget in widgets"
          :key="widget.component"
          :widget="widget"
          :is-locked="isLocked"
          @remove="remove(widget)"
        >
          <component
            :is="widget.component"
            :reference-month="filter.date"
            height="100%"
          />
        </GridStackItem>
      </template>
    </GridStack>
  </div>
</template>

<script>
import GridStack from '@/Domains/BI/Components/GridStack.vue';
import GridStackItem from '@/Domains/BI/Components/GridStackItem.vue';

import TechnicianMilkPriceChart from "@/Domains/BI/Views/MilkPrice/Components/TechnicianMilkPriceChart.vue";
import RoutesFreightChart from "@/Domains/BI/Views/MilkPrice/Components/RoutesFreightChart.vue";
import MilkPriceCard from "@/Domains/BI/Views/MilkPrice/Components/MilkPriceCard.vue";
import AverageMilkPriceChart from "@/Domains/BI/Views/MilkPrice/Components/AverageMilkPriceChart.vue";
import MilkPriceCostChart from "@/Domains/BI/Views/MilkPrice/Components/MilkPriceCostChart.vue";
import AverageMilkPriceByRangeChart from "@/Domains/BI/Views/MilkPrice/Components/AverageMilkPriceByRangeChart.vue";
import RegionsFreightChart from '@/Domains/BI/Views/MilkPrice/Components/RegionsFreightChart.vue';
import ProducerRevenueChart from "@/Domains/BI/Views/MilkPrice/Components/ProducerRevenueChart.vue";

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

export default {
  components: {
    GridStack,
    GridStackItem,
    TechnicianMilkPriceChart,
    RoutesFreightChart,
    MilkPriceCard,
    AverageMilkPriceChart,
    MilkPriceCostChart,
    AverageMilkPriceByRangeChart,
    RegionsFreightChart,
    ProducerRevenueChart
  },

  data() {
    return {
      filter: {
        date: moment().subtract(1, "month").format("YYYY-MM"),
      },

      charts: [
        { w: 12, h: 1, component: 'MilkPriceCard', description: 'Preço Leite', default: { x: 0, y: 0 } },
        { w: 12, h: 7, component: 'RoutesFreightChart', description: 'Média Preço Leite por Rota', default: { x: 0, y: 1 } },
        { w: 12, h: 4, component: 'AverageMilkPriceChart', description: 'Média Preço Leite por Mês', default: { x: 0, y: 8 } },
        { w: 12, h: 4, component: 'AverageMilkPriceByRangeChart', description: 'Média Preço Leite por Faixa', default: { x: 0, y: 12 } },
        { w: 12, h: 6, component: 'TechnicianMilkPriceChart', description: 'Média Preço Leite por Técnicos', default: { x: 0, y: 16 } },
        { w: 12, h: 6, component: 'RegionsFreightChart', description: 'Média Preço Leite por Região', default: { x: 0, y: 22 } },
        { w: 12, h: 5, component: 'MilkPriceCostChart', description: 'Custo Leite por Mês', dateAdded: '2023-12-30' },
        { w: 12, h: 6, component: 'ProducerRevenueChart', description: 'Total Movimentado por Mês e Perfil dos Produtores', default: { x: 0, y: 28 } }
      ],
    }
  },

  computed: {

    /**
     * Recupera o mês atual para definir o período máximo disponível para filtro
     * @returns {string}
     */
    maxDateRef() {
      return moment().format("YYYY-MM");
    },

    /**
     * Mês de referência definido nos filtros
     * @returns {string|null}
     */
    dateRefFilter() {
      if (isEmpty(this.filter.date)) {
        return null;
      }

      return moment(this.filter.date, "YYYY-MM").format("MM/YYYY");
    },
  },

};
</script>
