<template>
  <div
    class="ml-10 mr-10"
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Relatório Sanidade
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          v-model="filter.typeExam"
          :items="typeExamFilter"
          label="Tipo"
          prepend-inner-icon="biotech"
          dark
          filled
          hide-details
          @change="onLoad"
        />
      </v-col>

      <v-col
        md="3"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          @change="onLoad"
        />
      </v-col>
      <v-col
        md="3"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          @change="onLoad"
        />
      </v-col>

      <v-col
        md="3"
      >
        <v-text-field
          v-model="filter.search"
          prepend-inner-icon="search"
          label="Buscar"
          single-line
          hide-details
          dark
          filled
        />
      </v-col>

      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          v-if="tab == 2"
          v-model="filter.typeComingDue"
          :items="typeComingDueFilter"
          label="À vencer"
          prepend-inner-icon="event"
          dark
          filled
          clearable
          hide-details
          @change="onLoad"
        />
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
      @change="onTabChange"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
      >
        {{ tab.title }}
      </v-tab>

      <v-tab-item
        v-for="tab in tabs"
        :key="tab.title"
        background-color="transparent"
        dark
      >
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            {{ `Produtores: ${ tab.items.length}` }}
          </v-card-title>
          <data-table
            ref="report"
            :headers="tab.headers"
            :items="tab.items"
            :search="filter.search"
            :show-expand="(filter.typeExam === 'VACINAS' || filter.typeExam === 'ATESTADO_CONFORMIDADE') && !withoutVaccines"
            dark
            show-custom-group
            item-key="id_pessoa"
            class="elevation-1"
          >
            <template #expanded-item="{ headers, item }"  >
              <td
                :colspan="headers.length"
                class="pa-0 text-center"
              >
                <data-table
                  ref="report"
                  :headers="headersVaccines"
                  :items="item.vacinas"
                  dark
                  class="elevation-1 ma-3"
                />
              </td>
            </template>
          </data-table>
          <v-card-title>
            <v-spacer />
          </v-card-title>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

import moment from "moment-timezone";
import _ from "lodash";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  name: "report-brucelose-tuberculose",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter
  },

  mixins: [ReportMixin],

  data() {
    return {
      filter: {
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        search: '',
        typeExam: 'BRUCELOSE',
        typeComingDue: 30,
      },

      tab: null,
      tabs: null,

      notExam: [],
      notVaccine: [],
      expired: [],
      comingDue: [],
      regular: [],
      withVaccines: [],

      withoutVaccines: false,

      headers: [
        { text: 'Código', value: 'codigo_laticinio' },
        { text: 'Produtor', value: 'nome' },

        { text: 'Vac. (Tipo)', value: 'vacinas.tipo' },
        { text: 'Vac. (Data)', value: 'vacinas.data' },
        { text: 'Vac. (Qtd. Animais)', value: 'vacinas.quantidade_animais' },
        { text: 'Vac. (Veterinário)', value: 'vacinas.veterinarian.nome' },

        { text: 'Bruc. (Data)', value: 'brucelose.data' },
        { text: 'Bruc. (Qtd. Animais)', value: 'brucelose.quantidade_animais' },
        { text: 'Bruc. (Veterinário)', value: 'brucelose.veterinarian.nome' },
        { text: 'Bruc. (Vencimento)', value: 'brucelose.data_vencimento' },

        { text: 'Tub. (Data)', value: 'tuberculose.data' },
        { text: 'Tub. (Qtd. Animais)', value: 'tuberculose.quantidade_animais' },
        { text: 'Tub. (Veterinário)', value: 'tuberculose.veterinarian.nome' },
        { text: 'Tub. (Vencimento)', value: 'tuberculose.data_vencimento' },
      ],

      headersVaccines: [
        { text: 'Vac. (Tipo)', value: 'tipo' },
        { text: 'Vac. (Data)', value: 'data' },
        { text: 'Vac. (Qtd. Animais)', value: 'quantidade_animais' },
        { text: 'Vac. (Veterinário)', value: 'veterinarian.nome' },
      ],

      typeExamFilter: [
        { value: 'BRUCELOSE', text: 'Brucelose' },
        { value: 'TUBERCULOSE', text: 'Tuberculose' },
        { value: 'VACINAS', text: 'Vacinas' },
        { value: 'ATESTADO_CONFORMIDADE', text: 'Atestado Conformidade' },
      ],

      typeComingDueFilter: [
        { value: 15, text: '15 dias' },
        { value: 30, text: '30 dias' },
        { value: 60, text: '60 dias' },
        { value: 90, text: '90 dias' },
        { value: 120, text: '120 dias' },
      ],
    };
  },

  computed: {
    filteredItems() {
      return this.search(this.notExam);
    },

    filteredItems2() {
      return this.search(this.expired);
    },

    filteredItems3() {
      return this.search(this.comingDue);
    },

    filteredItems4() {
      return this.search(this.regular);
    },
    filteredItems5() {
      return this.search(this.notVaccine);
    },
    filteredItems6() {
      return this.search(this.withVaccines);
    },

  },

  async created() {
    await this.loadExam();
    await this.getHeaders();
    await this.loadTabs();
  },

  methods: {
    async onLoad() {
      this.notExam = [],
        this.expired = [],
        this.comingDue = [],
        this.regular = [],

        await this.loadExam();
        await this.getHeaders();
        await this.loadTabs();
    },

    async getHeaders() {


      if(!this.withoutVaccines && (this.filter.typeExam === 'VACINAS' || this.filter.typeExam === 'ATESTADO_CONFORMIDADE')) {
        this.headers = [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Produtor', value: 'nome' },
        ];
      } else {
        this.headers = [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Produtor', value: 'nome' },

          { text: 'Vac. (Tipo)', value: 'vacinas.tipo' },
          { text: 'Vac. (Data)', value: 'vacinas.data' },
          { text: 'Vac. (Qtd. Animais)', value: 'vacinas.quantidade_animais' },
          { text: 'Vac. (Veterinário)', value: 'vacinas.veterinarian.nome' },

          { text: 'Vac. (Tipo)', value: 'atestado_conformidade.tipo' },
          { text: 'Vac. (Data)', value: 'atestado_conformidade.data' },
          { text: 'Vac. (Qtd. Animais)', value: 'atestado_conformidade.quantidade_animais' },
          { text: 'Vac. (Veterinário)', value: 'atestado_conformidade.veterinarian.nome' },

          { text: 'Bruc. (Data)', value: 'brucelose.data' },
          { text: 'Bruc. (Qtd. Animais)', value: 'brucelose.quantidade_animais' },
          { text: 'Bruc. (Veterinário)', value: 'brucelose.veterinarian.nome' },
          { text: 'Bruc. (Vencimento)', value: 'brucelose.data_vencimento' },

          { text: 'Tub. (Data)', value: 'tuberculose.data' },
          { text: 'Tub. (Qtd. Animais)', value: 'tuberculose.quantidade_animais' },
          { text: 'Tub. (Veterinário)', value: 'tuberculose.veterinarian.nome' },
          { text: 'Tub. (Vencimento)', value: 'tuberculose.data_vencimento' },
        ];
      }
    },

    async loadTabs() {

      if(this.filter.typeExam === 'BRUCELOSE' || this.filter.typeExam === 'TUBERCULOSE') {
        this.tabs = [
          {
            title: 'Sem Exames',
            items: this.filteredItems,
            headers: this.filteredHeaders(),
          },
          {
            title: 'Vencidos',
            items: this.filteredItems2,
            headers: this.filteredHeaders(),
          },
          {
            title: 'À vencer',
            items: this.filteredItems3,
            headers: this.filteredHeaders(),
          },
          {
            title: 'Regular',
            items: this.filteredItems4,
            headers: this.filteredHeaders(),
          },
        ];
      }

      if(this.filter.typeExam === 'VACINAS' || this.filter.typeExam === 'ATESTADO_CONFORMIDADE') {
        this.tabs = [
          {
            title: 'Sem Vacinas',
            items: this.filteredItems5,
            headers: this.filteredHeaders(),
          },
          {
            title: 'Com Vacinas',
            items: this.filteredItems6,
            headers: this.filteredHeaders(),
          },
        ]
      }
    },


    async loadExam() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/health-exam/report`, { params: {
            tecnico: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id)
          }});

        const today = moment().format("YYYY-MM-DD");

        this.notExam = this.notHaveExam(data, this.filter.typeExam);
        this.expired = this.expiredExam(data, this.filter.typeExam, today);
        this.comingDue = this.comingDueExam(data, this.filter.typeExam, today);
        this.regular = this.regularExam(data, this.filter.typeExam, today);
        this.notVaccine = this.notHaveVaccine(data, this.filter.typeExam);
        this.withVaccines = this.regularVaccines(data, this.filter.typeExam);

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os exames!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    print() {
      const title = this.tabs[this.tab].title;
      this.$refs.report[this.tab].print(null, title);
    },

    exportExcel() {
      const title = this.tabs[this.tab].title;
      this.$refs.report[this.tab].exportExcel(null, title);
    },

    filteredHeaders() {
      if(!this.filter.typeExam) {
        return this.headers;
      }

      return this.headers.filter(item => {
        const typeExam = item.value.split(".")[0].toUpperCase();
        const typeExamVerify = (typeExam != 'BRUCELOSE' && typeExam != 'TUBERCULOSE' && typeExam != 'VACINAS' && typeExam != 'ATESTADO_CONFORMIDADE');
        const typeExamFilter = (this.filter.typeExam == typeExam);

        return typeExamFilter || typeExamVerify;
      });
    },

    notHaveExam(data, type) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => !item[exam]);
      return this.formatExams(type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa'));
    },

    expiredExam(data, type, today) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento < today));
      return this.formatExams(type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa'));
    },

    comingDueExam(data, type, today) {
      const coming = moment().add(30, "days").format("YYYY-MM-DD");
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento < coming && item[exam].data_vencimento >= today));

      return this.formatExams(type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa'));
    },

    regularExam(data, type, today) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento > today));
      return this.formatExams(type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa'));
    },

    notHaveVaccine(data, type) {
      const formatVacinas = (item) => {

        if(type === 'VACINAS') {
          // Criar um objeto de vacina com o tipo "Sem Vacina"
          item.vacinas = {
            tipo: "Sem Vacina",
            data: "",
            quantidade_animais: 0,
            id_produtor: item.id_pessoa
          };
        }

        if(type === 'ATESTADO_CONFORMIDADE') {
          // Criar um objeto de vacina com o tipo "Sem Vacina"
          item.atestado_conformidade = {
            tipo: "Sem Vacina",
            data: "",
            quantidade_animais: 0,
            id_produtor: item.id_pessoa
          };
        }

        return item;
      };

      let filteredData = [];

      if(type === 'VACINAS') {
        // Filtrar produtores que não possuem vacinas ou têm vacinas vazias
        filteredData = data.filter(item => !item.vacinas || item.vacinas.length === 0);
      }

      if(type === 'ATESTADO_CONFORMIDADE') {
        // Filtrar produtores que não possuem vacinas ou têm vacinas vazias
        filteredData = data.filter(item => !item.atestado_conformidade || item.atestado_conformidade.length === 0);
      }

      // Formatar os produtores adicionando o objeto de vacina "Sem Vacina"
      filteredData = filteredData.map(formatVacinas);

      return this.formatExams(filteredData);
    },

    regularVaccines(data, type) {

      let exams = '';

      if(type === 'VACINAS') {
        exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && item.vacinas && item.vacinas.length > 0);
        return this.formatExams(type ? exams() : _.unionBy(exams('vacinas'), 'id_pessoa'));
      }

      if(type === 'ATESTADO_CONFORMIDADE') {
        exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && item.atestado_conformidade && item.atestado_conformidade.length > 0);
        return this.formatExams(type ? exams() : _.unionBy(exams('atestado_conformidade'), 'id_pessoa'));
      }

      return [];

    },

    formatExams(data) {
      const format = (item, isVaccine = false) => {
        const { data, data_vencimento, quantidade_animais, ...rest } = item || {};
        return {
          data: data ? this.formatDate(data, 'DD/MM/YYYY') : (isVaccine ? 'Sem Vacina' : 'Sem Exame'),
          quantidade_animais: quantidade_animais || 0,
          data_vencimento: !isVaccine && data_vencimento ? this.formatDate(data_vencimento, 'DD/MM/YYYY') : null,
          ...rest
        };
      };

      return data.map(({brucelose, tuberculose, vacinas, ...rest}) => ({
        brucelose: format(brucelose),
        tuberculose: format(tuberculose),
        vacinas: vacinas,
        ...rest
      }));
    },

    formatDate(value, format) {
      if (!value) {
        return '';
      } else {
        return moment(value).format(format)
      }
    },

    search(data) {
      return this.filter.search ? data.filter(item => JSON.stringify(Object.values(item)).toUpperCase().includes(this.filter.search.toUpperCase())) : data;
    },

    onTabChange() {
      const selectedTabTitle = this.tabs[this.tab]?.title;
            // Agora você pode usar o título da aba selecionada para qualquer outra lógica
      if (selectedTabTitle === 'Sem Vacinas') {
        this.withoutVaccines = true;
      } else {
        this.withoutVaccines = false;
      }

      this.getHeaders();
      this.filteredHeaders();
      this.loadTabs();
    }
  },
};
</script>
