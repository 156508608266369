<template>
  <v-container>
    <v-card
      color="transparent"
      dark
    >
      <v-card-title class="pb-0">
        <v-row>
          <v-col>
            <v-date-range-picker
              v-model="filters.dateRange"
              class="pa-0"
              :style="dynamicFilterWidth()"
              @change="onFilter"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="filters.referenceDate"
              :items="['DESCARGA', 'COLETA']"
              label="Data de referência"
              prepend-inner-icon="local_shipping"
              hide-details
              dark
              filled
              :style="dynamicFilterWidth()"
              @change="onFilter"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.producerStatus"
              :items="['TODOS','ATIVOS', 'INATIVOS']"
              label="Status produtor"
              hide-details
              dark
              filled
              :style="dynamicFilterWidth()"
              @change="onFilter"
            />
          </v-col>
          <v-col>
            <routes-autocomplete-filter
              v-model="filters.routes"
              label="Rota"
              dark
              multiple
              :style="dynamicFilterWidth()"
              @change="onFilter"
            />
          </v-col>
          <v-col v-if="hasSecondaryRawMaterial">
            <v-select
              v-model="rawMaterial"
              :items="rawMaterialItems"
              label="Matéria Prima"
              prepend-inner-icon="info"
              dark
              filled
              hide-details
              clearable
              :style="dynamicFilterWidth()"
              @change="onFilter"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="filters.search"
              filled
              prepend-inner-icon="search"
              :style="dynamicFilterWidth()"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        class="collecton-city-table"
        dark
        :items="items"
        :search="filters.search"
        :headers="headers"
        show-expand
        item-key="id"
      >
        <template #[`item.data`]="{value}">
          <span>{{ formatDate(value, "DD/MM/YYYY") }}</span>
        </template>
        <template #[`item.volume`]="{ value }">
          <v-chip
            dark
            small
            color="teal darken-3"
          >
            {{ formatNumber(value) }}
          </v-chip>
        </template>
        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col
                cols="12"
              >
                <v-data-table
                  :items="item.info_produtor"
                  :headers="headersProducer"
                >
                  <template #[`item.volume`]="{ value }">
                    <v-chip
                      dark
                      small
                      color="teal darken-1"
                    >
                      {{ formatNumber(value) }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
      <div
        class="text-caption text-left ml-4"
        style="margin-top: -58px; height: 58px; line-height: 58px;"
      >
        Volume total
        <v-chip
          small
          class="mx-2"
        >
          {{ formatNumber(volumeTotal) }}
        </v-chip>
        de
        <v-chip
          small
          class="mx-2"
        >
          {{ formatNumber(totalCitys) }}
        </v-chip>
        Municípios
      </div>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import FilterRawMaterialMixin from "@/Support/Mixins/FilterRawMaterialMixin.js";

export default {
  components: {
    VDateRangePicker,
    RoutesAutocompleteFilter,
  },

  mixins: [ReportMixin, FilterRawMaterialMixin],

  data() {
    return {
      loading: false,

      filters: {
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],

        search: null,

        routes: [],
        producerStatus: 'TODOS',
        referenceDate: 'DESCARGA',
      },

      headers: [
        { text: 'Cidade', value: 'end_cidade' },
        { text: 'Data', value: 'data', width: 130, align: 'center' },
        { text: 'Nº Produtores', value: 'produtores', width: 150, align: 'center' },
        { text: 'Volume (L)', value: 'volume', width: 150, align: 'center' },
      ],

      headersProducer: [
        { text: 'Código', value: 'codigo_produtor', width: 150, align: 'center' },
        { text: 'Nome', value: 'nome', align: 'left' },
        { text: 'Volume (L)', value: 'volume', align: 'center' }
      ],

      items: [],
    };
  },
  computed: {
    totalCitys() {
      return this.items.length;
    },
    volumeTotal() {
      return _.sumBy(this.items, "volume");
    },
  },

  mounted() {
    this.loadVolume();
    this.addRawMaterialToGrid("headers", this.headerRawMaterial);
    this.addRawMaterialToGrid("headersProducer", this.headerRawMaterial);
  },

  methods: {
    onFilter() {
      this.loadVolume();
    },

    async loadVolume() {
      try {
        this.loading = true;
        const [dataInicio, dataFim] = this.filters.dateRange;
        const routes = this.filters.routes.map(({ id }) => id);

        const { data } = await this.$axios.post(
          `/relatorios/volumePorCidade`,
          {
            data_inicio: dataInicio || moment().format('YYYY-MM-DD'),
            data_fim: dataFim || moment().format('YYYY-MM-DD'),
            rotas: routes,
            data_referencia_coleta: this.filters.referenceDate,
            status_produtor: this.filters.producerStatus,
            materiaPrima: this.rawMaterial || null,
          }
        );

        if (!_.isArray(data)) {
          throw data;
        }

        this.items = data;
      } catch (e) {
        this.$snotify.error("Erro ao carregar o relatório", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    getReportJson() {
      return [
        ...this.items.map(item => ({
          'Cidade': item.end_cidade,
          'Data': this.formatDate(item.data, "DD/MM/YYYY"),
          'Nº Produtores': item.produtores,
          'Volume': this.formatNumber(item.volume),
          ...(this.hasSecondaryRawMaterial ? { 'Matéria Prima': item.nome_materia_prima } : {}),
        })),
        {
          '': ':',
        },
        {
          'Cidade': 'Total:',
          'Data': ' ------------- ',
          'Nº Produtores': _.sumBy(this.items, "produtores"),
          'Volume': _.sumBy(this.items, "volume"),
        }
      ];
    },

    print() {
      let [dataInicio, dataFim] = this.filters.dateRange;
      dataInicio = moment(dataInicio || moment()).format('DD-MM-YYYY');
      dataFim = moment(dataFim || moment()).format('DD-MM-YYYY');
      return this.printFromJson(this.getReportJson(), `Volume por município - ${dataInicio} - ${dataFim}`);
    },

    exportExcel() {
      const data = this.XLSX.utils.json_to_sheet(this.getReportJson());

      const workbook = this.XLSX.utils.book_new();

      let [dataInicio, dataFim] = this.filters.dateRange;
      dataInicio = moment(dataInicio || moment()).format('DD-MM');
      dataFim = moment(dataFim || moment()).format('DD-MM');

      const filename = `Volume por mun. - ${dataInicio} - ${dataFim}`;

      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
