<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        Embalagem/Pesagem
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formRef">
          <v-row class="mt-2">
            <v-col
              class="py-0"
              :cols="form.showQueijomatic && form.item.requiresLot ? '12' : undefined"
            >
              <v-text-field
                :value="form.item.description"
                label="Produto"
                prepend-inner-icon="inventory"
                outlined
                disabled
              />
            </v-col>
            <v-col
              v-if="form.showQueijomatic"
              class="py-0"
            >
              <v-select
                v-model="form.tankId"
                :items="tinas"
                label="Queijomatic *"
                item-value="id_tina"
                item-text="descricao"
                outlined
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              v-if="form.item.requiresLot"
              class="py-0"
            >
              <v-text-field
                v-model="form.lote.numero"
                label="Lote *"
                outlined
                :rules="[rules.required]"
                @blur="checkIfHasPersistedLots()"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="form.item.requiresLot"
            class="mt-2"
          >
            <v-col
              class="py-0"
            >
              <date-text-field
                v-model="form.lote.data_fabricacao"
                label="Fabricação"
                manual
                max=""
                outlined
                :disabled="form.lote.lote_existente"
                :rules="[rules.date]"
              />
            </v-col>
            <v-col
              class="py-0"
            >
              <date-text-field
                v-model="form.lote.data_validade"
                label="Validade"
                manual
                max=""
                outlined
                :disabled="form.lote.lote_existente"
                :rules="[rules.date]"
              />
            </v-col>
          </v-row>

          <scale-weighing
            v-if="dialog"
            v-model="form"
            settings-id="PRODUCT-WEIGHING"
            hide-tare
            @save="save"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click.native="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-menu
          v-model="settings.dialog"
          :close-on-content-click="false"
          top
          left
          content-class="settings-menu"
        >
          <template #activator="{ on }">
            <v-btn
              icon
              small
              @click="openSettings"
            >
              <v-icon>settings</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>Configurações</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="settings.computerId"
                    label="ID Computador"
                    outlined
                    small
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="settings.autoWeighing"
                    label="Peso automático"
                    :items="[
                      { text: 'Sim', value: true },
                      { text: 'Não', value: false }
                    ]"
                    outlined
                    small
                    hide-details
                    :disabled="!hasManualWeighingPermission"
                  />
                </v-col>
                <v-col
                  v-if="settings.autoWeighing"
                  cols="6"
                >
                  <masked-text-field
                    v-model="settings.minBoxWeight"
                    label="Peso Bruto Mínimo Caixa"
                    suffix="Kg"
                    :mask="masks.float"
                    unmask
                    inputmode="numeric"
                    outlined
                    small
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="saveSettings"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.settings-menu {
  min-width: 350px !important;
  max-width: 350px !important;
}
</style>

<script setup>
import { reactive, ref, computed, watch } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import { usePermissions } from '@/Support/Composables/permissions.js'
import { useDialogHistory } from '@/Support/Composables/dialogHistory.js'
import axios from '@/Support/Resources/axios-instance.js'

import { usePacking } from '@/Domains/Shipment/Composable/Packing.js'

import moment from 'moment'

import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import DateTextField from '@/Support/Components/DateTextField.vue'
import ScaleWeighing from '@/Domains/Shipment/StorageControl/Components/ScaleWeighing.vue'

const { progressBar, notify } = useUtils()

const { packingSettings, savePackingSettings } = usePacking('PRODUCT-WEIGHING')

const { hasPermission } = usePermissions()

const rules = {
  required: (v) => !!v || 'Campo obrigatório!',
  date: (v) => !v || moment(v, 'YYYY-MM-DD').isValid() || 'Campo Inválido!',
}

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
}

// eslint-disable-next-line
const props = defineProps({
  warehouseId: String,
})

const formRef = ref()

const form = reactive({
  item: {},
  showQueijomatic: false,
  tankId: null,
  lote: {
    numero: null,
    data_fabricacao: null,
    data_validade: null,
  },
  grossWeight: null,
  tareWeight1: null,
  tareWeight2: null,
})

// eslint-disable-next-line no-undef
const emit = defineEmits(['close'])

const dialog = ref(false)

useDialogHistory(dialog)

const persistedLots = ref([])

const show = async ({ id, showQueijomatic }) => {
  dialog.value = true

  formRef.value?.resetValidation()

  form.showQueijomatic = showQueijomatic || false
  form.tankId = null
  form.lote = {
    numero: null,
    data_fabricacao: null,
    data_validade: null,
    lote_existente: false,
  }
  form.grossWeight = null
  form.tareWeight1 = null
  form.tareWeight1 = null

  if (form.showQueijomatic) {
    loadQueijomatics()
  }

  try {
    progressBar.loading();

    const { data } = await axios.get(`/item/items-with-lots`, { params: {
      id_deposito: props.warehouseId,
      ids: [id],
    } });

    const [product] = data

    form.item = {
      id: product.id_item,
      code: product.cod_item,
      name: product.nome,
      description: `${product.cod_item} - ${product.nome}`,
      requiresLot: product.controlar_lotes,
      manualLot: product.manualLot,
    }

    persistedLots.value = product.stocks.map(lot => ({
      ...lot,
      text: lot.numero_lote || 'Sem Lote'
    }));
  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao carregar estoques'
    notify.error(message, 'Atenção');
    console.warn(error);
  } finally {
    progressBar.hide();
  }
}

const tinas = ref([])

const loadQueijomatics = async () => {
  try {
    const { data } = await axios.get(`/silo/listaTina`);

    tinas.value = data;
  } catch (e) {
    notify.error('Erro ao carregar as tinas', 'Atenção');
    console.warn(e);
  }
}

watch(dialog, (isOpen) => {
  if (!isOpen) { emit('close') }
})

const close = () => {
  dialog.value = false
}

const save = async () => {
  const isValidated = await formRef.value?.validate()

  if (!isValidated) {
    const errors = formRef.value.inputs
      .filter(item => item.hasError)
      .map(item => item.label ? `<span><b>${item.label.replace(' *', '')}</b>: ${item.errorBucket[0]}</span>` : `<b>${item.errorBucket[0]}</b>`)

    if (errors.length) {
      notify.error('Atenção', { html: `<div class="text-h4">Atenção</div>${errors.join('\n')}` })
    }

    return
  }

  emit('save', {
    itemId: form.item.id,
    lote: form.lote.numero ? {
      ...form.lote,
      quantidade: form.grossWeight,
    } : null,
    tankId: form.tankId,
    grossWeight: form.grossWeight,
  })

  close()
}

const hasManualWeighingPermission = computed(() => hasPermission('manual-weighing'))

const settings = reactive({
  dialog: false,
  computerId: null,
  autoWeighing: false,
  minBoxWeight: null,
})

const openSettings = () => {
  settings.dialog = true
  settings.computerId = packingSettings.value.computerId
  settings.autoWeighing = packingSettings.value.autoWeighing || !hasManualWeighingPermission.value
  settings.minBoxWeight = packingSettings.value.minBoxWeight
}

const saveSettings = async () => {
  try {
    progressBar?.saving()

    await savePackingSettings({
      computerId: settings.computerId,
      autoWeighing: settings.autoWeighing,
      minBoxWeight: settings.minBoxWeight,
    })

  } catch (error) {
    console.error(error)
    const message = error?.response?.data?.message || 'Erro ao salvar as configurações'
    notify.error(message, 'Atenção', { timeout: 6000 })
  } finally {
    progressBar?.hide()
    settings.dialog = false
  }
}

const checkIfHasPersistedLots = () => {
  const lotNumber = form.lote.numero;

  const lot = persistedLots.value.find((item) => item.numero_lote === lotNumber);

  form.lote.data_fabricacao = lot?.data_fabricacao;
  form.lote.data_validade = lot?.data_validade;
  form.lote.lote_existente = !!lot;

}

// eslint-disable-next-line no-undef
defineExpose({ show })
</script>
