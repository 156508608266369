import { default as api } from '@/Support/Resources/axios-instance.js';
import { round } from '@/Support/Resources/utils.js';
import store from '@/Support/Resources/vuex.js'

export default {
  async index(params = {}) {
    const { data } = await api.get('/production-order', { params });
    return data.map((order) => ({
      ...order,
      started: order.itens?.some(item => item.quantidade_realizada > 0),
      tolerance: order.produto?.parametros_producao?.tolerancia_perc && order.quantidade_planejada
        ? {
          perc: order.produto.parametros_producao.tolerancia_perc,
          min: order.quantidade_planejada * (1 - (order.produto.parametros_producao.tolerancia_perc / 100)),
          max: order.quantidade_planejada * (1 + (order.produto.parametros_producao.tolerancia_perc / 100)),
          // Verifica se o minimo foi atingido, o máximo já é validado na entrada de produção
          canBeFinished: (order.quantidade_planejada * (1 - (order.produto.parametros_producao.tolerancia_perc / 100))) < order.quantidade_realizada
        }
        : { canBeFinished: true },
    }));
  },

  async show(id) {
    const { data } = await api.get(`/production-order/${id}`);

    const getItemRange = (stepId, itemId, accomplishedQuantity) => {
      if (!data.quantidade_planejada) {
        return {
          min: null,
          max: null,
        }
      }

      const item = data.produto.formulacao?.itens?.find((item) => item.id_etapa == stepId && item.id_item === itemId) || {};

      const range = {
        min: item.quantidade_minima ? round(data.quantidade_planejada * item.quantidade_minima, 4) - accomplishedQuantity : null,
        max: item.quantidade_maxima ? round(data.quantidade_planejada * item.quantidade_maxima, 4) - accomplishedQuantity : null,
      };

      if (range.min < 0) {
        range.min = 0;
      }

      return range;
    }

    const getByproductRange = (stepId, itemId, accomplishedQuantity) => {
      if (!data.quantidade_planejada) {
        return {
          min: null,
          max: null,
        }
      }

      const item = data.produto.formulacao?.subprodutos?.find((item) => item.id_etapa == stepId && item.id_item === itemId) || {};

      const range = {
        min: item.quantidade_minima ? round(data.quantidade_planejada * item.quantidade_minima, 4) - accomplishedQuantity : null,
        max: item.quantidade_maxima ? round(data.quantidade_planejada * item.quantidade_maxima, 4) - accomplishedQuantity : null,
      };

      if (range.min < 0) {
        range.min = 0;
      }

      return range;
    }

    const getParam = (type, stepId, itemId, field) => {
      if (type == 'ITEM') {
        return data.produto.formulacao?.itens?.find((item) => item.id_etapa == stepId && item.id_item === itemId)?.[field];
      }

      return data.produto.formulacao?.subprodutos?.find((item) => item.id_etapa == stepId && item.id_item === itemId)?.[field];
    }

    return {
      id: id,
      code: data.codigo,
      started: data.itens?.some(item => item.quantidade_realizada > 0),
      showQueijomatic: data.produto.parametros_producao?.usa_queijomatic,
      item: data.produto ? {
        id: data.produto.id_item,
        description: `${data.produto.cod_item} - ${data.produto.nome}`,
        code: data.produto.cod_item,
        name: data.produto.nome,
        type: data.produto.tipo,
        measurement: data.produto.unidade,
        expirationDate: data.produto.parametros_producao?.prazos_validade?.[store.state.settings?.laticinio?.id],
        requiresLot: data.produto.controlar_lotes || false,
        manualLot: data.produto.lote_manual || false,
        lotFormat: data.produto.parametros_producao?.formato_lote,
        stockControl: data.produto.controle_estoque,
        scaleItem: data.produto?.item_de_balanca || false,
        rawMaterialGroupId: data.produto.id_agrupador_materia_prima,
        isRawMaterial: data.produto.controle_estoque === 'SILO' || data.produto.id_agrupador_materia_prima,
        steps: (data.produto.etapas_producao || []).map((item) => ({
          id: item.id,
          description: item.descricao,
          duration: item.duracao,
        })),
        customs: (data.produto.personalizados_producao || []).map((item) => ({
          id: item.id,
          title: item.titulo,
          step: item.id_etapa ? { id: item.id_etapa, description: item.etapa, } : undefined,
          fields: item.campos.map((field) => ({
            id: field.id,
            label: field.rotulo,
            type: field.tipo,
            options: field.opcoes,
            required: field.obrigatorio,
          })),
        })),
      } : null,
      tolerance: data.produto?.parametros_producao?.tolerancia_perc && data.quantidade_planejada
        ? {
          perc: data.produto.parametros_producao.tolerancia_perc,
          min: data.quantidade_planejada * (1 - (data.produto.parametros_producao.tolerancia_perc / 100)),
          max: data.quantidade_planejada * (1 + (data.produto.parametros_producao.tolerancia_perc / 100)),
          // Verifica se o minimo foi atingido, o máximo já é validado na entrada de produção
          canBeFinished: (data.quantidade_planejada * (1 - (data.produto.parametros_producao.tolerancia_perc / 100))) < data.quantidade_realizada
        }
        : { canBeFinished: true },
      status: data.status,
      description: data.descricao,
      quantity: parseFloat(data.quantidade_planejada) || null,
      accomplishedQuantity: parseFloat(data.quantidade_realizada),
      lotFormat: data.formato_lote,
      lotNumber: data.numero_lote,
      date: data.data_pedido,
      startDate: data.data_inicio,
      dueDate: data.data_vencimento,
      items: data.itens
        .map((item) => ({
          id: item.id_ordem_producao_item,
          type: item.tipo,
          itemId: item.id_item,
          item: {
            id: item.id_item,
            description: item.produto?.nome,
            code: item.produto?.cod_item,
            type: item.tipo,
            measurement: item.produto?.unidade,
            requiresLot: item.produto?.controlar_lotes || false,
            manualLot: item.produto?.lote_manual || false,
            stockControl: item.produto?.controle_estoque,
            scaleItem: item.produto?.item_de_balanca || false,
            rawMaterialGroupId: item.produto?.id_agrupador_materia_prima,
            isRawMaterial: item.produto?.controle_estoque === 'SILO' || item.produto?.id_agrupador_materia_prima,
          },
          quantity: parseFloat(item.quantidade_base),
          showQueijomatic: getParam('ITEM', item.id_etapa, item.id_item, 'exibir_tina'),
          showLosses: getParam('ITEM', item.id_etapa, item.id_item, 'exibir_perda'),
          range: getItemRange(item.id_etapa, item.id_item, item.quantidade_realizada),
          plannedQuantity: parseFloat(item.quantidade_base) * parseFloat(data.quantidade_planejada || 0),
          accomplishedQuantity: parseFloat(item.quantidade_realizada),
          lossesQuantity: parseFloat(item.quantidade_perdas) || 0,
          writeOffType: item.tipo_baixa,
          step: item.id_etapa ? { id: item.id_etapa, description: item.etapa, } : undefined,
          active: 1,
        })),
      byproducts: data.subprodutos
        .filter(item => !item.coproduto)
        .map((item) => ({
          id: item.id_ordem_producao_subproduto,
          type: item.tipo,
          item: {
            id: item.id_item,
            description: item.produto?.nome,
            code: item.produto?.cod_item,
            type: item.tipo,
            measurement: item.produto?.unidade,
            requiresLot: item.produto?.controlar_lotes || false,
            manualLot: item.produto?.lote_manual || false,
            stockControl: item.produto?.controle_estoque,
            scaleItem: item.produto?.item_de_balanca || false,
            rawMaterialGroupId: item.produto?.id_agrupador_materia_prima,
            isRawMaterial: item.produto?.controle_estoque === 'SILO' || item.produto?.id_agrupador_materia_prima,
          },
          quantity: parseFloat(item.quantidade_base),
          showQueijomatic: getParam('SUBPRODUTO', item.id_etapa, item.id_item, 'exibir_tina'),
          showLosses: getParam('SUBPRODUTO', item.id_etapa, item.id_item, 'exibir_perda'),
          range: getByproductRange(item.id_etapa, item.id_item, item.quantidade_realizada),
          plannedQuantity: parseFloat(item.quantidade_base) * parseFloat(data.quantidade_planejada || 0),
          accomplishedQuantity: parseFloat(item.quantidade_realizada),
          lossesQuantity: parseFloat(item.quantidade_perdas) || 0,
          entryType: item.tipo_entrada,
          step: item.id_etapa ? { id: item.id_etapa, description: item.etapa, } : undefined,
          active: 1,
        })),
      coproducts: data.subprodutos
        .filter(item => item.coproduto)
        .map((item) => ({
          id: item.id_ordem_producao_subproduto,
          type: item.tipo,
          item: {
            id: item.id_item,
            description: item.produto?.nome,
            code: item.produto?.cod_item,
            type: item.tipo,
            measurement: item.produto?.unidade,
            requiresLot: item.produto?.controlar_lotes || false,
            manualLot: item.produto?.lote_manual || false,
            stockControl: item.produto?.controle_estoque,
            rawMaterialGroupId: item.produto?.id_agrupador_materia_prima,
            isRawMaterial: item.produto?.controle_estoque === 'SILO' || item.produto?.id_agrupador_materia_prima,
          },
          quantity: parseFloat(item.quantidade_base),
          accomplishedQuantity: parseFloat(item.quantidade_realizada),
          lossesQuantity: parseFloat(item.quantidade_perdas) || 0,
          entryType: 'MANUAL',
          active: 1,
          isCoproduct: true,
        })),
      transfers: data.movimentos
        .map(transfer => ({
          type: transfer.tipo,
          movementId: transfer.id_silo_movimento,
          productionId: transfer.id_producao,
          groupedProductionId: transfer.id_agrupador_producao,
          originId: transfer.id_op_referencia,
          silo: {
            id: transfer.silo.id_silo,
            description: transfer.silo.descricao,
          },
          tinas: JSON.parse(transfer.tinas) || [],
          item: transfer.id_item ? {
            id: transfer.id_item,
            description: data.produto.nome,
          } : null,
          productionOrder: transfer.id_ordem_producao ? {
            id: transfer.id_ordem_producao,
            description: data.descricao,
          } : null,
          rawMaterial: {
            id: transfer.id_materia_prima,
            description: transfer.nome_materia_prima,
          },
          responsible: transfer.id_responsavel ? {
            id: transfer.id_responsavel,
            name: transfer.nome_responsavel,
          } : null,
          batchNumber: transfer.numero_lote,
          lotStatus: transfer.status_lote,
          transferredVol: parseInt(transfer.volume),
          processedAt: transfer.data_producao,
          transferredAt: transfer.data
        })),
      records: data.historico
        .map(history => ({
          id: history.id_movimento_item_estoque,
          itemId: history.id_ordem_producao_item,
          byproductId: history.id_ordem_producao_subproduto,
          product: history.produto ? {
            id: history.produto.id_item,
            code: history.produto.cod_item,
            name: history.produto.nome,
          } : {},
          tank: history.tina ? {
            id: history.tina.id_tina,
            name: history.tina.descricao,
          } : {},
          date: history.data_hora_cadastro,
          operation: history.entrada_saida == 1 ? 'ENTRADA' : 'SAIDA',
          quantity: parseFloat(history.quantidade) * (history.entrada_saida == 2 ? -1 : 1),
          measurement: history.unidade,
          price: parseFloat(history.valor),
          person: history.nome_pessoa_registro,
          stock: parseFloat(history.estoque),
          lotNumber: history.numero_lote || history.item_estoque?.numero_lote,
          lotStock: parseFloat(history.estoque_lote),
          lotStatus: history.status_lote,
          manufacturingDate: history.data_fabricacao,
          expirationDate: history.data_validade,
          notes: history.observacao,
        })),
      lots: data.lotes.map(lot => ({
        id: lot.id_estoque,
        lotNumber: lot.numero_lote,
        lotStatus: lot.status_lote,
        quantity: lot.quantidade,
        reservedQuantity: lot.reservado,
        availableQuantity: lot.quantidade - lot.reservado,
        manufacturingDate: lot.data_fabricacao,
        expirationDate: lot.data_validade,
        warehouseName: lot.warehouse?.descricao || 'Geral',
        price: lot.valor,
        product: lot.item ? {
          id: lot.item.id_item,
          name: lot.item.nome,
        } : null,
      })),
      losses: data.perdas || [],
      appointments: data.apontamentos || [],
      attachments: data.anexos || [],
      analysisNotes: data.observacao_laudo,
    };
  },

  async update(id, payload) {
    const { data } = await api.put(`/production-order/${id}`, payload);
    return data;
  },

  async store(payload) {
    const { data } = await api.post('/production-order', payload);
    return data;
  },

  async destroy(id) {
    const { data } = await api.delete(`/production-order/${id}`);
    return data;
  },

  async production(id, payload) {
    const { data } = await api.post(`/production-order/${id}/production`, payload);
    return data;
  },

  async getAppointments(itemId) {
    const { data } = await api.get('/production-order/appointment', { params: { id_item: itemId } });
    return data;
  },

  async storeWriteOff(payload) {
    const { data } = await api.post('/production-order/write-off', payload);
    return data;
  },

  async storeEntry(payload) {
    const { data } = await api.post('/production-order/entry', payload);
    return data;
  },

  async storeAppointment(payload) {
    const { data } = await api.post('/production-order/appointment', payload);
    return data;
  },

  async updateAppointment(id, payload) {
    const { data } = await api.put(`/production-order/appointment/${id}`, payload);
    return data;
  },

  async destroyAppointment(id) {
    const { data } = await api.delete(`/production-order/appointment/${id}`);
    return data;
  },

  async updateAttachments(id, payload) {
    const { data } = await api.put(`/production-order/${id}/attachments`, payload);
    return data;
  },

  async generateLot(id) {
    const { data } = await api.put(`/production-order/${id}/generate-lot`);
    return data;
  },

  async previewLot(params) {
    const { data } = await api.get(`/production-order/preview-lot`, { params });
    return data;
  },

}
