<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="630px"
  >
    <v-card class="order-dialog">
      <v-card-title>
        Entrada de Ordem de Produção nº {{ order.code }}
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="pt-5"
        style="max-height: calc(100vh - 250px);"
      >
        <v-form
          ref="formRef"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col
              cols="12"
              class="pt-0"
            >
              <v-select
                v-model="form.item"
                label="Produto"
                :items="items"
                item-value="id"
                item-text="description"
                return-object
                :disabled="items.length === 1"
                @change="onProductChange"
              />
            </v-col>
            <template v-if="!form.item?.isRawMaterial">
              <v-col
                cols="6"
                class="pt-0"
              >
                <date-text-field
                  v-model="form.manufacturingDate"
                  label="Fabricação"
                  manual
                  :disabled="!form.lotNumber"
                  @input="setExpirationDate()"
                />
              </v-col>
              <v-col
                cols="6"
                class="pt-0"
              >
                <date-text-field
                  v-model="form.expirationDate"
                  label="Validade"
                  manual
                  max=""
                  :disabled="!form.lotNumber"
                  :rules="!!form.lotNumber ? [v => !!v || 'Campo obrigatório!'] : []"
                />
              </v-col>
            </template>
            <v-col
              v-if="hasPlannedQuantityAccess && order.quantity"
              cols="6"
              class="pt-0"
            >
              <masked-text-field
                :value="order.quantity"
                label="Quantidade Planejada"
                :mask="masks.float"
                unmask
                :suffix="form.item?.measurement"
                disabled
              />
            </v-col>
            <v-col
              cols="6"
              class="pt-0 d-flex"
            >
              <v-text-field
                v-model="form.lotNumber"
                label="Nº Lote"
                prepend-inner-icon="subtitles"
                :disabled="!!form.lotFormat || form.item?.isRawMaterial"
                class="flex-grow-1"
                :rules="form.item?.requiresLot ? [v => !!v || 'Campo obrigatório!'] : []"
                @change="setExpirationDate()"
              >
                <template #append>
                  <v-tooltip
                    v-if="!form.lotNumber && order.item?.lotFormat"
                    top
                  >
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        small
                        v-on="on"
                        @click="generateLotNumber()"
                      >
                        <v-icon small>
                          autorenew
                        </v-icon>
                      </v-btn>
                    </template>
                    Gerar Lote
                  </v-tooltip>
                </template>
              </v-text-field>
              <masked-text-field
                v-if="form.lotNumber && !form.item?.isRawMaterial"
                v-model="form.lotNumberSuffix"
                prefix="-"
                label="Variação Lote"
                placeholder="Queijomatic, Tanque, etc."
                maxlength="2"
                :mask="masks.integer"
                unmask
                class="flex-grow-0 ml-2"
                style="max-width: 100px;"
              />
            </v-col>
            <v-col
              cols="6"
              class="pt-0"
            >
              <v-select
                v-if="form.item?.isRawMaterial"
                v-model="form.silo"
                :items="availableSilos"
                label="Silo"
                item-value="id"
                item-text="label"
                placeholder=" "
                return-object
                prepend-inner-icon="panorama_vertical_select"
                :rules="[v => !!v || 'Campo obrigatório!']"
                @change="onSiloSelect"
              >
                <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.label }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span v-if="item.rawProduct.id">
                        {{ item.rawProduct.name }}
                      </span>
                      <v-chip small>
                        {{ item.vol }}L / {{ item.capacity }}L
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
              <warehouse-select
                v-else-if="dialog"
                v-model="form.warehouseId"
                label="Depósito"
                auto-select-default
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              v-if="!form.item?.scaleItem"
              cols="6"
              class="pt-0"
            >
              <masked-text-field
                v-model="form.quantity"
                label="Quantidade"
                :mask="masks.float"
                unmask
                inputmode="numeric"
                :suffix="form.item?.measurement"
                :rules="[
                  v => !!v || 'Campo obrigatório!',
                  validateQuantity()
                ]"
                validate-on-blur
              />
            </v-col>
            <v-col
              v-if="!form.item?.isRawMaterial"
              cols="6"
              class="pt-0"
            >
              <v-select
                v-model="form.lotStatus"
                :items="[
                  { text: 'LIBERADO', value: 'LIBERADO' },
                  { text: 'RESTRITO', value: 'RESTRITO' },
                  { text: 'SUSPENSO', value: 'SUSPENSO' },
                ]"
                label="Status Lote"
              />
            </v-col>
            <v-col
              v-if="order.showQueijomatic && !form.item?.isRawMaterial"
              cols="6"
              class="pt-0"
            >
              <v-select
                v-model="form.tankId"
                :items="tinas"
                label="Queijomatic *"
                item-value="id_tina"
                item-text="descricao"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
          </v-row>

          <scale-weighing
            v-if="form.item?.scaleItem && dialog"
            v-model="form"
            settings-id="PRODUCT-WEIGHING"
            hide-tare
            hide-save
            @save="save"
          >
            <template #append>
              <v-menu
                v-model="settings.dialog"
                :close-on-content-click="false"
                top
                left
                content-class="settings-menu"
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    small
                    @click="openSettings"
                  >
                    <v-icon>settings</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>Configurações</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="settings.computerId"
                          label="ID Computador"
                          outlined
                          small
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="settings.autoWeighing"
                          label="Peso automático"
                          :items="[
                            { text: 'Sim', value: true },
                            { text: 'Não', value: false }
                          ]"
                          outlined
                          small
                          hide-details
                          :disabled="!hasManualWeighingPermission"
                        />
                      </v-col>
                      <v-col
                        v-if="settings.autoWeighing"
                        cols="6"
                      >
                        <masked-text-field
                          v-model="settings.minBoxWeight"
                          label="Peso Bruto Mínimo Caixa"
                          suffix="Kg"
                          :mask="masks.float"
                          unmask
                          inputmode="numeric"
                          outlined
                          small
                          hide-details
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="saveSettings"
                    >
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>
          </scale-weighing>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import { usePermissions } from '@/Support/Composables/permissions.js'

import WarehouseSelect from '@/Domains/Registrations/Item/Components/WarehouseSelect.vue'
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import DateTextField from '@/Support/Components/DateTextField.vue'
import ScaleWeighing from '@/Domains/Shipment/StorageControl/Components/ScaleWeighing.vue'

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js'
import { useRangeValidator } from '@/Support/Composables/validator.js'

import { usePacking } from '@/Domains/Shipment/Composable/Packing.js'

import moment from 'moment'

const { progressBar, notify } = useUtils()

const { hasPermission } = usePermissions()

const { validateRule } = useRangeValidator()

const hasPlannedQuantityAccess = computed(() => hasPermission('ordem-prod-qtde-planejada'))

const { packingSettings, savePackingSettings } = usePacking('PRODUCT-WEIGHING')

// eslint-disable-next-line
const props = defineProps({
  order: {
    type: Object,
    default: () => ({}),
  },
  silos: Array,
  tinas: Array,
})

// eslint-disable-next-line
const emit = defineEmits(['save'])

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
}

const items = computed(() => {
  if (props.order?.coproducts?.length) {
    return [props.order.item, ...props.order.coproducts.map(coproduct => coproduct.item)]
  }

  return [props.order.item]
})

const availableSilos = computed(() => {
  if (!props.form.item?.isRawMaterial) {
    return []
  }

  return props.silos.filter(silo => !silo.rawProduct.id || props.form.item.id === silo.rawProduct.id)
})

const dialog = ref(false)
const formRef = ref()
const form = ref({})

const onProductChange = () => {
  form.value = {
    ...form.value,
    silo: null,
    tankId: null,
    quantity: null,
  }
}

const onSiloSelect = (silo) => {
  form.value = {
    ...form.value,
    lotNumber: silo.lotNumber || null,
    lotFormat: null,
  }
}

const show = (item) => {
  dialog.value = true
  form.value = {
    item: { ...item },
    warehouseId: null,
    silo: null,
    tankId: null,
    lotNumber: props.order.lotNumber,
    lotFormat: props.order.lotFormat,
    manufacturingDate: moment().format('YYYY-MM-DD'),
    quantity: null,
    grossWeight: null,
    expirationDate: null,
  }

  setExpirationDate()
  formRef.value?.resetValidation()
}

const setExpirationDate = () => {
  const { expirationDate } = props.order.item

  if (!expirationDate) {
    return
  }

  if (!form.value.lotNumber || !form.value.manufacturingDate) {
    form.value.expirationDate = null
    return
  }

  form.value.expirationDate = moment(form.value.manufacturingDate).add(expirationDate, 'days').format('YYYY-MM-DD')
}

const close = () => {
  dialog.value = false
}

const save = async () => {
  if (!await formRef.value.validate()) {
    return
  }

  try {
    progressBar.saving()

    const payload = {
      id_item: form.value.item.id,
      id_deposito: form.value.warehouseId,
      id_silo: form.value.silo?.id,
      id_tina: form.value.tankId,
      quantidade: form.value.quantity || form.value.grossWeight,
      numero_lote: form.value.lotNumber,
      numero_lote_sufixo: form.value.lotNumberSuffix,
      status_lote: form.value.lotStatus,
      data_fabricacao: form.value.manufacturingDate,
      data_validade: form.value.expirationDate,
    }

    if (form.value.item.isRawMaterial) {
      payload.id_materia_prima = form.value.item.id
      payload.nome_materia_prima = form.value.item.name || form.value.item.description
    }

    await api.production(props.order.id, payload)

    emit('save')
    notify.success('Produção efetuada com sucesso', 'Sucesso')
  } catch (e) {
    const message = e?.response?.data?.message || 'Erro ao salvar'
    notify.error(message, 'Atenção')
    console.warn(e)
  } finally {
    progressBar.hide()
    dialog.value = false
  }
}

const generateLotNumber = async () => {
  try {
    progressBar.saving()

    const { data } = await api.generateLot(props.order.id)

    props.order.lotNumber = data.numero_lote
    props.order.lotFormat = data.formato_lote
    form.value.lotNumber = data.numero_lote
    form.value.lotFormat = data.formato_lote

    notify.success('Lote gerado com sucesso', 'Sucesso')
  } catch (e) {
    notify.error('Erro ao gerar lote', 'Atenção')
    console.warn(e)
  } finally {
    progressBar.hide()
  }
}

const validateQuantity = () => {
  if (!props.order.tolerance?.max) {
    return true
  }

  const value = form.value.quantity
  const rule = {
    max: props.order.tolerance.max - props.order.accomplishedQuantity,
  }

  return validateRule(value, rule)
}

const hasManualWeighingPermission = computed(() => hasPermission('manual-weighing'))

const settings = reactive({
  dialog: false,
  computerId: null,
  autoWeighing: false,
  minBoxWeight: null,
})

const openSettings = () => {
  settings.dialog = true
  settings.computerId = packingSettings.value.computerId
  settings.autoWeighing = packingSettings.value.autoWeighing || !hasManualWeighingPermission.value
  settings.minBoxWeight = packingSettings.value.minBoxWeight
}

const saveSettings = async () => {
  try {
    progressBar?.saving()

    await savePackingSettings({
      computerId: settings.computerId,
      autoWeighing: settings.autoWeighing,
      minBoxWeight: settings.minBoxWeight,
    })

  } catch (error) {
    console.error(error)
    const message = error?.response?.data?.message || 'Erro ao salvar as configurações'
    notify.error(message, 'Atenção', { timeout: 6000 })
  } finally {
    progressBar?.hide()
    settings.dialog = false
  }
}

// eslint-disable-next-line no-undef
defineExpose({ show })
</script>
