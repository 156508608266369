<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
            class="pt-0"
          >
            <v-date-range-picker
              v-model="filters.range"
              label="Data"
              dark
              @change="load"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            class="pt-0"
          >
            <v-select
              v-model="filters.type"
              label="Tipo"
              :items="[
                { value: 'TODOS', text: 'Todos' },
                { value: 'COM_PALLET', text: 'Com Pallet' },
                { value: 'SEM_PALLET', text: 'Sem Pallet' },
              ]"
              dark
              filled
              hide-details
              clearable
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            md="4"
            class="pt-0"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              prepend-inner-icon="search"
              dark
              filled
              hide-details
              clearable
            />
          </v-col>
        </v-row>
      </v-card-title>

      <data-table
        ref="report"
        :headers="headers"
        :items="filteredItems"
        :search="filters.search"
        dark
        item-key="id"
        mobile-breakpoint="1000"
      >
        <template #[`item.quantidade`]="{ value, item }">
          {{ formatNumber(value) }} {{ item.item?.unidade }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="hasDeletePermission"
                @click="remove(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <PackingDialog
      ref="packingDialog"
      @close="load"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="blue"
            @click="add()"
            v-on="on"
          >
            <v-icon>
              add
            </v-icon>
          </v-btn>
        </template>
        Novo
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel"
            v-on="on"
          >
            <v-icon>
              backup_table
            </v-icon>
          </v-btn>
        </template>
        Download (Excel)
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="print"
            v-on="on"
          >
            <v-icon>
              print
            </v-icon>
          </v-btn>
        </template>
        Imprimir
      </v-tooltip>
    </v-speed-dial>
  </v-container>
</template>

<script>
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue'
import PackingDialog from '@/Domains/Shipment/StorageControl/Components/PackingDialog.vue'

import ReportMixin from '@/Support/Mixins/ReportMixin.js'

import moment from 'moment-timezone'

export default {

  components: {
    VDateRangePicker,
    PackingDialog,
  },

  mixins: [ReportMixin],

  data() {
    return {
      filters: {
        range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        type: '',
        search: ''
      },

      headers: [
        { text: 'Produto', value: 'produto' },
        { text: 'Lote', value: 'estoque.numero_lote' },
        { text: 'Quantidade', value: 'quantidade' },
        { text: 'Pallet', value: 'codigo_pallet', align: 'center' },
        { text: 'Peso Bruto', value: 'peso_bruto', formatter: value => this.formatNumber(value) + ' Kg', mask: '#,##0.00' },
        { text: 'Peso Tara', value: 'peso_tara', formatter: value => this.formatNumber(value) + ' Kg', mask: '#,##0.00' },
        { text: 'Peso Líquido', value: 'peso_liquido', formatter: value => this.formatNumber(value) + ' Kg', mask: '#,##0.00' },
        { text: 'Data', value: 'data_hora_cadastro', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: 'Código', value: 'codigo', align: 'center' },
        { text: 'Código de Barras', value: 'codigo_barras', align: 'center' },
        { text: 'SSCC', value: 'sscc', align: 'center' },
        { text: '', altText: 'Opções', value: 'actions', align: 'end', width: 30, sortable: false, drag: false }
      ],

      items: [],
    }
  },

  computed: {
    /**
     * Exibe as ordens com base nos filtros selecionados em tela
     */
    filteredItems() {
      const type = this.filters.type === 'TODOS' ? null : this.filters.type
      if (!this.filters.search && !type) {
        return this.items
      }

      const search = this.filters.search?.toUpperCase()?.trim()

      return this.items.filter(item => {
        const matchSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search)
        const matchType = !type || (type === 'COM_PALLET' && item.codigo_pallet) || (type === 'SEM_PALLET' && !item.codigo_pallet)

        return matchSearch && matchType
      });
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasDeletePermission() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'packing-delete' && o.tipo === 'COMPONENTE');
    },
  },

  methods: {
    async load() {
      try {
        this.$root.$progressBar.loading();

        const [startDate, endDate] = this.filters.range;

        const { data } = await this.$axios.get(`packing`, { params: {
          start_date: startDate,
          end_date: endDate,
        } });

        this.items = data.map(item => {
          item.produto = item.item.nome;

          if (item.unidade_medida) {
            item.produto += ` (${item.unidade_medida.descricao})`;
          }

          item.peso_tara = (item.peso_tara_1 || 0) + (item.peso_tara_2 || 0);
          item.peso_liquido = item.peso_bruto - item.peso_tara;

          return item
        });

      } catch (error) {
        const message = error?.response?.data?.message || 'Erro ao carregar as embalagens';
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    add() {
      this.$refs.packingDialog.show();
    },

    async remove(packing) {
      if (!(await this.$root.$confirm(
        `Remover embalagem?`,
        `Esta ação não pode ser desfeita.`,
        { color: 'red' }
      ))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`packing/${packing.id}`);

        const index = this.items.findIndex(item => item.id === packing.id);
        this.items.splice(index, 1);

        this.$snotify.success('Embalagem removida com sucesso');
      } catch (error) {
        const message = error?.response?.data?.message || 'Erro ao remover a embalagem';
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.range;
      return `Embalagens - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: val => !val ? 0 : new Intl.NumberFormat('pt-BR').format(val),
  }
}
</script>
