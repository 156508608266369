<template>
  <div
    class="mx-6 px-6"
  >
    <v-row
      justify="end"
    >
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filters.referenceDate"
          :items="['DESCARGA', 'COLETA']"
          label="Data de referência"
          prepend-inner-icon="local_shipping"
          dark
          filled
          hide-details
          @change="onFilter"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-date-range-picker
          v-model="filters.dateRange"
          dark
          hide-details
          @change="onFilter"
        />
      </v-col>
      <v-col
        v-if="hasSecondaryRawMaterial"
        cols="12"
        md="3"
      >
        <v-select
          v-model="rawMaterial"
          :items="rawMaterialItems"
          label="Matéria Prima"
          prepend-inner-icon="info"
          dark
          filled
          hide-details
          clearable
          :style="dynamicFilterWidth(true, '200px', '450px')"
          @change="onFilter"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="mx-0 px-0"
      >
        <v-card
          dark
          :style="{ background: 'rgba(0, 0, 0, 0.5)' }"
        >
          <v-card-text>
            <v-chart
              :options="options"
              :style="{ width: '100%', height: `400px !important` }"
              autoresize
            />
            <div class="text-caption text-left ml-4 mt-3">
              Total
              <v-chip
                small
                class="mx-2 mb-0"
              >
                {{ formatNumber(getTotalVolume) }}
              </v-chip>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay
      v-model="loading"
      dark
    >
      Carregando
      <v-progress-circular
        indeterminate
        size="20"
      />
    </v-overlay>
  </div>
</template>

<script>
import isArray from "lodash/fp/isArray";
import moment from "moment-timezone";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import XLSX from "xlsx-js-style";
import FilterRawMaterialMixin from "@/Support/Mixins/FilterRawMaterialMixin.js";

export default {
  components: {
    VDateRangePicker,
  },

  mixins: [FilterRawMaterialMixin],

  data() {
    return {
      loading: false,
      filters: {
        dateRange: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
        referenceDate: 'DESCARGA',
      },
      report: [],
    };
  },

  computed: {

    getTotalVolume() {
      return this.report.reduce((acc, item) => (acc + parseFloat(item.total)), 0)
    },

    options() {
      const nomesRotas = [...new Set(this.report.map(item => item.nome_rota))];
      const colors = ["#619CB4", "#FF7F50"];
      const seriesData = {};

      this.report.forEach((item) => {
        if (!seriesData[item.nome_materia_prima]) {
          seriesData[item.nome_materia_prima] = {
            name: item.nome_materia_prima,
            data: [],
            type: "bar",
            itemStyle: {
              color: colors[Object.keys(seriesData).length % colors.length],
            },
            label: {
              show: true,
              position: 'inside',
              align: 'center',
              verticalAlign: 'middle',
              color: "#fff",
              fontSize: "13",
              formatter: (params) => this.formatNumber(params.value),
              fontWeight: "bold",
            },
          };
        }
        const index = nomesRotas.indexOf(`${item.nome_rota}`);
        seriesData[item.nome_materia_prima].data[index] = parseFloat(item.total);
      });

      const series = Object.values(seriesData);

      return {
        color: colors,
        xAxis: {
          type: "category",
          data: nomesRotas,
          axisLabel: {
            color: "rgba(255, 255, 255, 0.8)",
            rotate: 45,
            interval: 0,
          },
          axisTick: {
            alignWithLabel: true,
          },
          boundaryGap: true,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            margin: 0,
            color: "rgba(255, 255, 255, 0.8)",
            formatter: (value) => this.formatNumber(value),
          },
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            label: {
              backgroundColor: "#6a7985",
            },
          },
          formatter: (params) => {
            const { name, value } = params;
            return `${name}: ${this.formatNumber(value)}`;
          },
        },
        grid: {
          left: '5%',
          right: '5%',
          top: '50px',
          bottom: '15%',
          containLabel: true,
        },
        legend: {
          data: Object.keys(seriesData),
          textStyle: {
            color: "#fff",
          },
          top: '10px',
        },
        series,
        barCategoryGap: '30%',
        barGap: '10%',
        barWidth: '40px',
      };
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(value) {
        const filterId = this.$options._componentTag;
        this.$store.commit("setFilter", { [filterId]: value });
      },
    },
  },

  created() {
    this.getFiltersFromStore();
  },

  mounted() {
    this.onFilter();
  },

  methods: {
    getFiltersFromStore() {
      const filterId = this.$options._componentTag;
      if (filterId in this.$store.state.settings.filters) {
        this.filters = this.$store.state.settings.filters[filterId];
      }
    },

    async loadReport() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filters.dateRange;

        let { data } = await this.$axios.post(
          `/relatorios/volumeRotas`,
          {
            data_inicio: startDate,
            data_fim: endDate,
            data_referencia: this.filters.referenceDate,
            materiaPrima: this.rawMaterial || null,
          }
        );

        if (!isArray(data)) {
          throw new Error(data);
        }

        this.report = data;
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    exportExcel() {
      let records = this.report.map(rel => {
        const cols = [
          {
            key: "Rota",
            value: rel.nome_rota,
          },
          {
            key: "Volume",
            value: this.formatNumber(parseFloat(rel.total)),
          },
        ];

        const mapped = cols.map(item => ({ [item.key]: item.value }));

        return Object.assign({}, ...mapped);
      });

      const data = XLSX.utils.json_to_sheet(records);

      data['!cols'] = [
        { wch: 40 },
        { wch: 15 },
      ];

      XLSX.utils.sheet_add_aoa(data, [
        ["", ""],
        ["Total", this.formatNumber(this.getTotalVolume)],
      ], {origin:-1});

      const workbook = XLSX.utils.book_new();
      const filename = `volume_geral_por_mes`;

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    onFilter() {
      this.loadReport();
    },
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>
