<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{ background: color, height: '100%' }"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        monetization_on
      </v-icon>
      <span class="title font-weight-light">Faturamento Mensal por Faixa de Valor</span>
    </v-card-title>

    <v-tabs
      v-model="tab"
      align-with-title
      background-color="transparent"
      class="transparent flex-grow-0"
      dark
    >
      <v-tabs-slider color="cyan" />
      <v-tab>Gráfico</v-tab>
      <v-tab>Relatório</v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="flex-grow-1 tabs-items"
      :style="{ height: '100%', overflow: 'visible' }"
    >
      <v-tab-item>
        <v-chart
          :options="chartOptions"
          :style="{ width: '100%', height: '100% !important' }"
          autoresize
        />
      </v-tab-item>

      <v-tab-item>
        <v-data-table
          :headers="headers"
          :items="producerData"
          dark
          dense
          class="elevation-1 report-table"
          hide-default-footer
          disable-pagination
        >
          <template #[`item.total_valor`]="{ value }">
            {{ formatCurrency(value) }}
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
export default {
  props: {
    referenceMonth: {
      type: String,
      required: true, // A data de referência precisa ser passada corretamente
    },
  },

  data() {
    return {
      loading: false,
      tab: 0, // Inicializa a aba padrão (0 = Gráfico)
      color: 'rgba(0, 0, 0, 0.4)', // Cor do background
      height: '500px', // Altura padrão do card
      producerData: [], // Inicializando producerData como um array vazio para evitar erros
      headers: [
        { text: 'Nome do Produtor', value: 'nome' },
        { text: 'Perfil de Faturamento', value: 'perfil_faturamento', align: 'center' },
        { text: 'Total Movimentado (R$)', value: 'total_valor', align: 'right',  },
      ],
      chartOptions: {}, // Inicializa o options como um objeto vazio
    };
  },

  watch: {
    // Observa mudanças na prop 'referenceMonth' e recarrega os dados
    referenceMonth(newMonth, oldMonth) {
      if (newMonth !== oldMonth) {
        this.loadData(newMonth); // Recarrega os dados quando a data mudar
      }
    },
  },

  mounted() {
    // Carrega os dados ao montar o componente pela primeira vez
    this.loadData(this.referenceMonth);
  },

  methods: {
    loadData(date) {
      this.loading = true;
      // Requisição para buscar os dados com base no novo mês de referência
      this.$axios
        .post('/tabelaPreco/movimentacaoMensal', { mes_referencia: date })
        .then((response) => {
          this.producerData = response.data || []; // Garante que os dados serão um array

          // Ordena os dados do maior para o menor valor de total_valor
          this.producerData.sort((a, b) => b.total_valor - a.total_valor);

          this.updateChartOptions(); // Atualiza as opções do gráfico com os novos dados
        })
        .catch((error) => {
          console.error('Erro ao carregar os dados:', error);
        })
        .finally(() => {
          this.loading = false; // Finaliza o estado de loading após carregar os dados
        });
    },

    updateChartOptions() {
      // Verifique se os dados foram carregados corretamente
      if (!this.producerData.length) {
        return;
      }

      // Agrupa os dados por faixa de faturamento
      const groupedData = this.producerData.reduce((acc, item) => {
        const faixa = item.perfil_faturamento;
        const valor = item.total_valor ? parseFloat(item.total_valor) : 0; // Garante que os valores null sejam considerados como 0
        if (!acc[faixa]) {
          acc[faixa] = 0;
        }
        acc[faixa] += valor; // Soma o valor para a faixa correspondente
        return acc;
      }, {});

      // Verifica se todas as faixas de faturamento estão presentes, mesmo que sem dados
      const faixasPredefinidas = [
        '≤ R$ 20 mil',
        'R$ 20-50 mil',
        'R$ 50-100 mil',
        'R$ 100-200 mil',
        '≥ R$ 200 mil',
      ];

      const finalData = faixasPredefinidas.map((faixa) => {
        const valor = groupedData[faixa] || 0;
        return isNaN(valor) ? 0 : valor; // Garante que não haja valores NaN
      });

      // Atualiza as opções do gráfico com os dados agrupados
      this.chartOptions = {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(255, 255, 0, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' },
          },
          formatter: (params) => {
            return [
              params[0].name,
              ...params.map((param) => {
                const formatter = this.formatCurrency(param.value);
                return `${param.marker} ${param.seriesName}: <b>${formatter}</b>`;
              }),
            ].join('<br>');
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: faixasPredefinidas, // Usa faixas predefinidas como categorias
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: 'rgba(255, 255, 255, 0.8)',
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)',
            formatter: (value) => this.formatCurrency(value),
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)'],
            },
          },
        },
        series: [
          {
            name: 'Total Movimentado',
            type: 'bar',
            data: finalData, // Usando os totais de cada faixa
          },
        ],
      };
    },

    formatCurrency(value) {
      return (
        'R$ ' +
        new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value)
      );
    },
  },
};
</script>

<style lang="scss">
.tabs-items {
  .v-window__container,
  .v-window-item {
    height: 100%;
  }

  > div {
    overflow-y: visible;

    .v-data-table {
      flex-grow: 1 !important;

      .v-data-table__wrapper {
        height: 100%;
        background: rgba(255, 255, 255, 0.08);
      }
    }
  }
}
</style>
