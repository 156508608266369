export default {
  data() {
    return {
      hasSecondaryRawMaterial: false,

      primaryRawMaterialId: this.$store.state.settings.gerais.id_materia_prima_principal,
      primaryRawMaterialName: this.$store.state.settings.gerais.nome_materia_prima_principal,

      secondaryRawMaterialId: this.$store.state.settings.gerais.id_materia_prima_secundaria,
      secondaryRawMaterialName: this.$store.state.settings.gerais.nome_materia_prima_secundaria,

      rawMaterialItems: [],
      rawMaterial: null,

      headerRawMaterial: { text: 'Matéria Prima', value: 'nome_materia_prima', align: 'center' },
    };
  },

  mounted() {
    this.checkSecondaryRawMaterial();
    this.setItems();
  },

  methods: {
    setItems() {
      this.rawMaterialItems.push(
        {
          value: this.primaryRawMaterialId,
          text: this.primaryRawMaterialName,
        },
        {
          value: this.secondaryRawMaterialId,
          text: this.secondaryRawMaterialName,
        }
      )
    },
    checkSecondaryRawMaterial() {
      if (this.secondaryRawMaterialId !== null) {
        this.hasSecondaryRawMaterial = true;
      }
    },

    addRawMaterialToGrid(header, newColumn) {
      if (this.hasSecondaryRawMaterial && Array.isArray(this[header])) {
        this[header].push(newColumn);
      }
    },

    dynamicFilterWidth(setMaxWidth = true, minWidth = '200px', maxWidth = '350px') {
      if (!setMaxWidth) {
        return `min-width: ${minWidth};`;
      }

      return `min-width: ${minWidth}; max-width: ${maxWidth};`;
    },
  },
};
