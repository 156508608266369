<template>
  <div class="mx-6">
    <v-card
      color="transparent"
      dark
    >
      <v-card-title class="pb-0">
        <v-row>
          <v-col>
            <v-date-range-picker
              v-model="filters.date"
              dark
              :style="dynamicFilterWidth(true, '200px', '850px')"
              @change="onDateFilter"
            />
          </v-col>
          <v-col v-if="hasSecondaryRawMaterial">
            <v-select
              v-model="rawMaterial"
              :items="rawMaterialItems"
              label="Matéria Prima"
              prepend-inner-icon="info"
              dark
              filled
              hide-details
              clearable
              :style="dynamicFilterWidth(true, '200px', '850px')"
              @change="onDateFilter"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              prepend-inner-icon="search"
              single-line
              hide-details
              clearable
              filled
              :style="dynamicFilterWidth(true, '200px', '850px')"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="filters.search"
      >
        <template #[`item.volume`]="{value}">
          <span>{{ formatNumber(value) }} L</span>
        </template>
      </v-data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>

          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import FilterRawMaterialMixin from "@/Support/Mixins/FilterRawMaterialMixin.js";
import moment from "moment-timezone";
import XLSX from "xlsx-js-style";

export default {

  components: {
    VDateRangePicker,
  },

  mixins: [ReportMixin, FilterRawMaterialMixin],

  data() {
    return {
      loading: false,

      filters: {
        date: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        search: ''
      },

      headers: [
        { text: "Placa", value: "placa" },
        { text: "Volume (L)", value: "volume", align: 'end' },
      ],

      items: [],
    };
  },

  mounted() {
    this.loadReport();
    this.addRawMaterialToGrid("headers", this.headerRawMaterial);
  },

  methods: {
    onDateFilter() {
      this.loadReport();
    },

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.filters.date;

        let { data } = await this.$axios.post(
          `/relatorios/volumePlaca`,
          {
            data_inicio: startDate,
            data_fim: endDate,
            materiaPrima: this.rawMaterial || null,
          }
        );

        this.items = data;

      } catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de frota", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    exportExcel() {
      const reportData = this.items.map( item => {
        let report = {
          "Placa": item.placa,
          "Volume": `${item.volume} L`
        }

        if (this.hasSecondaryRawMaterial) {
          report['Matéria Prima'] = item.nome_materia_prima;
        }

        return report;
      });

      let data = XLSX.utils.json_to_sheet(reportData);
      const workbook = XLSX.utils.book_new();
      const filename = `Volume por Placa`;
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>