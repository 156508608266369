<template>
  <div
    class="mx-6 px-6"
  >
    <v-row class="pa-0 ma-0">
      <v-col
        cols="4"
        md="2"
      >
        <p class="white--text">
          Volume
        </p>
        <v-card
          class="report-card"
          color="transparent"
          dark
          flat
        >
          <v-card-title class="px-0 py-1 d-flex justify-center body-2 white--text font-weight-bold ">
            {{ Math.abs(volumeTotal) }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        cols="4"
        md="2"
      >
        <p class="white--text">
          Coletas
        </p>
        <v-card
          class="report-card"
          color="transparent"
          dark
          flat
        >
          <v-card-title class="px-0 py-1 d-flex justify-center body-2 white--text font-weight-bold ">
            {{ Math.abs(coletasTotal) }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        cols="4"
        md="2"
      >
        <p class="white--text">
          Produtores
        </p>
        <v-card
          class="report-card"
          color="transparent"
          dark
          flat
        >
          <v-card-title class="px-0 py-1 d-flex justify-center body-2 white--text font-weight-bold ">
            {{ Math.abs(totalProdutores) }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col>
        <v-select
          v-model="filters.referenceDate"
          :items="['DESCARGA', 'COLETA']"
          label="Data de referência"
          prepend-inner-icon="local_shipping"
          dark
          filled
          hide-details
          :style="dynamicFilterWidth(true, '200px', '450px')"
          @change="onFilter"
        />
      </v-col>
      <v-col>
        <v-date-range-picker
          v-model="filters.dateRange"
          dark
          hide-details
          :style="dynamicFilterWidth(true, '200px', '450px')"
          @change="onFilter"
        />
      </v-col>
      <v-col v-if="hasSecondaryRawMaterial">
        <v-select
          v-model="rawMaterial"
          :items="rawMaterialItems"
          label="Matéria Prima"
          prepend-inner-icon="info"
          dark
          filled
          hide-details
          clearable
          :style="dynamicFilterWidth(true, '200px', '450px')"
          @change="onFilter"
        />
      </v-col>
    </v-row>

    <v-card
      dark
      :style="{ background: 'rgba(0, 0, 0, 0.5)' }"
    >
      <v-card-text>
        <v-chart
          :options="options"
          :style="{ width: '100%', height: `400px !important` }"
          autoresize
        />
      </v-card-text>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay
      v-model="loading"
      dark
    >
      Carregando
      <v-progress-circular
        indeterminate
        size="20"
      />
    </v-overlay>
  </div>
</template>

<style lang="scss">
.chart-card {
  background: rgba(0, 0, 0, 0.5);
  overflow: none;
}
</style>

<script>
import isArray from "lodash/fp/isArray";
import XLSX from "xlsx-js-style";
import moment from "moment-timezone";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import FilterRawMaterialMixin from "@/Support/Mixins/FilterRawMaterialMixin.js";

export default {
  components: {
    VDateRangePicker,
  },

  mixins: [FilterRawMaterialMixin],

  data() {
    return {
      loading: false,
      filters: {
        dateRange: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
        search: null,
        referenceDate: 'DESCARGA',
      },
      report: [],
      general: [],
      volumeRawMaterial: {},
    };
  },

  computed: {
    options() {
      const uniqueDates = [...new Set(this.general.map(item => moment(item.data).format("DD")))];

      const groupedData = this.general.reduce((acc, item) => {
        const { nome_materia_prima, data, volume } = item;
        const formattedDate = moment(data).format("DD");

        if (!acc[nome_materia_prima]) {
          acc[nome_materia_prima] = {};
        }
        acc[nome_materia_prima][formattedDate] = volume;
        return acc;
      }, {});

      const colors = ["#619CB4", "#FF7F50"];
      const areaColors = ["rgba(97, 156, 180, 0.8)", "rgba(255, 127, 80, 0.8)"];

      const series = Object.keys(groupedData).map((key, index) => {
        const group = groupedData[key];

        let zIndex = this.volumeRawMaterial[key] !== undefined ? this.volumeRawMaterial[key] : 0;

        return {
          name: key,
          data: uniqueDates.map(date => group[date] || null),
          type: "line",
          smooth: true,
          areaStyle: {
            color: areaColors[index % areaColors.length],
          },
          itemStyle: {
            color: colors[index % colors.length],
          },
          lineStyle: {
            color: colors[index % colors.length],
            width: 2,
          },
          z: zIndex,
          label: {
            show: true,
            lineHeight: 20,
            height: 20,
            backgroundColor: '#6a7985',
            color: '#fff',
            borderRadius: 5,
            position: 'top',
            distance: 2,
            formatter: function(f) {
              return parseFloat(f.data);
            },
          },
        };
      });

      return {
        color: colors,
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: uniqueDates,
          axisLabel: {
            color: "rgba(255, 255, 255, 0.8)",
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            margin: 0,
            color: "rgba(255, 255, 255, 0.8)",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          left: '1%',
          right: '5%',
          top: '50px',
          bottom: '0%',
          containLabel: true,
        },
        series,
        toolbox: {
          right: "10",
          left: "10",
          iconStyle: {
            borderColor: "#fff"
          },
          feature: {
            saveAsImage: {
              title: "Volume geral por ciclo",
            },
          },
        },
      };
    },
    volumeTotal() {
      return Math.abs(this.report.reduce((acc, item) => (acc + parseInt(item.total_volume)), 0));
    },

    coletasTotal() {
      return Math.abs(this.report.reduce((acc, item) => (acc + parseInt(item.qtde_coletas)), 0));
    },

    totalProdutores() {
      return this.report.length;
    }
  },

  watch: {
    filters: {
      deep: true,
      handler(value) {
        const filterId = this.$options._componentTag;
        this.$store.commit("setFilter", { [filterId]: value });
      },
    },
  },

  created() {
    this.getFiltersFromStore();
  },

  mounted() {
    this.onFilter();
  },

  methods: {
    getFiltersFromStore() {
      const filterId = this.$options._componentTag;
      if (filterId in this.$store.state.settings.filters) {
        this.filters = this.$store.state.settings.filters[filterId];
      }
    },

    async loadReport() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filters.dateRange;

        const [{ data }, { data: general }] = await Promise.all([
          this.$axios.post(
            `/coleta/volumeColeta`,
            {
              data_inicio: startDate,
              data_fim: endDate,
              data_referencia: this.filters.referenceDate,
              materiaPrima: this.rawMaterial || null,
            }
          ),
          this.$axios.post(
            `/coleta/volumeColetaGeral`,
            {
              data_inicio: startDate,
              data_fim: endDate,
              data_referencia: this.filters.referenceDate,
              materiaPrima: this.rawMaterial || null,
            }
          ),
        ]);

        if (!isArray(data)) {
          throw new Error(data);
        }

        if (!isArray(general)) {
          throw new Error(general);
        }

        this.report = data;
        this.getVolumeRawMaterial();

        this.general = general.sort((a, b) => moment(a.data).diff(moment(b.data)));
        this.groupByRawMaterial();
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    onFilter() {
      this.loadReport();
    },

    exportExcel() {
      let records = this.general.map(rel => {
        const cols = [
          {
            key: "Dia",
            value: moment(rel.data).format("DD/MM/YYYY"),
          },
          {
            key: "Volume",
            value: parseFloat(rel.volume).toFixed(2),
          },
        ];

        const mapped = cols.map(item => ({ [item.key]: item.value }));

        return Object.assign({}, ...mapped);
      });

      const data = XLSX.utils.json_to_sheet(records);
      const workbook = XLSX.utils.book_new();
      const filename = `volume_geral_por_ciclo`;

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    groupByRawMaterial() {
      return this.general.reduce((acc, item) => {
        const { nome_materia_prima, data, volume } = item;
        if (!acc[nome_materia_prima]) {
          acc[nome_materia_prima] = [];
        }
        acc[nome_materia_prima].push({ data, volume });
        return acc;
      }, {});
    },

    getVolumeRawMaterial() {
      const totalVolumesPorMateriaPrima = this.report.reduce((acc, item) => {
        const nomeMateriaPrima = item.nome_materia_prima;

        if (!acc[nomeMateriaPrima]) {
          acc[nomeMateriaPrima] = 0;
        }

        acc[nomeMateriaPrima] += parseInt(item.total_volume);

        return acc;
      }, {});

      const volumesArray = Object.entries(totalVolumesPorMateriaPrima);

      volumesArray.sort((a, b) => a[1] - b[1]);

      volumesArray.forEach(([nomeMateriaPrima], index) => {
        const zIndex = volumesArray.length - index;
        this.volumeRawMaterial[nomeMateriaPrima] = zIndex;
      });
    },
  },
};
</script>
