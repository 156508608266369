<template>
  <div class="mx-6 px-16 pt-0 mb-7">
    <v-row justify="center">
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Entrada Diária
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-card class="transparent">
      <v-card-title class="pb-0">
        <v-row>
          <v-col>
            <v-date-range-picker
              v-model="filters.date.range"
              :ranges="filters.date.ranges"
              prepend-inner-icon="today"
              dark
              label="Data"
              hide-details
              filled
              :style="dynamicFilterWidth()"
              @change="onFilter"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="filters.referenceDate"
              :items="['DESCARGA', 'COLETA']"
              label="Data de referência"
              prepend-inner-icon="local_shipping"
              dark
              filled
              hide-details
              :style="dynamicFilterWidth()"
              @change="onFilter"
            />
          </v-col>
          <v-col>
            <routes-autocomplete-filter
              v-model="filters.route"
              label="Rota"
              dark
              :style="dynamicFilterWidth()"
              @change="onFilter"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="filters.region"
              :items="regions"
              item-text="regiao"
              item-value="id_regiao"
              prepend-inner-icon="map"
              label="Região"
              filled
              clearable
              dark
              hide-details
              :style="dynamicFilterWidth()"
              @change="onFilter"
            />
          </v-col>
          <v-col v-if="hasSecondaryRawMaterial">
            <v-select
              v-model="rawMaterial"
              :items="rawMaterialItems"
              label="Matéria Prima"
              prepend-inner-icon="info"
              dark
              filled
              hide-details
              clearable
              :style="dynamicFilterWidth()"
              @change="onFilter"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="filters.search"
              prepend-inner-icon="search"
              label="Busca"
              filled
              single-line
              hide-details
              :style="dynamicFilterWidth()"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <data-table
        ref="report"
        :headers="headers"
        :items="filteredItems"
        group-by="nome_rota"
        :sort-by.sync="filters.sort.by"
        :sort-desc.sync="filters.sort.desc"
        light
        dark
      >
        <template #[`group.header`]="{group, isOpen, toggle}">
          <td
            class="text-start"
            :colspan="headers.length"
          >
            <v-btn
              icon
              @click="toggle"
            >
              <v-icon>
                {{ isOpen ? 'remove' : 'add' }}
              </v-icon>
            </v-btn>
            {{ group }}
          </td>
        </template>

        <template #[`item.volume_total`]="{ item }">
          <v-chip color="teal darken-1">
            {{ item.volume_total }}
          </v-chip>
        </template>

        <template #[`body.append`]="{ headers }">
          <tr class="tr-tot-day">
            <td
              v-for="(header,index) in headers"
              :key="index"
            >
              <v-chip
                v-if="!['data-table-expand', 'codigo_produtor','nome_produtor', 'volume_total', 'cnpj_cpf', 'inscricao_estadual', 'nome_materia_prima'].includes(header.value)"
                color="blue darken-4"
                label
              >
                {{ totalsByDay[header.value] }}
              </v-chip>
              <v-chip
                v-else-if="header.value ==='volume_total'"
                color="blue darken-4"
                label
              >
                {{ totalVol }}
              </v-chip>
              <span v-else-if="header.value ==='codigo_produtor'">Total</span>
              <span v-else> {{ totalsByDay[header.value] }}</span>
            </td>
          </tr>
        </template>
      </data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<style lang="scss">
  .tr-tot-day{
    span{
      font-family: Roboto, Arial, sans-serif !important;
      font-size: 1rem !important;
    }
  }
</style>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import { sortItems } from 'vuetify/lib/util/helpers'
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import FilterRawMaterialMixin from "@/Support/Mixins/FilterRawMaterialMixin.js";

export default {
  components: {
    RoutesAutocompleteFilter,
    VDateRangePicker,
  },

  mixins: [ReportMixin, FilterRawMaterialMixin],

  data() {
    return {
      loading: false,

      filters: {
        search: '',
        route: null,
        referenceDate: 'DESCARGA',
        date: {
          ranges: {
            'Este mês': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
            'Mês anterior': [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')],
          },
          range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        },
        region: null,
        sort: { by: [], desc: [] }
      },

      regions: [],

      items: [],
    };
  },
  computed: {
    thisMonth() {
      const firstMonthDay = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      const lastMonthDay = moment().format("YYYY-MM-DD");

      return [firstMonthDay, lastMonthDay];
    },

    headers() {
      let headers = [
        { text: 'Rota', value: 'nome_rota' },
        { text: "Código", value: "codigo_produtor", width: 100, align: "center" },
        { text: "Produtor", value: "nome_produtor", width: 250 },
        { text: "CNPJ/CPF", value: "cnpj_cpf", width: 100,  align: "center" },
        { text: "IE", value: "inscricao_estadual", width: 100,  align: "center" },
        { text: "Volume", value: "volume_total", width: 120, align: "center" },
      ];

      if (this.hasSecondaryRawMaterial) {
        headers.push(this.headerRawMaterial);
      }

      headers.push(...this.dateHeaders);

      return headers;
    },

    dateHeaders() {
      const [startDate, endDate] = this.filters.date.range;

      let header = [];
      let currentDate = moment(startDate);
      while (currentDate <= moment(endDate)) {
        header.push({
          text: currentDate.format('DD/MM'),
          value: currentDate.format('YYYY-MM-DD'),
          width: 110,
          align: "center",
        });
        currentDate = moment(currentDate).add(1, 'days');
      }

      return header;
    },

    totalsByDay() {
      return this.dateHeaders.reduce((acc, header) => ({
        ...acc,
        [header.value]: _.sumBy(this.filteredItems, values => _.get(values, header.value, '-') === '-' ? 0 : parseFloat(_.get(values, header.value)))
      }), {});
    },

    totalVol() {
      return this.filteredItems.reduce((acc, item) => (acc + item.volume_total), 0)
    },

    filteredItems() {
      if (!this.filters.search) {
        return this.items;
      }

      const search = this.filters.search.toUpperCase().trim();

      return this.items.filter(item => {
        const searchFilter = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return searchFilter
      });
    },
  },

  mounted() {
    this.onFilter();
    this.loadRegion();
  },

  methods: {
    async loadDailyCollection() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filters.date.range;

        const { data } = await this.$axios.post(
          `/relatorios/volumeColetaDiario`,
          {
            data_inicio: startDate,
            data_fim: endDate,
            id_rota: this.filters.route ? this.filters.route.id : null,
            data_referencia: this.filters.referenceDate,
            id_regiao: this.filters.region,
            materiaPrima: this.rawMaterial || null,
          }
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        this.items = this.handleData(data);
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadRegion() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/relatorios/listaRegiao`);

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.regions = data;

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onFilter() {
      return this.loadDailyCollection();
    },

    handleData(response) {
      //Agrupa por produtor
      const group = _.groupBy(response, "id_produtor");

      //Preenche com todo o range date
      const fillDate = this.dateHeaders.map(({ value }) => ({ data: value }));

      //Preenche os valores correspondente a coleta de cada dia
      return _.map(group, item => {
        let volume_total = 0;

        const concat = _.concat(item, fillDate);

        item = _.uniqBy(concat, "data");

        const accumulator = _.reduce(
          item,
          function(result, value) {
            const { data, quantidade_coleta, rejeitada } = value;

            return {
              ...result,
              ...value,
              [data]: quantidade_coleta !== undefined ? quantidade_coleta : rejeitada == true ? "0" : "-",

            };
          },
          {}
        );

        item.forEach(e => {
          if (e.quantidade_coleta > 0 && e.quantidade_coleta != null) {
            volume_total += e.quantidade_coleta;
          }
        });

        _.unset(accumulator, "quantidade_coleta");
        _.set(accumulator, "volume_total");
        _.unset(accumulator, "data");

        accumulator.volume_total = volume_total;
        return accumulator;
      });
    },

    orderedItems() {
      return sortItems(this.filteredItems, this.filters.sort.by, this.filters.sort.desc, 'pt-BR');
    },

    exportExcel() {
      let data = this.XLSX.utils.json_to_sheet([]);

      let header = ["Código", "Produtor", "Volume", "Região", "CNPJ/CPF", "IE"];

      if (this.hasSecondaryRawMaterial) {
        header.push("Matéria Prima");
      }

      header.push(...this.dateHeaders.map(header => header.text));

      const routes = _.groupBy(
        _.orderBy(this.orderedItems(), "nome_rota"),
        "nome_rota"
      );

      let currentRow = 1;
      new Map(Object.entries(routes))
        .forEach((items, route) => {

          const json = items.map(item => {
            let reportItem = {
              "Código": item.codigo_produtor,
              "Produtor": item.nome_produtor,
              "Volume": item.volume_total,
              "Região": item.regiao,
              "CNPJ/CPF": item.cnpj_cpf,
              "IE": item.inscricao_estadual,
            };

            if (this.hasSecondaryRawMaterial) {
              reportItem["Matéria Prima"] = item.nome_materia_prima;
            }

            const dateHeadersData = this.dateHeaders.reduce((acc, header) => ({
              ...acc, [header.text]: item[header.value]
            }), {});

            reportItem = { ...reportItem, ...dateHeadersData };

            return reportItem;
          });

          data[`A${currentRow}`] = { v: route };

          this.XLSX.utils.sheet_add_json(data, json, {
            origin: currentRow,
            header,
          });

          currentRow += items.length + 3;
        });

      const totals = [{
        'Código': 'Total',
        'Produtor': '',
        'Volume': this.totalVol,
        ...this.dateHeaders
          .reduce((acc, header) => ({
            ...acc, [header.text]: this.totalsByDay[header.value]
          }), {})
      }];

      this.XLSX.utils.sheet_add_json(data, totals, { origin: currentRow, header, skipHeader: true });

      data['!cols'] = [
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
      ];

      const workbook = this.XLSX.utils.book_new();
      const [startDate, endDate] = this.filters.date.range;
      const filename = `Entrada Diária - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
      this.XLSX.utils.book_append_sheet(workbook, data, _.truncate(filename, { length: 31 }));
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    async print() {
      const [startDate, endDate] = this.filters.date.range;
      const title = `Entrada Diária - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      if (this.filteredItems.length === 0) {
        return;
      }

      let body = [];

      const headers = this.headers
        .filter(header => header.value !== 'nome_rota')
        .map(header => ({
          key: header.value,
          label: header.text,
          class: 'text-left',
          attributes: {
            style: header.width ? `width: ${header.width}px;` : ''
          }
        }));

      const totals = [{
        'codigo_produtor': 'Total',
        'nome_produtor': '',
        'volume_total': this.totalVol,
        ...this.dateHeaders
          .reduce((acc, header) => ({
            ...acc, [header.value]: this.totalsByDay[header.value]
          }), {})
      }];

      const routes = _.groupBy(
        _.orderBy(this.orderedItems(), "nome_rota"),
        "nome_rota"
      );

      new Map(Object.entries(routes))
        .forEach((items, route) => {
          body.push({
            tag: 'section',
            class: 'row',
            children: [{
              tag: 'datatable',
              title: route,
              headers,
              items,
            }]
          });
        });

      body.push({
        tag: 'section',
        class: 'row',
        children: [
          {
            tag: 'div',
            contents: '&nbsp;'
          },
          {
            tag: 'datatable',
            headers,
            items: totals,
          },
        ]
      });

      const header = [{
        tag: 'div',
        class: 'row',
        children: [{
          tag: 'div',
          class: 'text-right',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }]
      }];

      await this.printPdf({ pages: [{ title, header, body }], landscape: true });
    },
  },
};
</script>
