<template>
  <div
    class="mx-6 px-6 mt-4"
  >
    <v-row class="pa-0 ma-0">
      <v-col
        cols="12"
        md="3"
      >
        <v-date-range-picker
          v-model="filters.dateRange"
          dark
          hide-details
          @change="onFilter"
        />
      </v-col>
      <v-col
        v-if="hasSecondaryRawMaterial"
        cols="12"
        md="3"
      >
        <v-select
          v-model="rawMaterial"
          :items="rawMaterialItems"
          label="Matéria Prima"
          prepend-inner-icon="info"
          dark
          filled
          hide-details
          clearable
          @change="onFilter"
        />
      </v-col>
    </v-row>
    <v-card
      dark
      :style="{ background: 'rgba(0, 0, 0, 0.5)' }"
    >
      <v-card-text>
        <v-chart
          :options="options"
          :style="{ width: '100%', height: `400px !important` }"
          autoresize
        />
      </v-card-text>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay
      v-model="loading"
      dark
    >
      Carregando
      <v-progress-circular
        indeterminate
        size="20"
      />
    </v-overlay>
  </div>
</template>

<script>
import _ from "lodash";
import XLSX from "xlsx-js-style";
import moment from "moment-timezone";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import FilterRawMaterialMixin from "@/Support/Mixins/FilterRawMaterialMixin.js";

export default {
  components: {
    VDateRangePicker,
  },

  mixins: [FilterRawMaterialMixin],

  data() {
    return {
      loading: false,
      volumeMes: 0,
      relatorioVolumeMes: [],
      filters: {
        dateRange: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
    };
  },

  computed: {
    options() {
      const groupedData = {};

      this.relatorioVolumeMes.forEach(item => {
        const month = moment(item.mes, "YYYY-MM").format("MM/YYYY");
        const materiaPrima = item.nome_materia_prima;
        const total = parseFloat(item.total || 0);

        if (!groupedData[month]) {
          groupedData[month] = {};
        }
        if (!groupedData[month][materiaPrima]) {
          groupedData[month][materiaPrima] = 0;
        }
        groupedData[month][materiaPrima] += total;
      });

      const months = Object.keys(groupedData).sort();
      const materiaPrimas = [...new Set(this.relatorioVolumeMes.map(item => item.nome_materia_prima))];

      const series = materiaPrimas.map(materiaPrima => ({
        name: materiaPrima,
        type: 'line',
        smooth: true,
        data: months.map(month => groupedData[month][materiaPrima] || 0),
        label: {
          show: true,
          position: 'top',
          fontSize: 12,
          color: '#fff',
          fontWeight: 'bold',
          formatter: (params) => {
            return params.data > 0 ? this.formatNumber(params.data) : '';
          },
        },
      }));

      return {
        legend: {
          show: true,
          textStyle: {
            color: "#fff",
          },
        },
        xAxis: {
          type: "category",
          data: months,
          axisLabel: {
            color: "rgba(255, 255, 255, 0.8)",
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            margin: 0,
            color: "rgba(255, 255, 255, 0.8)",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          left: '1%',
          right: '5%',
          top: '50px',
          bottom: '0%',
          containLabel: true,
        },
        series: series,
        toolbox: {
          right: "10",
          left: "10",
          iconStyle: {
            borderColor: "#fff",
          },
          feature: {
            saveAsImage: {
              title: "Volume geral por mês",
              name: "Volume geral por mês",
              backgroundColor: '#193D5B',
            },
          },
        },
      };
    },
    optionsVolumePorMes() {
      return {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: this.relatorioVolumeMes.map(geral => moment(geral.mes, "YYYY-MM").format("MM/YYYY")),
        },
        theme: {
          mode: "light",
        },
      };
    },
  },

  mounted() {
    this.loadVolumePorMes();
  },

  methods: {

    onFilter() {
      this.loadVolumePorMes();
    },

    async loadVolumePorMes() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.post(
          `/relatorios/volumeMes`,
          { data_inicio: startDate, data_fim: endDate, materiaPrima: this.rawMaterial || null }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }
        this.relatorioVolumeMes = data.sort((a, b) => moment(a.mes).diff(moment(b.mes)));
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    exportExcel() {
      let records = this.relatorioVolumeMes.map(rel => {
        const cols = [
          {
            key: "Mês",
            value: moment(rel.mes, "YYYY-MM").format("MM/YYYY"),
          },
          {
            key: "Volume",
            value: parseFloat(rel.total).toFixed(2),
          },
        ];

        const mapped = cols.map(item => ({ [item.key]: item.value }));

        return Object.assign({}, ...mapped);
      });

      const data = XLSX.utils.json_to_sheet(records);
      const workbook = XLSX.utils.book_new();
      const filename = `volume_geral_por_mes`;

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    formatNumber(numero) {
      return numero.toLocaleString('pt-BR');
    }
  },
};
</script>
