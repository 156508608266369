<template>
  <div>
    <v-dialog
      v-model="show"
      width="1060"
      max-width="1060"
      scrollable
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card
        flat
        class="pa-0"
      >
        <v-card-title>
          <span class="text-h6">{{ form.name || 'Cadastro de Produto' }}</span>
          <v-spacer />
          <v-btn
            icon
            small
            depressed
            @click="close()"
          >
            <v-icon small>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle
          v-if="form.code"
          class="pb-2"
        >
          Código: <b>{{ form.code }}</b>
        </v-card-subtitle>
        <div class="mx-6">
          <v-tabs
            v-model="tab"
          >
            <v-tab href="#item-tab">
              <v-icon left>
                assignment
              </v-icon>
              Dados do produto
            </v-tab>

            <v-tab href="#stock-data-tab">
              <v-icon left>
                archive
              </v-icon>
              Dados do estoque
            </v-tab>

            <v-tab
              v-if="form.productionItem"
              href="#production-tab"
            >
              <v-icon left>
                conveyor_belt
              </v-icon>
              Produção
            </v-tab>

            <v-tab
              v-if="savedData.stockControl === 'FISICO'"
              href="#stock-tab"
            >
              <v-icon left>
                shelves
              </v-icon>
              Controle de estoque
            </v-tab>
          </v-tabs>
        </div>
        <v-card-text>
          <div>
            <v-tabs-items
              v-model="tab"
              touchless
            >
              <v-tab-item value="item-tab">
                <v-form
                  ref="infoForm"
                  lazy-validation
                  @submit.prevent="save"
                >
                  <v-row>
                    <v-col
                      v-for="(image, idx) in form.images"
                      :key="idx"
                    >
                      <file-viewer
                        :value="image"
                        @removeFile="onRemoveImage(idx)"
                      />
                    </v-col>
                    <v-col
                      v-if="form.images.length < 4"
                      class="pb-0 text-center align-self-center flex-column justify-center"
                    >
                      <v-btn
                        outlined
                        class="mb-5"
                        @click="showFileUpload = !showFileUpload"
                      >
                        <v-icon>add_a_photo</v-icon>
                        <span class="ml-2">Adicionar</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <v-text-field
                        v-model="form.code"
                        label="Código"
                        prepend-inner-icon="subtitles"
                        persistent-hint
                        :hint="!form.id ? 'Deixe vazio para ser gerado automaticamente' : undefined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <v-text-field
                        v-model="form.name"
                        label="Nome *"
                        prepend-inner-icon="branding_watermark"
                        :rules="[v => !!v || 'Campo obrigatório!']"
                        persistent-hint
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <money-input
                        v-model="form.value"
                        label="Valor"
                        prefix="R$"
                        persistent-hint
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <v-select
                        v-model="form.type"
                        label="Tipo"
                        :items="[
                          { value: 'PRODUTO_ACABADO', text: 'Produto Acabado' },
                          { value: 'INSUMO_PRODUCAO', text: 'Insumos de Produção' },
                          { value: 'INSUMO_AGRICOLA', text: 'Insumos Agrícolas' },
                          { value: 'MATERIA_PRIMA', text: 'Matéria Prima' },
                        ]"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <group-select
                        ref="group-select"
                        v-model="form.group"
                        label="Grupo *"
                        persistent-hint
                        return-object
                        :rules="[v => !!v || 'Campo obrigatório!']"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <v-text-field
                        v-model="form.barcode"
                        label="GTIN/EAN"
                        prepend-inner-icon="subtitles"
                        persistent-hint
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <masked-text-field
                        v-model="form.ncm"
                        label="NCM"
                        prepend-inner-icon="subtitles"
                        :mask="{ mask: '0000.00.00' }"
                        unmask
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <masked-text-field
                        v-model="form.cest"
                        label="CEST"
                        prepend-inner-icon="subtitles"
                        :mask="{ mask: '00.000.00' }"
                        unmask
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <v-select
                        v-model="form.origin"
                        :items="[
                          { value: 0, text: '0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8' },
                          { value: 1, text: '1 - Estrangeira - Importação direta, exceto a indicada no código 6' },
                          { value: 2, text: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7' },
                          { value: 3, text: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%' },
                          { value: 4, text: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes' },
                          { value: 5, text: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%' },
                          { value: 6, text: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural' },
                          { value: 7, text: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural' },
                          { value: 8, text: '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%' },
                        ]"
                        label="Origem da Mercadoria"
                        prepend-inner-icon="place"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <masked-text-field
                        v-model="form.sif"
                        label="SIF/DIPOA"
                        :mask="{ mask: '0000/0000' }"
                        hint="Registro no Ministério da Agricultura"
                        placeholder="####/####"
                        :rules="[() => !form.sif || form.sif.length === 9 || 'Sif inválido']"
                        validate-on-blur
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <v-select
                        v-model="form.productionItem"
                        label="Item de Produção"
                        prepend-inner-icon="panorama_vertical_select"
                        :items="[
                          { text: 'Sim', value: true },
                          { text: 'Não', value: false },
                        ]"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <v-select
                        v-model="form.stockControl"
                        label="Controle de Estoque"
                        prepend-inner-icon="pallet"
                        :items="[
                          { text: 'FÍSICO', value: 'FISICO' },
                          { text: 'SILO', value: 'SILO' },
                          { text: 'NÃO CONTROLA', value: 'NAO_CONTROLA' },
                        ]"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <v-select
                        v-model="form.sellingItem"
                        label="Item de Venda"
                        prepend-inner-icon="sell"
                        :items="[
                          { text: 'Sim', value: true },
                          { text: 'Não', value: false },
                        ]"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                    >
                      <v-select
                        v-model="form.purchaseItem"
                        label="Item de Compra"
                        prepend-inner-icon="sell"
                        :items="[
                          { text: 'Sim', value: true },
                          { text: 'Não', value: false },
                        ]"
                      />
                    </v-col>
                    <v-col
                      v-if="form.stockControl !== 'FISICO'"
                      cols="6"
                      sm="4"
                    >
                      <v-autocomplete
                        v-model="form.rawMaterialGroupId"
                        label="Agrupador matéria prima"
                        prepend-inner-icon="communities"
                        :items="rawMaterialParents"
                        item-value="id"
                        item-text="description"
                        clearable
                      />
                    </v-col>
                    <v-col
                      v-if="form.stockControl === 'FISICO'"
                      cols="6"
                      sm="4"
                    >
                      <v-select
                        v-model="form.requiresLot"
                        label="Controlar Lotes"
                        prepend-inner-icon="qr_code"
                        :items="[
                          { text: 'Sim', value: true },
                          { text: 'Não', value: false },
                        ]"
                      />
                    </v-col>
                    <v-col
                      v-if="form.stockControl === 'FISICO' && form.requiresLot"
                      cols="6"
                      sm="4"
                    >
                      <v-select
                        v-model="form.manualLot"
                        label="Lote Manual"
                        prepend-inner-icon="qr_code"
                        hint="Se o lote for manual, não haverá validação de estoque pelo sistema"
                        :items="[
                          { text: 'Sim', value: true },
                          { text: 'Não', value: false },
                        ]"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>

              <v-tab-item
                value="stock-data-tab"
                eager
              >
                <v-form
                  ref="stockForm"
                  lazy-validation
                  @submit.prevent="save"
                >
                  <v-row>
                    <v-col
                      cols="6"
                      sm="3"
                    >
                      <v-select
                        v-model="form.measurement"
                        :items="measurements"
                        label="Unidade de Medida *"
                        prepend-inner-icon="qr_code"
                        persistent-hint
                        :rules="[v => !!v || 'Campo obrigatório!']"
                      />
                    </v-col>
                    <v-col
                      v-if="form.stockControl === 'FISICO'"
                      cols="6"
                      :sm="form.scaleItem ? '3' : true"
                    >
                      <v-select
                        v-model="form.scaleItem"
                        label="Item de Balança"
                        prepend-inner-icon="scale"
                        :items="[
                          { text: 'Sim', value: true },
                          { text: 'Não', value: false },
                        ]"
                      />
                    </v-col>
                    <v-col
                      v-if="!form.scaleItem"
                      cols="6"
                      sm
                    >
                      <masked-text-field
                        v-model="form.grossWeight"
                        label="Peso Bruto"
                        prepend-inner-icon="scale"
                        :mask="masks.float"
                        placeholder="Em Kg"
                        unmask
                        suffix="Kg"
                      />
                    </v-col>
                    <v-col
                      v-if="!form.scaleItem"
                      cols="6"
                      sm
                    >
                      <masked-text-field
                        v-model="form.tareWeight"
                        label="Peso Tara"
                        prepend-inner-icon="scale"
                        :mask="masks.float"
                        placeholder="Em Kg"
                        unmask
                        suffix="Kg"
                      />
                    </v-col>
                    <v-col
                      v-if="!form.scaleItem"
                      cols="6"
                      sm
                    >
                      <masked-text-field
                        :value="parseFloat(form.grossWeight || 0) - parseFloat(form.tareWeight || 0)"
                        label="Peso Líquido"
                        prepend-inner-icon="scale"
                        :mask="masks.float"
                        placeholder="Em Kg"
                        unmask
                        suffix="Kg"
                        disabled
                      />
                    </v-col>
                  </v-row>

                  <span class="text-h6 d-block mt-4">Embalagens / Outras Unidades de Medida</span>
                  <v-divider class="mb-4" />

                  <v-row>
                    <v-col
                      v-for="(field, idx) of form.otherMeasurements"
                      :key="`measurement-${idx}`"
                      cols="12"
                      class="pt-0"
                    >
                      <v-card style="margin: 1px">
                        <v-card-text>
                          <v-row>
                            <v-col
                              cols="4"
                              sm="2"
                              class="pt-0"
                            >
                              <v-text-field
                                v-model="field.code"
                                label="Código"
                                prepend-inner-icon="subtitles"
                              />
                            </v-col>
                            <v-col
                              class="pt-0"
                            >
                              <v-text-field
                                v-model="field.description"
                                label="Descrição *"
                                prepend-inner-icon="branding_watermark"
                                placeholder="Caixa/Pacote/Pote/Embalagem"
                                :rules="[v => !!v || 'Campo obrigatório!']"
                              />
                            </v-col>
                            <v-col
                              class="px-0 d-flex flex-column justify-center"
                              style="max-width: 35px;"
                            >
                              <v-btn
                                icon
                                @click="removeMeasurement(idx)"
                              >
                                <v-icon>
                                  delete
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="4"
                              sm="3"
                              md="2"
                              class="pt-0"
                            >
                              <v-select
                                v-model="field.measurement"
                                :items="measurements"
                                label="Unidade de Medida *"
                                prepend-inner-icon="qr_code"
                                persistent-hint
                                :rules="[v => !!v || 'Campo obrigatório!']"
                              />
                            </v-col>
                            <v-col
                              cols="4"
                              sm="3"
                              md="2"
                              class="pt-0"
                            >
                              <masked-text-field
                                v-model="field.conversionFactor"
                                :label="form.measurement && field.measurement ? `Qtde. de ${form.measurement} por ${field.measurement}` : 'Fator de Conversão'"
                                prepend-inner-icon="swap_horiz"
                                :mask="masks.float"
                                unmask
                                hint="Fator de Conversão"
                              />
                            </v-col>
                            <v-col
                              cols="4"
                              sm="3"
                              md="2"
                              class="pt-0"
                            >
                              <masked-text-field
                                v-model="field.tareWeight"
                                label="Peso Tara"
                                prepend-inner-icon="scale"
                                :mask="masks.float"
                                unmask
                                placeholder="Em Kg"
                                suffix="Kg"
                              />
                            </v-col>
                            <v-col
                              cols="6"
                              sm="3"
                              md="2"
                              class="pt-0"
                            >
                              <v-select
                                v-model="field.sellingItem"
                                label="Item de Venda"
                                prepend-inner-icon="sell"
                                :items="[
                                  { text: 'Sim', value: true },
                                  { text: 'Não', value: false },
                                ]"
                              />
                            </v-col>
                            <v-col
                              class="pt-0"
                            >
                              <v-text-field
                                v-model="field.barcode"
                                label="DUN-14/Código de Barras"
                                prepend-inner-icon="subtitles"
                                persistent-hint
                              />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="3"
                              class="pt-0"
                            >
                              <masked-text-field
                                v-model="field.width"
                                label="Largura"
                                :mask="masks.float"
                                unmask
                                prepend-inner-icon="straighten"
                                suffix="cm"
                                placeholder="Em cm"
                                :rules="[() => !!field.width || (!field.width && !field.height && !field.depth) || 'Campo obrigatório!']"
                                validate-on-blur
                              />
                            </v-col>
                            <v-col
                              cols="3"
                              class="pt-0"
                            >
                              <masked-text-field
                                v-model="field.height"
                                label="Altura"
                                :mask="masks.float"
                                unmask
                                prepend-inner-icon="straighten"
                                suffix="cm"
                                placeholder="Em cm"
                                :rules="[() => !!field.height || (!field.width && !field.height && !field.depth) || 'Campo obrigatório!']"
                                validate-on-blur
                              />
                            </v-col>
                            <v-col
                              cols="3"
                              class="pt-0"
                            >
                              <masked-text-field
                                v-model="field.depth"
                                label="Comprimento"
                                :mask="masks.float"
                                unmask
                                prepend-inner-icon="straighten"
                                suffix="cm"
                                placeholder="Em cm"
                                :rules="[() => !!field.depth || (!field.width && !field.height && !field.depth) || 'Campo obrigatório!']"
                                validate-on-blur
                              />
                            </v-col>
                            <v-col
                              cols="3"
                              class="pt-0"
                            >
                              <masked-text-field
                                :value="parseFloat(field.width || 0) * parseFloat(field.height || 0) * parseFloat(field.depth || 0)"
                                label="Volume"
                                :mask="masks.float"
                                unmask
                                prepend-inner-icon="inventory_2"
                                suffix="cm³"
                                disabled
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <div class="d-flex justify-end">
                    <v-btn
                      outlined
                      color="blue"
                      class="my-2"
                      @click="addMeasurement"
                    >
                      <v-icon>add</v-icon> Embalagem
                    </v-btn>
                  </div>
                </v-form>
              </v-tab-item>

              <v-tab-item
                v-if="form.productionItem"
                value="production-tab"
                eager
              >
                <v-form
                  ref="formulationForm"
                  lazy-validation
                  @submit.prevent="save"
                >
                  <template v-if="form.stockControl === 'FISICO'">
                    <div class="d-flex mt-4">
                      <span class="text-h6 d-block">Configurações</span>

                      <v-spacer />

                      <v-menu
                        bottom
                        right
                      >
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                          >
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item
                            @click="copyProductionFromItem()"
                          >
                            <v-list-item-icon>
                              <v-icon>file_copy</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Clonar Produção de Outro Produto</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <v-divider class="mb-4" />

                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-card outlined>
                          <v-card-title class="pb-0 secondary--text">
                            Formato de número do lote e vencimento
                          </v-card-title>
                          <v-card-text>
                            <div class="d-flex">
                              <v-checkbox
                                v-for="(format, value) of lotFormats"
                                :key="value"
                                v-model="form.productionSettings.lotFormat"
                                :label="format.label"
                                :value="value"
                                hide-details
                                class="mt-1 mb-3"
                              />
                            </div>
                            <draggable
                              :list="form.productionSettings.lotFormat"
                              animation="200"
                              delay="100"
                              delay-on-touch-only
                            >
                              <v-chip
                                v-for="item of form.productionSettings.lotFormat"
                                :key="item"
                                draggable
                                class="mr-2"
                                style="cursor:grab"
                              >
                                {{ lotFormats[item].label }}
                              </v-chip>
                            </draggable>
                            <div
                              v-if="form.productionSettings.lotFormat.length > 0"
                              class="mt-4 subtitle-1"
                            >
                              Exemplo: <b>{{ lotExample }}</b>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-card outlined>
                          <v-card-title class="pb-0 secondary--text">
                            Prazos de Validade
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col
                                v-for="dairy of dairies"
                                :key="dairy.id"
                                cols="4"
                              >
                                <masked-text-field
                                  v-model="form.productionSettings.expirationDates[dairy.id]"
                                  :label="dairy.name"
                                  hint="Prazo de Validade"
                                  persistent-hint
                                  unmask
                                  :mask="masks.integer"
                                  suffix="DIAS"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-card outlined>
                          <v-card-title class="pb-0 secondary--text">
                            Parâmetros
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col
                                cols="4"
                                md="2"
                              >
                                <masked-text-field
                                  v-model="form.productionSettings.tolerancePerc"
                                  label="Tolerância de Produção"
                                  unmask
                                  :mask="masks.percentual"
                                  suffix="%"
                                  hint="Valida a quantidade máx. e mín. de produção"
                                />
                              </v-col>
                              <v-col
                                cols="4"
                                md="2"
                              >
                                <v-select
                                  v-model="form.productionSettings.showLimitRanges"
                                  label="Exibir faixas de limites"
                                  :items="[
                                    { text: 'Ativo', value: true },
                                    { text: 'Inativo', value: false }
                                  ]"
                                />
                              </v-col>
                              <v-col
                                cols="4"
                                md="2"
                              >
                                <v-select
                                  v-model="form.productionSettings.hasQueijomatic"
                                  label="Usa Queijomatic"
                                  :items="[
                                    { text: 'Ativo', value: true },
                                    { text: 'Inativo', value: false },
                                  ]"
                                />
                              </v-col>
                              <v-col
                                cols="4"
                                md="2"
                              >
                                <v-select
                                  v-model="form.productionSettings.hasLosses"
                                  label="Gera Perdas de Produção"
                                  :items="[
                                    { text: 'Ativo', value: true },
                                    { text: 'Inativo', value: false },
                                  ]"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                  <div class="d-flex mt-4">
                    <span class="text-h6 d-block">Etapas de Produção</span>

                    <v-spacer />

                    <v-menu
                      v-if="form.stockControl !== 'FISICO'"
                      bottom
                      right
                    >
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                        >
                          <v-icon>more_vert</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click="copyProductionFromItem()"
                        >
                          <v-list-item-icon>
                            <v-icon>file_copy</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Clonar Produção de Outro Produto</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                  <v-divider class="mb-4" />

                  <draggable
                    :list="form.productionSteps"
                    handle=".handle"
                    ghost-class="ghost"
                    animation="200"
                    delay="100"
                    delay-on-touch-only
                  >
                    <v-row
                      v-for="(data, idx) of form.productionSteps"
                      :key="`step-${idx}`"
                      style="border-bottom: 1px #eee solid;"
                    >
                      <v-col
                        class="py-0 d-flex flex-column justify-center"
                        style="max-width: 49px;"
                      >
                        <v-icon
                          class="handle"
                          style="cursor:grab"
                        >
                          reorder
                        </v-icon>
                      </v-col>
                      <v-col class="pt-1 pb-0">
                        <v-select
                          v-model="form.productionSteps[idx]"
                          label="Etapa"
                          :items="productionSteps"
                          return-object
                          item-value="id"
                          item-text="description"
                          persistent-hint
                          :rules="[v => !!v || 'Campo obrigatório!']"
                        />
                      </v-col>
                      <v-col class="pt-1 pb-0">
                        <v-text-field
                          :value="data?.duration"
                          label="Duração"
                          disabled
                        />
                      </v-col>
                      <v-col
                        class="px-0 d-flex flex-column justify-center"
                        style="max-width: 49px;"
                      >
                        <v-btn
                          icon
                          @click.stop="removeStep(idx)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </draggable>

                  <div class="d-flex justify-end">
                    <v-btn
                      outlined
                      color="blue"
                      class="my-2"
                      @click="addStep"
                    >
                      <v-icon>add</v-icon> Etapa
                    </v-btn>
                  </div>

                  <span class="text-h6 d-block mt-4">Componentes</span>
                  <v-divider class="mb-4" />

                  <draggable
                    :list="formulation.items"
                    handle=".handle"
                    ghost-class="ghost"
                    animation="200"
                    delay="100"
                    delay-on-touch-only
                  >
                    <v-row
                      v-for="(data, idx) of formulation.items"
                      :key="`item-${idx}`"
                      style="border-bottom: 1px #eee solid;"
                    >
                      <v-col
                        class="py-0 d-flex flex-column justify-center"
                        style="max-width: 49px;"
                      >
                        <v-icon
                          class="handle"
                          style="cursor:grab"
                        >
                          reorder
                        </v-icon>
                      </v-col>
                      <v-col
                        class="pt-1 pb-0"
                        style="flex-grow: 3"
                      >
                        <v-autocomplete
                          v-model="data.item"
                          label="Item *"
                          :items="items"
                          :rules="[v => (!!v && !!v.id) || 'Campo obrigatório!']"
                          item-value="id"
                          item-text="description"
                          return-object
                          @change="onComponentChange(idx)"
                        >
                          <template #selection="{ item }">
                            {{ item.description }}
                            <div class="mx-1 caption font-weight-light">
                              ({{ getType(item.type) }})
                            </div>
                          </template>
                          <template #item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.description }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <v-chip
                                  x-small
                                  :color="getTypeColor(item.type)"
                                  text-color="white"
                                >
                                  {{ getType(item.type) }}
                                </v-chip>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col class="pt-1 pb-0">
                        <masked-text-field
                          v-model="data.quantity"
                          label="Quantidade Base *"
                          persistent-hint
                          unmask
                          :mask="masks.float"
                          :suffix="data.item ? data.item.measurement : undefined"
                          :rules="[v => !!v && data.quantity > 0 || 'Campo obrigatório!']"
                          validate-on-blur
                        />
                      </v-col>
                      <v-col class="pt-1 pb-0">
                        <v-select
                          v-model="data.writeOffType"
                          label="Tipo de Baixa *"
                          :items="['MANUAL', 'AUTOMATICA']"
                          :disabled="data?.item?.stockControl === 'SILO' || !!data?.item?.rawMaterialGroupId"
                          :rules="[v => !!v || 'Campo obrigatório!']"
                        />
                      </v-col>
                      <v-col
                        v-if="data?.item?.stockControl !== 'SILO' && !data?.item?.rawMaterialGroupId && form.productionSettings.hasQueijomatic"
                        class="pt-1 pb-0"
                      >
                        <v-select
                          v-model="data.showQueijomatic"
                          label="Campo Queijomatic"
                          :items="[
                            { text: 'Ativo', value: true },
                            { text: 'Inativo', value: false },
                          ]"
                        />
                      </v-col>
                      <v-col
                        v-if="data?.item?.stockControl !== 'SILO' && !data?.item?.rawMaterialGroupId && form.productionSettings.hasLosses"
                        class="pt-1 pb-0"
                      >
                        <v-select
                          v-model="data.showLosses"
                          label="Campo Perda"
                          :items="[
                            { text: 'Ativo', value: true },
                            { text: 'Inativo', value: false },
                          ]"
                        />
                      </v-col>
                      <template v-if="data.writeOffType === 'MANUAL' && form.productionSettings.showLimitRanges">
                        <v-col class="pt-1 pb-0">
                          <masked-text-field
                            v-model="data.minQuantity"
                            label="Mínimo"
                            persistent-hint
                            unmask
                            :mask="masks.float"
                            :suffix="data.item ? data.item.measurement : undefined"
                          />
                        </v-col>
                        <v-col class="pt-1 pb-0">
                          <masked-text-field
                            v-model="data.maxQuantity"
                            label="Máximo"
                            persistent-hint
                            unmask
                            :mask="masks.float"
                            :suffix="data.item ? data.item.measurement : undefined"
                          />
                        </v-col>
                      </template>
                      <v-col
                        v-if="form.productionSteps?.length > 0"
                        class="pt-1 pb-0"
                      >
                        <v-select
                          v-model="data.step"
                          label="Etapa"
                          :items="form.productionSteps"
                          item-value="id"
                          item-text="description"
                          return-object
                          clearable
                        />
                      </v-col>
                      <v-col
                        class="px-0 d-flex flex-column justify-center"
                        style="max-width: 49px;"
                      >
                        <v-btn
                          icon
                          @click.stop="removeComponent(idx)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </draggable>
                  <div class="d-flex justify-end">
                    <v-btn
                      outlined
                      color="blue"
                      class="my-2"
                      @click="addComponent"
                    >
                      <v-icon>add</v-icon> Componente
                    </v-btn>
                  </div>

                  <span class="text-h6 d-block mt-4">Subprodutos</span>
                  <v-divider class="mb-4" />

                  <draggable
                    :list="formulation.byproducts"
                    handle=".handle"
                    ghost-class="ghost"
                    animation="200"
                    delay="100"
                    delay-on-touch-only
                  >
                    <v-row
                      v-for="(data, idx) of formulation.byproducts"
                      :key="`byproduct-${idx}`"
                      style="border-bottom: 1px #eee solid;"
                    >
                      <v-col
                        class="py-0 d-flex flex-column justify-center"
                        style="max-width: 49px;"
                      >
                        <v-icon
                          class="handle"
                          style="cursor:grab"
                        >
                          reorder
                        </v-icon>
                      </v-col>
                      <v-col
                        class="pt-1 pb-0"
                        style="flex-grow: 3"
                      >
                        <v-autocomplete
                          v-model="data.item"
                          label="Item *"
                          :items="items"
                          :rules="[v => (!!v && !!v.id) || 'Campo obrigatório!']"
                          item-value="id"
                          item-text="description"
                          return-object
                          @change="onByproductChange(idx)"
                        >
                          <template #selection="{ item }">
                            {{ item.description }}
                            <div class="mx-1 caption font-weight-light">
                              ({{ getType(item.type) }})
                            </div>
                          </template>
                          <template #item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.description }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <v-chip
                                  x-small
                                  :color="getTypeColor(item.type)"
                                  text-color="white"
                                >
                                  {{ getType(item.type) }}
                                </v-chip>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col class="pt-1 pb-0">
                        <masked-text-field
                          v-model="data.quantity"
                          label="Quantidade Base *"
                          persistent-hint
                          unmask
                          :mask="masks.float"
                          :suffix="data.item ? data.item.measurement : undefined"
                          :rules="[v => !!v && data.quantity > 0 || 'Campo obrigatório!']"
                          validate-on-blur
                        />
                      </v-col>
                      <v-col class="pt-1 pb-0">
                        <v-select
                          v-model="data.entryType"
                          label="Tipo de Entrada *"
                          :items="['MANUAL', 'AUTOMATICA']"
                          :disabled="data?.item?.stockControl === 'SILO' || !!data?.item?.rawMaterialGroupId"
                          :rules="[v => !!v || 'Campo obrigatório!']"
                        />
                      </v-col>
                      <v-col
                        v-if="data?.item?.stockControl !== 'SILO' && !data?.item?.rawMaterialGroupId && form.productionSettings.hasQueijomatic"
                        class="pt-1 pb-0"
                      >
                        <v-select
                          v-model="data.showQueijomatic"
                          label="Exibir Queijomatic"
                          :items="[
                            { text: 'Ativo', value: true },
                            { text: 'Inativo', value: false },
                          ]"
                        />
                      </v-col>
                      <v-col
                        v-if="data?.item?.stockControl !== 'SILO' && !data?.item?.rawMaterialGroupId && form.productionSettings.hasLosses"
                        class="pt-1 pb-0"
                      >
                        <v-select
                          v-model="data.showLosses"
                          label="Campo Perda"
                          :items="[
                            { text: 'Ativo', value: true },
                            { text: 'Inativo', value: false },
                          ]"
                        />
                      </v-col>
                      <template v-if="data.entryType === 'MANUAL' && form.productionSettings.showLimitRanges">
                        <v-col class="pt-1 pb-0">
                          <masked-text-field
                            v-model="data.minQuantity"
                            label="Mínimo"
                            persistent-hint
                            unmask
                            :mask="masks.float"
                            :suffix="data.item ? data.item.measurement : undefined"
                          />
                        </v-col>
                        <v-col class="pt-1 pb-0">
                          <masked-text-field
                            v-model="data.maxQuantity"
                            label="Máximo"
                            persistent-hint
                            unmask
                            :mask="masks.float"
                            :suffix="data.item ? data.item.measurement : undefined"
                          />
                        </v-col>
                      </template>
                      <v-col
                        v-if="form.productionSteps?.length > 0"
                        class="pt-1 pb-0"
                      >
                        <v-select
                          v-model="data.step"
                          label="Etapa"
                          :items="form.productionSteps"
                          item-value="id"
                          item-text="description"
                          return-object
                          clearable
                        />
                      </v-col>
                      <v-col
                        class="px-0 d-flex flex-column justify-center"
                        style="max-width: 49px;"
                      >
                        <v-btn
                          icon
                          @click.stop="removeByProduct(idx)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </draggable>

                  <div class="d-flex justify-end">
                    <v-btn
                      outlined
                      color="blue"
                      class="my-2"
                      @click="addByproduct"
                    >
                      <v-icon>add</v-icon> Subproduto
                    </v-btn>
                  </div>

                  <span class="text-h6 d-block mt-4">Coprodutos</span>
                  <v-divider class="mb-2" />
                  <span class="text-subtitle d-block mb-4">Usará como base o produto principal para ser produzido</span>

                  <draggable
                    :list="formulation.coproducts"
                    handle=".handle"
                    ghost-class="ghost"
                    animation="200"
                    delay="100"
                    delay-on-touch-only
                  >
                    <v-row
                      v-for="(data, idx) of formulation.coproducts"
                      :key="`coproduct-${idx}`"
                      style="border-bottom: 1px #eee solid;"
                    >
                      <v-col
                        class="py-0 d-flex flex-column justify-center"
                        style="max-width: 49px;"
                      >
                        <v-icon
                          class="handle"
                          style="cursor:grab"
                        >
                          reorder
                        </v-icon>
                      </v-col>
                      <v-col
                        class="pt-1 pb-0"
                        style="flex-grow: 3"
                      >
                        <v-autocomplete
                          v-model="data.item"
                          label="Item *"
                          :items="items"
                          :rules="[v => (!!v && !!v.id) || 'Campo obrigatório!']"
                          item-value="id"
                          item-text="description"
                          return-object
                        >
                          <template #selection="{ item }">
                            {{ item.description }}
                            <div class="mx-1 caption font-weight-light">
                              ({{ getType(item.type) }})
                            </div>
                          </template>
                          <template #item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.description }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <v-chip
                                  x-small
                                  :color="getTypeColor(item.type)"
                                  text-color="white"
                                >
                                  {{ getType(item.type) }}
                                </v-chip>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col class="pt-1 pb-0">
                        <masked-text-field
                          v-model="data.quantity"
                          :label="form.measurement && data.item?.measurement ? `Qtde. de ${form.measurement} por ${data.item.measurement}` : 'Fator de Conversão'"
                          hint="Fator de Conversão"
                          persistent-hint
                          unmask
                          :mask="masks.float"
                          :suffix="form.measurement"
                          :rules="[v => !!v && data.quantity > 0 || 'Campo obrigatório!']"
                          validate-on-blur
                        />
                      </v-col>
                      <v-col class="pt-1 pb-0">
                        <v-select
                          v-model="data.dairies"
                          label="Apenas Latícinios"
                          :items="dairies"
                          item-value="id"
                          item-text="name"
                          small-chips
                          multiple
                          clearable
                        />
                      </v-col>
                      <v-col
                        class="px-0 d-flex flex-column justify-center"
                        style="max-width: 49px;"
                      >
                        <v-btn
                          icon
                          @click.stop="removeCoproduct(idx)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </draggable>

                  <div class="d-flex justify-end">
                    <v-btn
                      outlined
                      color="blue"
                      class="my-2"
                      @click="addCoproduct"
                    >
                      <v-icon>add</v-icon> Coproduto
                    </v-btn>
                  </div>

                  <span class="text-h6 d-block mt-4">Campos Personalizados</span>
                  <v-divider class="mb-4" />

                  <v-row
                    v-for="(data, idx) of form.productionCustoms"
                    :key="`custom-${idx}`"
                    style="border-bottom: 1px #eee solid;"
                  >
                    <v-col
                      class="py-0 d-flex flex-column justify-center"
                      style="max-width: 49px;"
                    >
                      <v-icon>
                        tune
                      </v-icon>
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <v-text-field
                        v-model="data.title"
                        label="Título *"
                        persistent-hint
                        :rules="[v => !!v || 'Campo obrigatório!']"
                      />
                    </v-col>
                    <v-col
                      v-if="form.productionSteps?.length > 0"
                      class="pt-1 pb-0"
                    >
                      <v-select
                        v-model="data.step"
                        label="Etapa"
                        :items="form.productionSteps"
                        item-value="id"
                        item-text="description"
                        return-object
                        clearable
                      />
                    </v-col>
                    <v-col
                      class="px-0 d-flex flex-column justify-center"
                      style="max-width: 120px;"
                    >
                      <v-btn
                        outlined
                        color="blue"
                        class="mr-4"
                        @click="addField(idx)"
                      >
                        <v-icon>add</v-icon> campo
                      </v-btn>
                    </v-col>
                    <v-col
                      class="px-0 d-flex flex-column justify-center"
                      style="max-width: 49px;"
                    >
                      <v-btn
                        icon
                        @click.stop="removeCustom(idx)"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pt-1 pb-0"
                    >
                      <draggable
                        :list="data.fields"
                        class="row"
                        handle=".handle"
                        ghost-class="ghost"
                        animation="200"
                        delay="100"
                        delay-on-touch-only
                      >
                        <v-col
                          v-for="(field, fieldIndex) of data.fields"
                          :key="`${idx}-${fieldIndex}`"
                          cols="12"
                          sm="6"
                          md="4"
                          class="pt-1 pb-0"
                        >
                          <v-card>
                            <v-card-title>
                              <v-icon
                                class="handle"
                                style="cursor:grab"
                              >
                                reorder
                              </v-icon>
                              {{ field.title }}
                              <v-spacer />
                              <v-btn
                                icon
                                small
                                @click="removeField(idx, fieldIndex)"
                              >
                                <v-icon small>
                                  delete
                                </v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text>
                              <v-text-field
                                v-model="field.label"
                                label="Rótulo *"
                                persistent-hint
                                :disabled="field.type === 'TANK'"
                                :rules="[v => !!v || 'Campo obrigatório!']"
                              />

                              <v-select
                                v-model="field.type"
                                label="Tipo *"
                                :items="[
                                  { text: 'Aberta', value: 'TEXT' },
                                  { text: 'Numérica', value: 'NUMBER' },
                                  { text: 'Caixa de Seleção', value: 'SELECT' },
                                  { text: 'Data', value: 'DATE' },
                                  { text: 'Hora', value: 'TIME' },
                                  { text: 'Queijomatic', value: 'TANK' },
                                ]"
                                :rules="[v => !!v || 'Campo obrigatório!']"
                                @change="$event === 'TANK' ? field.label = 'Queijomatic' : null"
                              />

                              <v-combobox
                                v-if="field.type === 'SELECT'"
                                v-model="field.options"
                                label="Opções *"
                                hint="Aperte 'enter' ou 'tab' para adicionar um novo"
                                persistent-hint
                                class="mt-0"
                                multiple
                                chips
                                deletable-chips
                                clearable
                                dense
                                :rules="[v => v?.length > 0 || 'Campo obrigatório!']"
                              />

                              <v-checkbox
                                v-model="field.required"
                                label="Obrigatório?"
                              />
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </draggable>
                    </v-col>
                  </v-row>

                  <div class="d-flex justify-end">
                    <v-btn
                      outlined
                      color="blue"
                      class="my-2"
                      @click="addCustom"
                    >
                      <v-icon>add</v-icon> personalizado
                    </v-btn>
                  </div>
                </v-form>
              </v-tab-item>

              <v-tab-item
                v-if="savedData.stockControl === 'FISICO'"
                value="stock-tab"
                justify-center
              >
                <item-stock-tab
                  v-if="!!form.id"
                  :item-data="savedData"
                  @cancel="close()"
                />
                <v-divider />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="secondary"
            outlined
            @click="close()"
          >
            Fechar
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="tab !== 'stock-tab'"
            color="primary"
            outlined
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay
        :value="loading"
        absolute
      >
        <v-card-text>
          Aguarde...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-dialog>

    <v-dialog
      v-model="copy.show"
      max-width="630px"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <span class="text-h6">
              Copiar parâmetros de produção
            </span>

            <v-spacer />

            <v-col
              cols="12"
              sm="6"
              class="pt-0"
            >
              <v-text-field
                v-model="copy.search"
                prepend-inner-icon="search"
                label="Buscar"
                outlined
                hide-details
                dense
              />
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="copy.headers"
          :items="copy.items"
          :search="copy.search"
          class="elevation-1"
          @click:row="selectItemToCopy"
        >
          <template #[`item.type`]="{ value }">
            <span>{{ getType(value) }}</span>
          </template>
        </v-data-table>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            outlined
            @click="copy.show = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <file-upload
      v-model="showFileUpload"
      @insertFiles="onSelectImages"
    />
  </div>
</template>

<script>
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import ItemStockTab from "@/Domains/Registrations/Item/Components/ItemStockTab.vue";
import GroupSelect from "@/Domains/Registrations/Item/Components/GroupSelect.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";
import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";
import Draggable from 'vuedraggable';

import _ from "lodash";
import moment from "moment";
import IMask from "imask";
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

export default {

  components: {
    MaskedTextField,
    ItemStockTab,
    GroupSelect,
    MoneyInput,
    FileUpload,
    FileViewer,
    Draggable,
  },

  props: {
    value: Boolean,
    idItem: String,
    name: String,
  },

  data() {
    return {
      showFileUpload: false,
      loading: false,

      tab: null,

      measurements: [],

      form: {
        type: 'PRODUTO_ACABADO',
        images: [],
        stockControl: 'FISICO',
        sellingItem: false,
        purchaseItem: false,
        requiresLot: false,
        manualLot: false,
        scaleItem: false,
        productionSettings: {
          lotFormat: [],
          expirationDates: {},
        },
        productionSteps: [],
        productionCustoms: [],
        otherMeasurements: [],
      },

      savedData: {},

      items: [],
      productionSteps: [],
      dairies: [],

      types: [
        { text: 'Produto Acabado', value: 'PRODUTO_ACABADO' },
        { text: 'Insumo de Produção', value: 'INSUMO_PRODUCAO' },
        { text: 'Matéria Prima', value: 'MATERIA_PRIMA' },
      ],

      formulation: {
        items: [],
        byproducts: [],
        coproducts: [],
      },

      lotFormats: {
        'DD': {
          label: 'DIA',
          example: moment().format('DD'),
        },
        'MM': {
          label: 'MÊS',
          example: moment().format('MM'),
        },
        'YY': {
          label: 'ANO',
          example: moment().format('YY'),
        },
        'SEQ': {
          label: 'SEQUENCIAL',
          example: '999',
        },
        'LET': {
          label: 'LETRA UNID.',
          example: 'A',
        },
      },

      masks: {
        float: { mask: Number, min: 0, scale: 4, thousandsSeparator: '.', radix: ',' },
        integer: { mask: Number, min: 0, scale: 0, signed: false, thousandsSeparator: '.', radix: ',' },
        percentual: { mask: Number, min: 0, max: 100, scale: 4, thousandsSeparator: '.', radix: ',' },
        time: {
          mask: 'hh:mm',
          blocks: {
            hh: {
              mask: IMask.MaskedRange,
              placeholderChar: 'hh',
              from: 0,
              to: 23,
              maxLength: 2,
            },
            mm: {
              mask: IMask.MaskedRange,
              placeholderChar: 'mm',
              from: 0,
              to: 59,
              maxLength: 2,
            },
          },
        }
      },

      copy: {
        show: false,
        search: null,
        headers: [
          { text: 'Código	', value: 'code' },
          { text: 'Nome', value: 'name' },
          { text: 'Tipo', value: 'type' },
        ],
        items: [],
      },

    };
  },

  computed: {
    show: {
      get() {
        if (this.value) {
          if (this.idItem) {
            this.loadItem();
          }
        }
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$refs.infoForm?.resetValidation();
          this.$refs.stockForm?.resetValidation();
          this.form = {
            type: 'PRODUTO_ACABADO',
            images: [],
            stockControl: 'FISICO',
            sellingItem: false,
            purchaseItem: false,
            requiresLot: false,
            manualLot: false,
            scaleItem: false,
            otherMeasurements: [],
            productionSettings: {
              lotFormat: [],
              expirationDates: {},
            },
            productionSteps: [],
            productionCustoms: [],
          };
          this.savedData = {};
          this.$refs.formulationForm?.resetValidation();
          this.formulation = {
            items: [],
            byproducts: [],
            coproducts: [],
          };
          this.tab = 'item-tab';
        }
        this.$emit("input", value);
      },
    },

    lotExample() {
      if (!this.form.productionSettings?.lotFormat?.length) {
        return ''
      }

      return this.form.productionSettings.lotFormat
        .map(value => this.lotFormats[value].example)
        .join('')
    },

    rawMaterialParents() {
      return this.items.filter(item => item.stockControl === 'SILO' && item.id !== this.idItem)
    }
  },

  mounted() {
    this.loadMeasurement();
    this.loadItems();
    this.loadProductionSteps();
    this.loadDairies();
  },

  methods: {
    async loadMeasurement() {
      try {
        const { data } = await this.$axios.get(`/unidadeMedida/listaJson`);

        if (_.isString(data)) {
          throw data;
        }

        this.measurements = data.map(u => ({
          value: u.sigla,
          text: `${u.sigla} - ${u.descricao}`
        }));
      }
      catch (err) {
        console.warn(err)
      }
    },

    async loadItem() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/item/${this.idItem}`);

        const { form, formulation } = this.parseItemData(data);

        this.form = form;

        this.savedData = _.cloneDeep(this.form);

        this.formulation = formulation;

      } catch (e) {
        this.$snotify.error("Erro ao carregar produto", "Atenção");
        console.error(e);
        this.close();
      } finally {
        this.loading = false;
      }
    },

    parseItemData(data) {
      const form = {
        id: data.id_item,
        code: data.cod_item,
        name: data.nome.trim(),
        type: data.tipo,
        measurement: data.unidade,
        grossWeight: data.peso_bruto,
        tareWeight: data.peso_tara,
        barcode: data.codigo_barras,
        ncm: data.ncm,
        cest: data.cest,
        origin: data.origem,
        sif: data.sif,
        value: parseFloat(data.valor) || null,
        productionItem: data.exibir_no_silo,
        stockControl: data.controle_estoque,
        sellingItem: !!data.item_de_venda,
        purchaseItem: !!data.item_de_compra,
        requiresLot: data.controlar_lotes,
        manualLot: data.lote_manual,
        scaleItem: !!data.item_de_balanca,
        group: {
          value: data.id_item_grupo,
          text: data.nome_item_grupo,
        },
        images: data.imagens || [],
        rawMaterialGroupId: data.id_agrupador_materia_prima,
        otherMeasurements: data.outras_unidades?.map((item) => ({
          id: item.id,
          code: item.codigo,
          description: item.descricao,
          measurement: item.unidade_medida,
          conversionFactor: item.fator_conversao,
          tareWeight: item.peso_tara,
          sellingItem: !!item.item_de_venda,
          barcode: item.codigo_barras,
          width: item.largura,
          height: item.altura,
          depth: item.comprimento,
        })) || [],
        productionSettings: {
          lotFormat: data.parametros_producao?.formato_lote?.split('-') || [],
          expirationDates: _.isEmpty(data.parametros_producao?.prazos_validade) ? {} : data.parametros_producao?.prazos_validade,
          tolerancePerc: data.parametros_producao?.tolerancia_perc,
          showLimitRanges: data.parametros_producao?.exibir_faixas_limites,
          hasQueijomatic: data.parametros_producao?.usa_queijomatic,
          hasLosses: data.parametros_producao?.gera_perdas,
        },
        productionSteps: (data.etapas_producao || []).map((item) => ({
          id: item.id,
          description: item.descricao,
          duration: item.duracao,
        })),
        productionCustoms: (data.personalizados_producao || []).map((item) => ({
          id: item.id,
          title: item.titulo,
          fields: item.campos.map((field) => ({
            id: field.id,
            label: field.rotulo,
            type: field.tipo,
            options: field.opcoes || [],
            required: field.obrigatorio,
          })),
          step: item.id_etapa ? { id: item.id_etapa, description: item.etapa } : undefined,
        }))
      };

      const formulation = {
        items: (data.formulacao?.itens || []).map((item) => ({
          item: {
            id: item.id_item,
            code: item.cod_item,
            name: item.nome,
            type: item.tipo,
            description: item.cod_item ? `${item.cod_item} - ${item.nome}` : item.nome,
            measurement: item.unidade,
            stockControl: item.controle_estoque,
            rawMaterialGroupId: item.id_agrupador_materia_prima,
          },
          quantity: parseFloat(item.quantidade_base),
          minQuantity: item.quantidade_minima ? parseFloat(item.quantidade_minima) : null,
          maxQuantity: item.quantidade_maxima ? parseFloat(item.quantidade_maxima) : null,
          writeOffType: item.tipo_baixa,
          showQueijomatic: item.exibir_tina,
          showLosses: item.exibir_perda,
          step: item.id_etapa ? { id: item.id_etapa, description: item.etapa } : undefined,
        })),
        byproducts: (data.formulacao?.subprodutos || [])
          .filter(item => !item.coproduto)
          .map((item) => ({
            item: {
              id: item.id_item,
              code: item.cod_item,
              name: item.nome,
              type: item.tipo,
              description: item.cod_item ? `${item.cod_item} - ${item.nome}` : item.nome,
              measurement: item.unidade,
              stockControl: item.controle_estoque,
              rawMaterialGroupId: item.id_agrupador_materia_prima,
            },
            quantity: parseFloat(item.quantidade_base),
            minQuantity: item.quantidade_minima ? parseFloat(item.quantidade_minima) : null,
            maxQuantity: item.quantidade_maxima ? parseFloat(item.quantidade_maxima) : null,
            entryType: item.tipo_entrada,
            showQueijomatic: item.exibir_tina,
            showLosses: item.exibir_perda,
            step: item.id_etapa ? { id: item.id_etapa, description: item.etapa } : undefined,
          })),
        coproducts: (data.formulacao?.subprodutos || [])
          .filter(item => item.coproduto)
          .map((item) => ({
            item: {
              id: item.id_item,
              code: item.cod_item,
              name: item.nome,
              type: item.tipo,
              description: item.cod_item ? `${item.cod_item} - ${item.nome}` : item.nome,
              measurement: item.unidade,
              stockControl: item.controle_estoque,
              rawMaterialGroupId: item.id_agrupador_materia_prima,
            },
            quantity: parseFloat(item.quantidade_base),
            entryType: 'MANUAL',
            isCoproduct: true,
            dairies: item.laticinios || [],
          })),
      };

      return { form, formulation };
    },

    async validateForm() {
      if (!await (this.$refs.infoForm?.validate())) {
        this.tab = 'item-tab';
        return false;
      }
      if (!await (this.$refs.stockForm?.validate())) {
        this.tab = 'stock-data-tab';
        return false;
      }
      if (this.form.productionItem) {
        if (!await (this.$refs.formulationForm?.validate())) {
          this.tab = 'production-tab';
          return false;
        }
      }
      return true;
    },

    async save() {
      if (!await this.validateForm()) {
        return;
      }

      try {
        this.loading = true;

        const payload = {
          id_item: this.form.id,
          cod_item: this.form.code,
          nome: this.form.name,
          tipo: this.form.type,
          id_item_grupo: this.form.group.value,
          nome_item_grupo: this.form.group.text,
          unidade: this.form.measurement,
          peso_bruto: this.form.grossWeight,
          peso_tara: this.form.tareWeight,
          codigo_barras: this.form.barcode,
          ncm: this.form.ncm,
          cest: this.form.cest,
          origem: this.form.origin,
          sif: this.form.sif,
          valor: this.form.value,
          imagens: this.form.images,
          id_agrupador_materia_prima: this.form.stockControl !== 'FISICO' ? this.form.rawMaterialGroupId : null,
          exibir_no_silo: this.form.productionItem,
          controle_estoque: this.form.stockControl || 'NAO_CONTROLA',
          item_de_venda: this.form.sellingItem,
          item_de_compra: this.form.purchaseItem,
          controlar_lotes: this.form.requiresLot,
          lote_manual: this.form.requiresLot ? this.form.manualLot : false,
          item_de_balanca: this.form.scaleItem && this.form.stockControl === 'FISICO',
          outras_unidades: this.form.otherMeasurements.map((item) => ({
            id: item.id,
            codigo: item.code,
            descricao: item.description,
            unidade_medida: item.measurement,
            fator_conversao: item.conversionFactor,
            peso_tara: item.tareWeight,
            item_de_venda: item.sellingItem,
            codigo_barras: item.barcode,
            largura: item.width,
            altura: item.height,
            comprimento: item.depth,
            volume: parseFloat(item.width || 0) * parseFloat(item.height || 0) * parseFloat(item.depth || 0),
          })),
          parametros_producao: {
            formato_lote: this.form.productionSettings?.lotFormat?.join('-'),
            prazos_validade: this.form.productionSettings?.expirationDates,
            tolerancia_perc: this.form.productionSettings?.tolerancePerc,
            exibir_faixas_limites: this.form.productionSettings?.showLimitRanges,
            usa_queijomatic: this.form.productionSettings?.hasQueijomatic,
            gera_perdas: this.form.productionSettings?.hasLosses,
          },
          etapas_producao: this.form.productionSteps.length > 0 ? this.form.productionSteps.map((item) => ({
            id: item.id,
            descricao: item.description,
            duracao: item.duration,
          })) : null,
          formulacao: {
            itens: this.formulation.items.map((item) => ({
              tipo: item.item?.type,
              id_item: item.item?.id,
              cod_item: item.item?.code,
              nome: item.item?.name,
              unidade: item.item?.measurement,
              controle_estoque: item.item?.stockControl,
              id_agrupador_materia_prima: item.item?.rawMaterialGroupId,
              quantidade_base: parseFloat(item.quantity),
              quantidade_minima: item.minQuantity && this.form.productionSettings?.showLimitRanges ? parseFloat(item.minQuantity) : undefined,
              quantidade_maxima: item.maxQuantity && this.form.productionSettings?.showLimitRanges ? parseFloat(item.maxQuantity) : undefined,
              tipo_baixa: item.writeOffType,
              exibir_tina: this.form.productionSettings.hasQueijomatic && item.showQueijomatic,
              exibir_perda: this.form.productionSettings.hasLosses && item.showLosses,
              id_etapa: this.form.productionSteps?.find(step => step.id === item.step?.id) ? item.step?.id : undefined,
              etapa: this.form.productionSteps?.find(step => step.id === item.step?.id) ? item.step?.description : undefined,
            })),
            subprodutos: [...this.formulation.byproducts, ...this.formulation.coproducts].map((item) => ({
              tipo: item.item?.type,
              id_item: item.item?.id,
              cod_item: item.item?.code,
              nome: item.item?.name,
              unidade: item.item?.measurement,
              controle_estoque: item.item?.stockControl,
              id_agrupador_materia_prima: item.item?.rawMaterialGroupId,
              quantidade_base: parseFloat(item.quantity),
              quantidade_minima: item.minQuantity && this.form.productionSettings?.showLimitRanges ? parseFloat(item.minQuantity) : undefined,
              quantidade_maxima: item.maxQuantity && this.form.productionSettings?.showLimitRanges ? parseFloat(item.maxQuantity) : undefined,
              tipo_entrada: item.entryType,
              exibir_tina: this.form.productionSettings.hasQueijomatic && item.showQueijomatic,
              exibir_perda: this.form.productionSettings.hasLosses && item.showLosses,
              id_etapa: this.form.productionSteps?.find(step => step.id === item.step?.id) ? item.step?.id : undefined,
              etapa: this.form.productionSteps?.find(step => step.id === item.step?.id) ? item.step?.description : undefined,
              coproduto: item.isCoproduct,
              laticinios: item.dairies,
            })),
          },
          personalizados_producao: this.form.productionCustoms.map((item) => {
            const namespace = this.form.id || this.form.name;
            const customId = item.id || uuidv5(item.title, namespace);
            return {
              id: customId,
              titulo: item.title,
              campos: item.fields.map((field) => ({
                id: field.id || uuidv5(field.label, customId),
                rotulo: field.label,
                tipo: field.type,
                opcoes: field.type === 'SELECT' ? field.options : undefined,
                obrigatorio: field.required,
              })),
              id_etapa: this.form.productionSteps?.find(step => step.id === item.step?.id) ? item.step?.id : undefined,
              etapa: this.form.productionSteps?.find(step => step.id === item.step?.id) ? item.step?.description : undefined,
            }
          }),
        };

        const data = this.form.id ? await this.update(payload) : await this.store(payload);

        this.form.id = data.id;

        if (data.cod_item) {
          this.form.code = data.cod_item;
        }

        this.savedData = _.cloneDeep(this.form);

        this.$emit("change")
        this.$snotify.success("Produto salvo com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar produto", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/item`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/item/${this.form.id}`, payload);
      return data;
    },

    onSelectImages(images) {
      for (let image of images) {
        if (!this.form.images.find(o => o.name == image.name)) {
          this.form.images.push(image);
        }
      }
    },

    async onRemoveImage(idx) {
      try {
        const { fileUrl } = this.form.images[idx].url;
        if (fileUrl && fileUrl.includes('googleapis.com')) {
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
          await this.save();
        }
      } catch (err) {
        this.$snotify.error("Erro ao remover imagem", "Atenção");
        console.warn(err);
      } finally {
        this.form.images.splice(idx, 1);
      }
    },

    async getGroups() {
      const { data } = await this.$axios.get(`/grupo/listaJson`);

      return data.map(o => ({
        value: o.id_grupo,
        text: o.nome_grupo
      }));
    },

    async saveNewGroup(groupName) {
      if (!groupName) {
        return;
      }

      const  { data } = await this.$axios.post(
        `/grupo/insere`,
        this.$qs.stringify({ nome_grupo: groupName })
      );
      const [response] = data;

      if (response && response.codigo != 1) {
        throw new Error(data.mensagem || data);
      }

      return {
        text: groupName,
        value: response.id_grupo
      };
    },

    async loadItems() {
      try {
        const { data } = await this.$axios.get(`/item`, { params: {
          exibir_formulacao: true,
          tipos: ['PRODUTO_ACABADO', 'INSUMO_PRODUCAO', 'MATERIA_PRIMA']
        } });

        this.items = data
          .map(item => ({
            id: item.id_item,
            code: item.cod_item,
            name: item.nome,
            description: item.cod_item ? `${item.cod_item} - ${item.nome.trim()}` : item.nome.trim(),
            measurement: item.unidade,
            type: item.tipo,
            stockControl: item.controle_estoque,
            rawMaterialGroupId: item.id_agrupador_materia_prima,
            fullData: item
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
      }
      catch (err) {
        console.warn(err)
        this.$snotify.error("Oops, ocorreu um erro ao carregar os insumos de produção!", "Atenção");
      }
    },

    async loadProductionSteps() {
      try {
        const { data } = await this.$axios.get(`/production-step`);

        this.productionSteps = data.map(item => ({
          id: item.id,
          description: item.descricao,
          duration: item.duracao?.substring(0, 5),
        }));
      }
      catch (err) {
        console.warn(err)
        this.$snotify.error("Oops, ocorreu um erro ao carregar as etapas de produção!", "Atenção");
      }
    },

    async loadDairies() {
      try {
        const { data } = await this.$axios.get(`/pessoa/listaLaticinioJson`);

        this.dairies = data.map(dairy => {
          return {
            id: dairy.id_pessoa,
            name: dairy.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os laticínios!", "Atenção");
      }
    },

    removeMeasurement(idx) {
      this.form.otherMeasurements.splice(idx, 1);
    },

    addMeasurement() {
      this.form.otherMeasurements.push({
        id: uuidv4(),
        label: '',
        type: 'TEXT',
        options: [],
        required: false
      })
    },

    removeComponent(idx) {
      this.formulation.items.splice(idx, 1);
    },

    addComponent() {
      this.formulation.items.push({
        type: 'PRODUTO_ACABADO',
        writeOffType: 'AUTOMATICA'
      })
    },

    onComponentChange(idx) {
      const item = this.formulation.items[idx];

      if (item?.item?.stockControl === 'SILO' || !!item?.item?.rawMaterialGroupId) {
        item.writeOffType = 'MANUAL';
      }
    },

    removeByProduct(idx) {
      this.formulation.byproducts.splice(idx, 1);
    },

    addByproduct() {
      this.formulation.byproducts.push({
        type: 'MATERIA_PRIMA',
        entryType: 'MANUAL',
      })
    },

    onByproductChange(idx) {
      const item = this.formulation.byproducts[idx];

      if (item?.item?.stockControl === 'SILO' || !!item?.item?.rawMaterialGroupId) {
        item.entryType = 'MANUAL';
      }
    },

    removeCoproduct(idx) {
      this.formulation.coproducts.splice(idx, 1);
    },

    addCoproduct() {
      this.formulation.coproducts.push({
        type: 'MATERIA_PRIMA',
        entryType: 'MANUAL',
        isCoproduct: true,
        dairies: [],
      })
    },

    removeCustom(idx) {
      this.form.productionCustoms.splice(idx, 1);
    },

    addCustom() {
      this.form.productionCustoms.push({
        fields: [],
      })
    },

    addField(idx) {
      this.form.productionCustoms[idx].fields.push({
        label: '',
        type: 'TEXT',
        options: [],
        required: false
      })
    },

    removeField(idx, fieldIndex) {
      this.form.productionCustoms[idx].fields.splice(fieldIndex, 1);
    },

    removeStep(idx) {
      this.form.productionSteps.splice(idx, 1);
    },

    addStep() {
      this.form.productionSteps.push({
        description: '',
        duration: null,
      });
    },

    copyProductionFromItem() {
      this.copy.items = this.items
        .filter(({ fullData }) => fullData.exibir_no_silo && (
          fullData.parametros_producao?.formato_lote ||
          fullData.parametros_producao?.prazos_validade ||
          fullData.etapas_producao?.length ||
          fullData.formulacao?.itens.length ||
          fullData.formulacao?.subprodutos.length ||
          fullData.personalizados_producao?.length
        ));
      this.copy.show = true;
    },

    selectItemToCopy({ fullData }) {
      const { form, formulation } = this.parseItemData(fullData);

      this.form = {
        ...this.form,
        productionSettings: form.productionSettings || {
          lotFormat: [],
          expirationDates: {},
        },
        productionSteps: form.productionSteps,
        productionCustoms: form.productionCustoms,
      };
      this.formulation = formulation;
      this.copy.show = false;
    },

    getType(value) {
      const types = {
        'PRODUTO_ACABADO': 'PRODUTO ACABADO',
        'MATERIA_PRIMA': 'MATÉRIA PRIMA',
        'INSUMO_PRODUCAO': 'INSUMO DE PRODUÇÃO',
        'INSUMO_AGRICOLA': 'INSUMO AGRÍCOLA',
      };

      return types[value] || value;
    },

    getTypeColor(value) {
      const statusColors = {
        'PRODUTO_ACABADO': 'blue',
        'MATERIA_PRIMA': 'orange darken-1',
        'INSUMO_PRODUCAO': 'green darken-1',
      }
      return statusColors[value] || 'grey'
    },

    close() {
      this.show = false;
    },

    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
  },
}
</script>
