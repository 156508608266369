<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
    >
      <v-card
        dark
        class="itinerary-list"
        color="transparent"
        flat
      >
        <v-card-title>
          <v-row>
            <v-col>
              <routes-autocomplete-filter
                v-model="filters.route"
                label="Rota"
                :style="dynamicFilterWidth()"
                @change="onFilterChange"
              />
            </v-col>

            <v-col>
              <person-autocomplete-filter
                v-model="filters.driver"
                label="Motorista"
                type="DRIVER"
                :style="dynamicFilterWidth()"
                @change="onFilterChange"
              />
            </v-col>

            <v-col>
              <vehicle-autocomplete-filter
                v-model="filters.vehicle"
                :style="dynamicFilterWidth()"
                @change="onFilterChange"
              />
            </v-col>

            <v-col>
              <date-range-picker
                v-model="filters.date.input"
                :style="dynamicFilterWidth()"
                @change="onDateFilter"
              />
            </v-col>

            <v-col v-if="hasSecondaryRawMaterial">
              <v-select
                v-model="rawMaterial"
                :items="rawMaterialItems"
                label="Matéria Prima"
                prepend-inner-icon="info"
                dark
                filled
                hide-details
                clearable
                :style="dynamicFilterWidth()"
                @change="onFilterChange"
              />
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="itineraries"
          item-key="id"
          class="elevation-1 itinerary-list-table"
          disable-sort
          disable-filtering
          @click:row="onItinerarySelect"
        >
          <template v-slot:item.time="{ item }">
            <div style="max-width: 90px;">
              <v-chip
                pill
                x-small
              >
                <v-avatar
                  left
                  color="green"
                  class="white--text"
                >
                  I
                </v-avatar>
                {{ dateFormat(item.collect.time.startedAt, 'DD/MM HH:mm') }}
              </v-chip>
              <div>
                <v-chip
                  v-if="item.collect.time.endedAt"
                  pill
                  x-small
                >
                  <v-avatar
                    left
                    color="green"
                    class="white--text"
                  >
                    F
                  </v-avatar>
                  {{ dateFormat(item.collect.time.endedAt, 'DD/MM HH:mm') }}
                </v-chip>
                <v-chip
                  v-else
                  pill
                  x-small
                >
                  Em Andamento
                </v-chip>
              </div>
              <v-chip
                v-if="item.collect.time.endedAt"
                pill
                x-small
              >
                <v-avatar
                  left
                  color="blue"
                  class="white--text"
                >
                  =
                </v-avatar>
                {{ item.collect.time.total }}
              </v-chip>
            </div>
          </template>

          <template v-slot:item.distance="{ item }">
            <itinerary-odometer-table-item
              :started-at="item.collect.distance.startedAt"
              :ended-at="item.collect.distance.endedAt"
              :total="item.collect.distance.total"
              :odometer="item.collect.distance.odometer"
            />
          </template>

          <template v-slot:item.tanks="{ item }">
            <div style="max-width: 70px;">
              <template v-for="tank in item.collect.tanks">
                <v-chip
                  v-if="tank.vol > 0"
                  :key="tank.index"
                  pill
                  x-small
                >
                  <v-avatar
                    left
                    color="green"
                    class="white--text"
                  >
                    {{ tank.index }}
                  </v-avatar>

                  {{ tank.vol }}
                </v-chip>
              </template>

              <v-chip
                pill
                x-small
              >
                <v-avatar
                  left
                  color="blue"
                  class="white--text"
                >
                  =
                </v-avatar>
                {{ item.collect.totalVol }}
              </v-chip>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-btn
                  dark
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="onItineraryPrint(item)">
                  <v-list-item-icon>
                    <v-icon>print</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Imprimir
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          class="mr-2"
          v-on="on"
          @click="loadItems()"
        >
          <v-icon>refresh</v-icon>
        </v-btn>
      </template>

      <span>Recarregar</span>
    </v-tooltip>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<style lang="scss">
.itinerary-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.itinerary-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>

<script>
import moment from "moment-timezone";
import _ from "lodash";

import VehicleAutocompleteFilter from "@/Support/Components/Filters/VehicleAutocompleteFilter.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import ItineraryOdometerTableItem from "@/Domains/Itineraries/Components/ItineraryOdometerTableItem.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import FilterRawMaterialMixin from "@/Support/Mixins/FilterRawMaterialMixin.js";

export default {

  components: {
    ItineraryOdometerTableItem,
    DateRangePicker,
    PersonAutocompleteFilter,
    VehicleAutocompleteFilter,
    RoutesAutocompleteFilter,
  },

  mixins: [FilterRawMaterialMixin],

  props: {
    status: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,

      itineraries: [],

      // Filtros da tabela
      filters: {
        route: {},

        vehicle: {},

        driver: {},

        date: {
          input: "today",
          range: [],
        },
      },
    };
  },

  computed: {
    headers() {
      let headers = [
        {
          text: "Rota",
          value: "route.description",
          sortable: false,
          width: 150,
        },
        {
          text: "Motorista",
          value: "driver.name",
          sortable: false,
          width: 140,
        },
        {
          text: "Placa",
          value: "vehicle.description",
          sortable: false,
          width: 100,
        },
        {
          text: "Saída/Chegada",
          value: "time",
          sortable: false,
          width: 130,
        },
        {
          text: "Quilometragem",
          value: "distance",
          width: 90,
          sortable: false
        },
        {
          text: "Litros",
          value: "tanks",
          sortable: false,
          width: 70,
        },
        {
          text: "Produtores",
          value: "collect.producers",
          sortable: false,
          width: 90,
        },
        {
          text: "Temp.",
          value: "collect.temp",
          sortable: false,
          width: 80,
        },
      ];

      if (this.hasSecondaryRawMaterial) {
        headers.push(this.headerRawMaterial);
      }

      headers.push({
        text: "",
        value: "actions",
        sortable: false,
        width: 40,
        align: 'end',
      });

      return headers;
    },
  },

  async mounted() {
    await this.loadItems();
  },

  methods: {
    async loadItems() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/coleta/relatorioItinerario`,
          this.$qs.stringify({
            status: 'descarregando',
            filtro_data: 'data_hora_descarga',
            data_ini: _.first(this.filters.date.range) || moment().format("DD/MM/YYYY"),
            data_fim: _.last(this.filters.date.range) || moment().format("DD/MM/YYYY"),
            id_motorista: this.filters.driver ? this.filters.driver.id : null,
            id_equipamento: this.filters.vehicle ? this.filters.vehicle.id : null,
            id_rota: this.filters.route ? this.filters.route.id : null,
            materiaPrima: this.rawMaterial || null,
          }),
        );

        if (!_.isArray(data)) {
          throw 'Error';
        }

        this.itineraries = data.map(itinerary => {
          const tanks = [
            { index: 1, vol: parseInt(itinerary.tanque1) || 0 },
            { index: 2, vol: parseInt(itinerary.tanque2) || 0 },
            { index: 3, vol: parseInt(itinerary.tanque3) || 0 },
            { index: 4, vol: parseInt(itinerary.tanque4) || 0 },
            { index: 5, vol: parseInt(itinerary.tanque5) || 0 },
            { index: 6, vol: parseInt(itinerary.tanque6) || 0 },
            { index: 7, vol: parseInt(itinerary.tanque7) || 0 },
          ];

          return {
            id: itinerary.id_itinerario,
            route: {
              id: itinerary.id_rota,
              description: itinerary.descricao_rota,
            },
            driver: {
              id: itinerary.id_motorista,
              name: itinerary.motorista,
            },
            vehicle: {
              id: itinerary.id_equipamento,
              description: (itinerary.caminhao || '').toUpperCase(),
            },
            nome_materia_prima: itinerary.nome_materia_prima,
            collect: {
              time: {
                startedAt: itinerary.data_hora_inicio,
                endedAt: itinerary.data_hora_fim,
                total: itinerary.data_hora_fim ? moment(itinerary.horas, 'HH:mm:ss').format('HH:ss') : null,
              },
              distance: {
                startedAt: itinerary.km_inicial ? parseInt(itinerary.km_inicial) : null,
                endedAt: itinerary.km_final ? parseInt(itinerary.km_final) : null,
                total: itinerary.km_total ? parseInt(itinerary.km_total) : null,
                odometer: [
                  {
                    type: 'START',
                    src: itinerary.url_velocimentro_ini,
                    thumb: itinerary.url_velocimentro_ini,
                    caption: 'Início',
                  },
                  {
                    type: 'END',
                    src: itinerary.url_velocimentro_fim,
                    thumb: itinerary.url_velocimentro_fim,
                    caption: 'Fim',
                  },
                ],
              },
              unloadedAt: itinerary.data_hora_descarga ? moment(itinerary.data_hora_descarga).format('DD/MM/YYYY HH:mm') : null,
              tanks,
              totalVol: parseInt(itinerary.quantidade_coleta),
              temp: `${parseFloat(itinerary.temperatura).toFixed(1)} ºC`,
              producers: itinerary.totalprodutores,
            },
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar o relatório!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    dateFormat(value, format) {
      return moment(value).format(format);
    },

    onDateFilter(event) {
      this.filters.date.range = event.map(date => moment(date).format('DD/MM/YYYY'));

      return this.onFilterChange();
    },

    onFilterChange() {
      return this.loadItems();
    },

    onItinerarySelect(itinerary) {
      return this.$emit('onItinerarySelect', itinerary);
    },

    onItineraryPrint() {
      // do stuff
    },

  },
};
</script>
